import React, { useState, useEffect } from "react";
import { createClient } from "contentful";
import ContentfulBlog from "./Contentfulblog";
import BlogSearch from "./BlogSearch";
import FilterHeader from "./FilterHeader";
import RecentPosts from "./RecentPosts";
import BlogCategories from "./BlogCategories";
import BlogTags from "./BlogTags";
import BlogPagination from "./BlogPagination";
import BlogNotFound from "./BlogNotFound";
import LanguageTranslater from "../../components/Common/LanguageTranslater";
import {
  blogProps,
  blogsQueryProps,
  categoryProps,
  tagProps,
  tagItemProps,
  categoryItemProps,
  blogItemProps,
  blogEntryProps,
} from "./PropTypes";

const Blogs: React.FC = () => {
  const [blogList, setBlogList] = useState<blogProps[]>([]);
  const [recentBlogs, setRecentBlogs] = useState<blogProps[]>([]);
  const [entries, setEntries] = useState<blogEntryProps>({
    total: 0,
    skip: 0,
    limit: 0,
    items: {},
  });
  const [categories, setCategories] = useState<categoryProps[]>([]);
  const [tags, setTags] = useState<tagProps[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const tagParam = localStorage.getItem("selected_tag")
    ? [localStorage.getItem("selected_tag")]
    : [];
  const [tag, setTag] = useState<(string | null)[]>(tagParam);
  const categoryParam = localStorage.getItem("selected_category")
    ? [localStorage.getItem("selected_category")]
    : [];
  const [category, setCategory] = useState<(string | null)[]>(categoryParam);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState<boolean>(false);
  const [menuSelected, setMenuSelected] = useState<string>("recent_post");
  const [screenSize, setScreenSize] = useState<number>(0);
  const itemPerPage = 10;
  const screenSizeForFilterToggle = 768;

  const getClient = async () => {
    return await createClient({
      space: process.env.REACT_APP_CONTENTFUL_ID || "",
      accessToken: process.env.REACT_APP_CONTENTFUL_KEY || "",
    });
  };

  const getContentfulBlogs = async (skip: number, limit: number) => {
    const cClient = await getClient();
    const blogsQuery: blogsQueryProps = {
      skip,
      limit,
      content_type: "blogPost",
      order: "-fields.publishDate",
      "fields.title[match]": searchText,
    };
    if (tag.length) {
      blogsQuery["fields.tags.sys.id[in]"] = tag.join();
    }
    if (category.length) {
      blogsQuery["fields.categories.sys.id[in]"] = category.join();
    }
    const blogs: blogEntryProps = await cClient.getEntries(blogsQuery);
    const blogList = blogs.items.map((item: blogItemProps) => {
      return {
        ...item.fields,
        blog_id: item.sys.id,
        created_at: item.sys.publishDate,
      };
    });
    setEntries(blogs);
    setBlogList(blogList);
  };

  const reloadBlogs = async (reloadPage: number) => {
    await getContentfulBlogs(
      itemPerPage * reloadPage - itemPerPage,
      itemPerPage
    );
    const height = offset();
    window.scrollTo(0, height ? height.top - 300 : 0);
  };

  const offset = () => {
    const el = document.querySelector(".blog_pagination_pages");
    if (el) {
      const rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }
  };

  const handleSearchTextChange = (text: string) => {
    setSearchText(text);
  };

  const toggleFilterMenu = (menu_status: boolean) => {
    setIsFilterMenuOpen(menu_status);
  };

  const showFilterMenu = (menu_name: string) => {
    setMenuSelected(menu_name);
  };

  const handleCategoryFilter = (selected_category: string[]) => {
    setCategory(selected_category);
  };

  const handleTagFilter = (selected_tag: string[]) => {
    setTag(selected_tag);
  };

  useEffect(() => {
    const getContentfulBlogsData = async (skip: number, limit: number) => {
      const cClient = await getClient();
      const blogsQuery: blogsQueryProps = {
        skip,
        limit,
        content_type: "blogPost",
        order: "-fields.publishDate",
        "fields.title[match]": searchText,
      };
      if (tag.length) {
        blogsQuery["fields.tags.sys.id[in]"] = tag.join();
      }
      if (category.length) {
        blogsQuery["fields.categories.sys.id[in]"] = category.join();
      }
      const blogs: blogEntryProps = await cClient.getEntries(blogsQuery);
      const blogList = blogs.items.map((item: blogItemProps) => {
        return {
          ...item.fields,
          blog_id: item.sys.id,
          created_at: item.sys.publishDate,
        };
      });
      setEntries(blogs);
      setBlogList(blogList);
    };
    getContentfulBlogsData(0, itemPerPage);
    localStorage.setItem("selected_category", "");
    localStorage.setItem("selected_tag", "");
  }, [searchText, tag, category]);

  useEffect(() => {
    const getCategories = async () => {
      const cClient = await getClient();
      const categoriesData = await cClient.getEntries({
        content_type: "category",
        limit: 10,
        "fields.priority[gt]": 0,
        order: "fields.priority",
        select: "fields.priority,fields.name,sys.id",
      });

      const categoriesList = categoriesData.items.map(
        (item: categoryItemProps) => {
          return {
            name: item.fields.name,
            id: item.sys.id,
          };
        }
      );

      setCategories(categoriesList);
    };

    const getRecentPosts = async () => {
      const cClient = await getClient();
      const blogs = await cClient.getEntries({
        limit: 4,
        content_type: "blogPost",
        order: "-fields.publishDate",
      });
      const blogList = blogs.items.map((item: blogItemProps) => {
        return {
          ...item.fields,
          blog_id: item.sys.id,
        };
      });
      setRecentBlogs(blogList);
    };

    const getTags = async () => {
      const cClient = await getClient();
      const tagData = await cClient.getEntries({
        content_type: "tag",
        limit: 10,
        "fields.priority[gt]": 0,
        order: "fields.priority",
      });

      const TagList = tagData.items.map((item: tagItemProps) => {
        return {
          name: item.fields.name,
          id: item.sys.id,
        };
      });
      setTags(TagList);
    };
    getRecentPosts();
    getCategories();
    getTags();
    setScreenSize(window.screen.width);
    window.addEventListener(
      "resize",
      () => setScreenSize(window.screen.width),
      false
    );
  }, []);

  return (
    <section className="hosting_service_area sec_pad">
      <div className="container">
        <div className="row blog_page_container">
          <div className="col-md-8">
            <div className="row">
              {blogList.length ? (
                blogList.map(
                  (blog: blogProps) =>
                    blog.title.length > 0 && (
                      <ContentfulBlog key={blog.title} blogData={blog} />
                    )
                )
              ) : (
                <BlogNotFound
                  header="No Blog Found!!"
                  instruction="Search with other query."
                />
              )}
            </div>
          </div>
          <div className="col-md-4">
            <BlogSearch handleSearchTextChange={handleSearchTextChange} />
            <div className="blog_filter_section pb-4">
              {screenSize < screenSizeForFilterToggle && (
                <FilterHeader toggleFilterMenu={toggleFilterMenu} />
              )}
              {(screenSize > screenSizeForFilterToggle || isFilterMenuOpen) && (
                <div className="blog_menu_section">
                  {screenSize < screenSizeForFilterToggle && (
                    <div className="blog_menu_header">
                      <div className="blog_menu d-flex">
                        <div
                          key="recent_post"
                          onClick={() => showFilterMenu("recent_post")}
                          className={
                            "blog_menu_item " +
                            (menuSelected == "recent_post" ? "selected" : "")
                          }
                        >
                          <LanguageTranslater langKey="blog.recentPosts" />
                        </div>
                        <div
                          key="categories"
                          onClick={() => showFilterMenu("categories")}
                          className={
                            "blog_menu_item " +
                            (menuSelected == "categories" ? "selected" : "")
                          }
                        >
                          Categories
                        </div>
                        <div
                          key="tags"
                          onClick={() => showFilterMenu("tags")}
                          className={
                            "blog_menu_item " +
                            (menuSelected == "tags" ? "selected" : "")
                          }
                        >
                          Tags
                        </div>
                      </div>
                    </div>
                  )}
                  {(screenSize > screenSizeForFilterToggle ||
                    menuSelected == "recent_post") && (
                    <RecentPosts recentBlogs={recentBlogs} />
                  )}
                  {(screenSize > screenSizeForFilterToggle ||
                    menuSelected == "categories") && (
                    <BlogCategories
                      filterByCategory={handleCategoryFilter}
                      categories={categories}
                      selectedCategory={category}
                    />
                  )}
                  {(screenSize > screenSizeForFilterToggle ||
                    menuSelected == "tags") && (
                    <BlogTags
                      filterByTag={handleTagFilter}
                      blogTags={tags}
                      selectedTags={tag}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <BlogPagination
          reloadBlogs={reloadBlogs}
          totalItems={entries.total}
          itemSkip={entries.skip}
          itemPerPage={itemPerPage}
        />
      </div>
    </section>
  );
};

export default Blogs;
