import React from "react";
import "./cardflip.css";

interface CardDetailProps {
  title: string | JSX.Element;
  des: string | JSX.Element;
  customClass?: string;
  iconPath: string;
  sectionClass?: string;
}

export const CardDetail: React.FC<CardDetailProps> = (
  props: CardDetailProps
) => {
  const { title, des, customClass, iconPath, sectionClass } = props;
  return (
    <div className={`card_detail ${sectionClass}`}>
      <div className="card_detail_img">
        <img
          src={require(`../../../assets/img/${iconPath}`).default}
          alt="icon"
        />
      </div>
      <div className="card_detail_content">
        <div className={`media-body ${customClass}`}>
          <h4 className="h_head">{title}</h4>
          <p className="f_p">{des}</p>
        </div>
      </div>
    </div>
  );
};

export default CardDetail;
