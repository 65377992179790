import React from "react";
import Image from "../Image";
import "./feature-item.css";
const Reveal = require("react-reveal/Reveal");

interface FeatureItemProps {
  rowClass: string;
  fimage: string;
  aClass?: string;
  bClass?: string;
  iImg: string;
  ftitle: string | JSX.Element;
  textClass?: string;
  headerClass?: string;
  fname: string;
  firstDescription?: string | JSX.Element;
  imgClass?: string;
  leftClass?: string;
  children?: React.ReactNode;
  altImg?: boolean;
}

export const FeatureItem: React.FunctionComponent<FeatureItemProps> = (
  props: FeatureItemProps
) => {
  const {
    rowClass,
    fimage,
    aClass,
    iImg,
    ftitle,
    firstDescription,
    bClass,
    textClass,
    fname,
    headerClass,
    imgClass,
    leftClass,
    children,
    altImg,
  } = props;

  return (
    <div
      className={`agency_featured_item align-items-center h-100 ${rowClass} `}
    >
      <div className={`col-lg-6 my-auto ${imgClass}`}>
        <Reveal effect="fadeInLeft">
          <div className={`agency_featured_img ${bClass}`}>
            {altImg === true ? (
              <Image
                className="img-fluid"
                src={`${fname}/${fimage}`}
                alt={fimage}
              />
            ) : (
              <img
                src={require(`../../../assets/img/${fname}/${fimage}`).default}
                alt={fimage}
                className="img-fluid"
              />
            )}
            <a
              className="mapping"
              href="https://assets.pumapay.io/O9pRVq/index.html"
              target="_blank"
              rel="noreferrer"
            ></a>
          </div>
        </Reveal>
      </div>
      <div className={leftClass ? leftClass : "col-lg-6 feature_detail"}>
        <div
          className={`agency_featured_content wow fadeInLeft ${aClass}`}
          data-wow-delay="0.6s"
        >
          <Reveal effect="fadeInRight">
            {iImg && (
              <img
                className="number"
                src={require(`../../../assets/img/${fname}/${iImg}`).default}
                alt={iImg}
              />
            )}
            <h3 className={`${fname}-step-title f_p ${headerClass}`}>
              {ftitle}
            </h3>
            <p className={`${fname}-step-description f_p ${textClass}`}>
              {children ? children : firstDescription}
            </p>
          </Reveal>
        </div>
      </div>
    </div>
  );
};
export default FeatureItem;
