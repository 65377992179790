import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { defaultLanguage, supportedLanguages } from "../config/i18n";

i18next
  .use(initReactI18next)
  .use(XHR)
  .use(LanguageDetector)
  .init({
    supportedLngs: supportedLanguages.map((lang) => lang.code),
    nonExplicitSupportedLngs: true,
    lng: defaultLanguage,

    // Good idea to provide a fallback when loading
    // translations from a back-end, to avoid unsuccessful
    // attempts to load default fallbackLng ("dev").
    fallbackLng: defaultLanguage,

    // Back-end config
    // backend: {
    //   loadPath: "/locales/{{lng}}/{{ns}}.json",
    // },

    interpolation: {
      // React will escape output values, so we don't need
      // i18next to do it.
      escapeValue: false,
    },

    // react-i18next config
    react: {
      useSuspense: true,
    },

    debug: process.env.NODE_ENV === "development",
  })
  .catch((reason: string) => console.error("Failed init i18n", reason));

export default i18next;

export function languageCodeOnly(fullyQualifiedCode: string): string {
  return fullyQualifiedCode.split("-")[0];
}
