import React from "react";
import { HeaderMenu } from "../../components/Header";
import {
  OutletForCryptoSection,
  LiquiditySection,
  PartnersPaySection,
  PaywithCryptoBanner,
} from "../../components/PayWithCrypto/";
import { FooterBanner } from "../../components/Footer";
import { ClaimRewardBanner, LanguageTranslater } from "../../components/Common";
import useMediaQuery from "../../services/UseMediaQuery";
import "./payWithCrypto.css";

const PayWithCrypto: React.FC = () => {
  const [width] = useMediaQuery();
  return (
    <>
      <HeaderMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        cClass=""
        hbtnClass=""
        isAltLogo={!(width < 769)}
      />
      <PaywithCryptoBanner />
      <ClaimRewardBanner />
      <OutletForCryptoSection />
      <LiquiditySection />
      <PartnersPaySection aClass="h_action_area custom_pps" />
    </>
  );
};

export default PayWithCrypto;
