import React from "react";

interface contactItemProps {
  image: string;
  title: string | JSX.Element;
  description: string | JSX.Element;
  subDescription?: string | JSX.Element;
  contact_url: string;
  type?: string | JSX.Element;
  isSupport?: boolean;
}
const ContactItem: React.FunctionComponent<contactItemProps> = (
  props: contactItemProps
) => {
  const {
    image,
    title,
    description,
    contact_url,
    subDescription,
    type,
    isSupport,
  } = props;

  return (
    <div className="col-lg-4 contact-features_wrapper">
      <div className="contact-features text-center wow fadeInUp">
        <img
          src={require(`../../../../assets/img/contact/${image}`).default}
          alt={image}
          className="img-fluid"
        />
        <h5 className="f_p f_size_20 t_color mt_30 mb_20">{title}</h5>
        <p className={`f_p t_color f_500`}>{description}</p>
        <p className="f_p f_400">{subDescription}</p>
        {!isSupport && (
          <p className="f_p f_400">
            {type}{" "}
            <a
              className="f_p f_500 email_anchor"
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto:${contact_url}?subject=${title}`}
            >
              {contact_url}
            </a>
          </p>
        )}
      </div>
    </div>
  );
};
export default ContactItem;
