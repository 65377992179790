import React from "react";
interface V3CardProps {
  children?: React.ReactNode;
  isInvert?: boolean;
}
export const V3Card: React.FC<V3CardProps> = (props: V3CardProps) => {
  const { children, isInvert } = props;
  return (
    <div className="card_front card_v3">
      <img
        className={isInvert === true ? "img_v3_invert" : "img_v3"}
        src={require("../../../assets/img/card-flip/v3.png").default}
        alt="V3"
      />
      <div className="card_front_content">{children}</div>
    </div>
  );
};

export default V3Card;
