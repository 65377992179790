import React from "react";
const Reveal = require("react-reveal/Reveal");

interface SectitleProps {
  title: string;
  sClass: string;
  children?: React.ReactNode;
}
export const Sectitle: React.FunctionComponent<SectitleProps> = (
  props: SectitleProps
) => {
  const { title, sClass, children } = props;
  return (
    <div className={`${sClass}`}>
      <Reveal effect="fadeInUp" duration={1300}>
        <h2 className={`f_p f_size_30 l_height50 f_600`}>
          <span>{children ? children : title}</span>
        </h2>
      </Reveal>
    </div>
  );
};
export default Sectitle;
