import React from "react";
const Reveal = require("react-reveal/Reveal");
import LanguageTranslater from "../../../components/Common/LanguageTranslater";
import { ServiceItems } from "../../Common";
import "./solutionService.css";

interface SolutionServiceProps {
  customClass?: string;
}

export const SolutionService: React.FC<SolutionServiceProps> = (
  props: SolutionServiceProps
) => {
  const { customClass } = props;
  return (
    <section className={`agency_service_area ${customClass}`}>
      <div className="container">
        <h2
          className="f_500 text-center mb_90 wow fadeInUp f_p zero-const-title"
          data-wow-delay="0.2s"
        >
          {<LanguageTranslater langKey="bussiness.service.title1" />}
          <span>
            {<LanguageTranslater langKey="bussiness.service.title2" />}
          </span>
        </h2>
        <div className="row content-wrapper">
          <Reveal effect="fadeInUp">
            <div className="col-lg-3 col-sm-6">
              <ServiceItems
                icon="icon1.png"
                title={
                  <LanguageTranslater langKey="bussiness.service.item1.title" />
                }
                description={
                  <LanguageTranslater langKey="bussiness.service.item1.text" />
                }
              />
            </div>
          </Reveal>
          <Reveal effect="fadeInUp">
            <div className="col-lg-3 col-sm-6">
              <ServiceItems
                icon="icon2.png"
                customClass="commitment_item2"
                title={
                  <LanguageTranslater langKey="bussiness.service.item2.title" />
                }
                description={
                  <LanguageTranslater langKey="bussiness.service.item2.text" />
                }
              />
            </div>
          </Reveal>
          <Reveal effect="fadeInUp">
            <div className="col-lg-3 col-sm-6">
              <ServiceItems
                icon="icon3.png"
                title={
                  <LanguageTranslater langKey="bussiness.service.item3.title" />
                }
              >
                {<LanguageTranslater langKey="bussiness.service.item3.text1" />}{" "}
                <a
                  className="try_it"
                  href="https://console.pumapay.io/register"
                  target="_blank"
                  rel="noreferrer"
                >
                  {
                    <LanguageTranslater langKey="bussiness.service.item3.text2" />
                  }
                </a>
              </ServiceItems>
            </div>
          </Reveal>
        </div>
      </div>
    </section>
  );
};
export default SolutionService;
