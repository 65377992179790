import React from "react";
import { HeaderMenu, PlainHeaderBanner } from "../../components/Header";
import { FooterBanner } from "../../components/Footer";
import {
  HowItWorksSection,
  BillingModelSection,
  SliderFeatureSection,
} from "../../components/Subscription";
import LanguageTranslater from "../../components/Common/LanguageTranslater";

const Subscription: React.FC = () => {
  return (
    <>
      <HeaderMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        cClass=""
        hbtnClass=""
      />
      <PlainHeaderBanner
        title={<LanguageTranslater langKey="subscription.headerBanner.title" />}
        text={
          <LanguageTranslater langKey="subscription.headerBanner.description" />
        }
        headerClass="subscription_title"
        subHeaderClass="subscription_subtitle"
      />
      <BillingModelSection />
      <SliderFeatureSection />
      <HowItWorksSection pClass="partner_logo_area_two" pClasst="pt-0 mb-0" />

      <FooterBanner
        imgClass="billing_content_bg_simple"
        title={
          <LanguageTranslater langKey="footerBanner.subscription.header" />
        }
        description={
          <LanguageTranslater langKey="footerBanner.subscription.description" />
        }
        btnText={<LanguageTranslater langKey="common.createBillingModel" />}
        fbClass=""
        btn_url="https://console.pumapay.io/register"
        fbTitleClass="subscription"
      />
    </>
  );
};

export default Subscription;
