import React from "react";
import { SolutionSectitle, BrandLists } from "../../Common";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";

export const TrustedByIndustry: React.FC = () => {
  return (
    <section className="industry-log-section sec_pad">
      <div className="container">
        <SolutionSectitle
          sClass="adult-ent-header text-center"
          title={<LanguageTranslater langKey="adult.trustedLeaders.title" />}
          subTitle=""
        />
        <BrandLists adult={true} />
      </div>
    </section>
  );
};
export default TrustedByIndustry;
