import React from "react";
import SpeedAgilityItems from "./SpeedAgilityItems";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";
import "./speedagilitysection.css";
const Reveal = require("react-reveal/Reveal");

export const SpeedAgilitySection: React.FC = () => {
  return (
    <section className="agency_service_area bg_color custom_bg other_billing_speed">
      <div className="container">
        <h1
          className="speed-title f_p f_size_30 f_500 t_color3 l_height40 text-center wow fadeInUp"
          data-wow-delay="0.2s"
        >
          {<LanguageTranslater langKey="billing.speedAgilitySection.title" />}
        </h1>
        <p className="text-center subheader">
          {
            <LanguageTranslater langKey="billing.speedAgilitySection.description" />
          }
        </p>
        <div className="row mb_30 mt_50">
          <Reveal effect="fadeInUp">
            <div className="col-lg-4 col-sm-6">
              <SpeedAgilityItems
                icon="billing.png"
                iClass="item1"
                text={
                  <LanguageTranslater langKey="billing.speedAgilitySection.Find the right billing model.title" />
                }
                description={
                  <LanguageTranslater langKey="billing.speedAgilitySection.Find the right billing model.description" />
                }
              />
            </div>
          </Reveal>
          <Reveal effect="fadeInUp">
            <div className="col-lg-4 col-sm-6">
              <SpeedAgilityItems
                icon="customize.png"
                iClass="item2 "
                text={
                  <LanguageTranslater langKey="billing.speedAgilitySection.Customize to meet your needs.title" />
                }
                description={
                  <LanguageTranslater langKey="billing.speedAgilitySection.Customize to meet your needs.description" />
                }
              />
            </div>
          </Reveal>
          <Reveal effect="fadeInUp">
            <div className="col-lg-4 col-sm-6">
              <SpeedAgilityItems
                icon="automate.png"
                iClass="item3"
                text={
                  <LanguageTranslater langKey="billing.speedAgilitySection.Automate your payments.title" />
                }
                description={
                  <LanguageTranslater langKey="billing.speedAgilitySection.Automate your payments.description" />
                }
              />
            </div>
          </Reveal>
        </div>
      </div>
    </section>
  );
};
export default SpeedAgilitySection;
