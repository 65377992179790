import React, { useState } from "react";

interface BlogFilterHeader {
  toggleFilterMenu: (a: boolean) => void;
}

const BlogSearch: React.FunctionComponent<BlogFilterHeader> = (
  props: BlogFilterHeader
) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const toggleFilterMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    props.toggleFilterMenu(!isMenuOpen);
  };

  return (
    <div className="pb-4" onClick={() => toggleFilterMenu()}>
      <div className="position-relative">
        <div className="blog_filter_menu">Filters</div>
        <img
          src={require("../../../assets/img/blogs/up_down.png").default}
          className={
            "blog_filter_direction mt-1 " + (isMenuOpen ? "down" : "up")
          }
          alt="up_down.png"
        />
      </div>
    </div>
  );
};

export default BlogSearch;
