import React from "react";
import useMediaQuery from "../../../services/UseMediaQuery";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";
import "./HowItWorksSection.css";
import Image from "../../Common/Image";

interface HowItWorksSectionProps {
  pClass: string;
  pClasst: string;
}

export const HowItWorksSection: React.FC<HowItWorksSectionProps> = (
  props: HowItWorksSectionProps
) => {
  const { pClass, pClasst } = props;
  const [width] = useMediaQuery();
  return (
    <section className={`${pClass} pt_5 mb-80`}>
      <div className={`partner_logo_area_four ${pClasst}`}>
        <h1 className="f_size_30 f_p text-center l_height45 t_color mb_80 hiw_title">
          {<LanguageTranslater langKey="subscription.How it works" />}
        </h1>
        <div className="partner_info mb_60">
          {width <= 767 ? (
            <Image
              className="img-fluid w-100"
              src={"subscription/Infographic_mobile.png"}
              alt="infographic_mobile"
            />
          ) : (
            <Image
              className="img-fluid"
              src={"subscription/infographic.png"}
              alt="infographic"
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default HowItWorksSection;
