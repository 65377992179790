import React from "react";
import { HeaderMenu, PlainHeaderBanner } from "../../components/Header";
import {
  HowItWillWork,
  PullPayment,
  Partners,
} from "../../components/Executors";
import { FooterBanner } from "../../components/Footer";
import { LanguageTranslater } from "../../components/Common";
import "./executor.css";

const Executors: React.FC = () => {
  return (
    <div className="executors_page">
      <HeaderMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        cClass=""
        hbtnClass=""
      />
      <PlainHeaderBanner
        title={<LanguageTranslater langKey="executors.headerBanner.title" />}
        text={
          <LanguageTranslater langKey="executors.headerBanner.description" />
        }
        mClass="executor"
        headerClass="executor_title"
        subHeaderClass="executor_subtitle"
      />
      <HowItWillWork />
      <PullPayment />
      <Partners />
      <FooterBanner
        imgClass="billing_content_bg_pumapay_app"
        title={<LanguageTranslater langKey="footerBanner.executors.header" />}
        description={
          <LanguageTranslater langKey="footerBanner.executors.description" />
        }
        btnText={<LanguageTranslater langKey="common.depositNow" />}
        fbTitleClass="mt-3"
        fbDesClass="pumapay_app_description"
        btn_url="https://exchange.pancakeswap.finance/#/add/BNB/0x43a167B15a6F24913A8B4D35488B36Ac15d39200"
      />
    </div>
  );
};

export default Executors;
