import React from "react";

interface ServiceItemsProps {
  serviceTitle: string | JSX.Element;
  servicedescription: string | JSX.Element;
  icon: string;
  className: string;
}

const ServiceItems: React.FunctionComponent<ServiceItemsProps> = (
  props: ServiceItemsProps
) => {
  const { serviceTitle, servicedescription, icon, className } = props;
  return (
    <div className="col-sm-6">
      <div className={className}>
        <div>
          <img
            src={
              require(`../../../../assets/img/solutions-for/${icon}`).default
            }
            alt={icon}
          />
        </div>
        <div>
          <h4 className="h_head f_p f_size_26">{serviceTitle}</h4>
          <p className="f_p f_size_16 l_height24">{servicedescription}</p>
        </div>
      </div>
    </div>
  );
};
export default ServiceItems;
