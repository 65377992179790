import React from "react";
import { useTranslation } from "react-i18next";
import { HeaderMenu, TriangleHeaderBanner } from "../../components/Header";
import { RewardLiquidityProvider } from "../../components/Rewards";
import "./reward_scheme.css";

const RewardScheme: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        cClass=""
        hbtnClass=""
      />
      <TriangleHeaderBanner
        sImage="reward_header_image.png"
        aClass="reward_banner"
        isHtmlContent={true}
      >
        <>
          <h1
            className="solutions_for_header f_p mb_20 f_400"
            data-wow-delay="0.3s"
          >
            <div className="f_700">
              {t("rewardScheme.headerBanner.header.500,000,000 PMA")}
            </div>
            {t(
              "rewardScheme.headerBanner.header.tokens in rewards for liquidity providers"
            )}
          </h1>
          <p
            className="solutions_for_description wow fadeInUp f_p l_height27 f_300"
            data-wow-delay="0.5s"
          >
            {t("rewardScheme.headerBanner.header.description")}
          </p>
        </>
      </TriangleHeaderBanner>
      <RewardLiquidityProvider />
    </>
  );
};

export default RewardScheme;
