import React from "react";
import { Route, Switch } from "react-router-dom";
interface IRoutes {
  name: string;
  path: string;
  component: React.ComponentType;
  exact?: boolean;
  containParam?: boolean;
  params?: Array<Param>;
}

interface Param {
  id: string;
  value: string;
}

interface RoutesProps {
  routes: Array<IRoutes>;
}

const Routes: React.FC<RoutesProps> = ({ routes }: RoutesProps) => {
  return (
    <Switch>
      {routes.map((route: IRoutes, index: number) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
    </Switch>
  );
};

export default Routes;
