import React from "react";
import PersonalWidget from "./FooterWidget/PersonalWidget";
import SocialWidget from "./FooterWidget/SocialWidget";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "./footer.css";

interface FooterProps {
  fClass?: string;
}

export const FooterMenu: React.FunctionComponent<FooterProps> = (
  props: FooterProps
) => {
  const { fClass } = props;
  const { t } = useTranslation();
  const tAndCPdfurl = "/docs/terms.pdf";
  return (
    <footer className={`footer_area footer_area_four f_bg ${fClass}`}>
      <div className="footer_top">
        <div className="container">
          <div className="row footer-wrapper">
            <PersonalWidget />
          </div>
          <div className="row pt_60">
            <SocialWidget ftitle={t("title.footerTitle.SOCIAL")} />
          </div>
        </div>
      </div>
      <div className="footer_bottom custom_footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-5 col-sm-6">
              <p className="mb-0 f_400" style={{ whiteSpace: "nowrap" }}>
                {t("title.copywrite", { year: moment().format("YYYY") })}
              </p>
            </div>
            <div className="col-lg-4 col-md-3 col-sm-6 text-center">
              <a
                target="_blank"
                rel="noreferrer"
                className="btn_hover"
                href={tAndCPdfurl}
              >
                {t("title.t&c")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default FooterMenu;
