import React from "react";
import { useTranslation } from "react-i18next";
import { HeaderMenu, PlainHeaderBanner } from "../../components/Header";
import {
  SmartChainSection,
  PumaPayBridgeMain,
} from "../../components/PumaPayBridge";
import { FooterBanner } from "../../components/Footer";

const PumaPayBridge: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="pumapay_bridge_page">
      <HeaderMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        cClass=""
        hbtnClass=""
      />
      <PlainHeaderBanner
        title={t("pumapayBridge.headerBanner.title")}
        text={t("pumapayBridge.headerBanner.description")}
        mClass="pumapay_bridge_head_banner"
        headerClass="page_title"
        subHeaderClass="solution_subtitle"
        isButton={true}
        btnUrl="https://bridge.pumapay.io/"
        btnText={t("common.goToBridge")}
      />
      <PumaPayBridgeMain />
      <SmartChainSection />
      <FooterBanner
        imgClass="billing_content_bg_pumapay_app"
        title={t("pumapayBridge.footerBanner.title")}
        description={t("pumapayBridge.footerBanner.description")}
        btnText={t("common.learnMore")}
        fbTitleClass="mt-3"
        fbDesClass="pumapay_app_description"
        btn_url="/liquidity-providers"
      />
    </div>
  );
};

export default PumaPayBridge;
