import React from "react";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";
import "./forexBanner.css";

export const ForexBanner: React.FC = () => {
  return (
    <section className="forex_hosting_banner_area">
      <div className="container">
        <div className="row align-items-center h-100">
          <div className="col-lg-6 temp">
            <img
              className="img-fluid"
              src={require("../../../assets/img/forex/forex.png").default}
              alt="forex-banner"
            />
          </div>
          <div className="col-lg-6 forex_text">
            <h1 className="header_first-forex text-left">
              {<LanguageTranslater langKey="forex.headerBanner.title" />}
            </h1>
            <h1 className="header_second mb_20" data-wow-delay="0.3s">
              {<LanguageTranslater langKey="forex.headerBanner.header" />}
            </h1>
            <p className="wow fadeInUp desc_first" data-wow-delay="0.5s">
              {<LanguageTranslater langKey="forex.headerBanner.description" />}
            </p>
            <a
              className="btn_get btn_hover banner_button"
              href="https://console.pumapay.io/register"
              target="_blank"
              rel="noopener noreferrer"
            >
              {<LanguageTranslater langKey="common.startAcceptingCrypto" />}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ForexBanner;
