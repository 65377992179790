import React from "react";
import { HeaderMenu } from "../../components/Header";
import {
  ForexBanner,
  BenefitSection,
  BillingModelCaraousel,
  AutomatedTopupSection,
} from "../../components/Forex";
import { PaymentSection } from "../../components/Common";
import { FooterBanner } from "../../components/Footer";
import LanguageTranslater from "../../components/Common/LanguageTranslater";

const Forex: React.FC = () => {
  return (
    <>
      <HeaderMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        cClass=""
        hbtnClass=""
        isAltLogo={true}
        isAltNav={true}
      />

      <ForexBanner />
      <BenefitSection />
      <PaymentSection
        mTitle={<LanguageTranslater langKey="forex.paymentSection.title" />}
        mDescription={
          <LanguageTranslater langKey="forex.paymentSection.description" />
        }
        pImage="automate.png"
        sTitle={<LanguageTranslater langKey="forex.paymentSection.subTitle" />}
        sDecsription={
          <LanguageTranslater langKey="forex.paymentSection.subDescription" />
        }
        btn_text={<LanguageTranslater langKey="common.getStarted" />}
        btn_url="https://console.pumapay.io/register"
        l_more_btn={<LanguageTranslater langKey="common.learnMore" />}
        l_more_url="/top-ups"
        fColorDes="forex_des_color"
      />
      <AutomatedTopupSection />
      <BillingModelCaraousel />

      <FooterBanner
        imgClass="billing_content_bg_dark"
        title={<LanguageTranslater langKey="footerBanner.forex.header" />}
        description={
          <LanguageTranslater langKey="footerBanner.forex.description" />
        }
        btnText={<LanguageTranslater langKey="common.getStarted" />}
        btn_url="https://console.pumapay.io/register"
        fbTitleClass="forex"
        fbDesClass="forex_des"
      />
    </>
  );
};

export default Forex;
