import React from "react";
import FeatureRichSlider from "./FeatureRichSlider";
import { LanguageTranslater } from "../../Common";
import "./pumapay-content.css";

export const PumaPayAppContentSection: React.FC = () => {
  return (
    <section className="case_studies_area sec_pad">
      <div className="container">
        <h2
          className="f_500 f_p f_size_36 t_color l_height54 text-center mb_90 wow fadeInUp banner_title"
          data-wow-delay="0.2s"
        >
          <LanguageTranslater langKey="pumaPayApp.contentSection.title" />
        </h2>
        <FeatureRichSlider />
      </div>
    </section>
  );
};

export default PumaPayAppContentSection;
