import React from "react";
import { HeaderMenu, TriangleHeaderBanner } from "../../components/Header";
import {
  PumaPayAppContentSection,
  PumapayWalletCarousel,
  PumaPayUserFeedBack,
} from "../../components/PumaPayApp";
import { LanguageTranslater } from "../../components/Common";
import { FooterBanner } from "../../components/Footer";
import "./pumapay-app.css";

const PumaPayApp: React.FC = () => {
  return (
    <>
      <HeaderMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        cClass=""
        hbtnClass=""
      />
      <TriangleHeaderBanner
        header={<LanguageTranslater langKey="pumaPayApp.headerBanner.title" />}
        description={
          <LanguageTranslater langKey="pumaPayApp.headerBanner.description" />
        }
        sImage="mockup.png"
        isDownloadButton={true}
        btn_text="Download your PumaPay wallet today!"
        aClass="pumapay_app_banner"
      />
      <PumaPayAppContentSection />
      <PumapayWalletCarousel />
      <PumaPayUserFeedBack />

      <FooterBanner
        imgClass="billing_content_bg_pumapay_app"
        title={<LanguageTranslater langKey="pumaPayApp.footerBanner.title" />}
        description={
          <LanguageTranslater langKey="pumaPayApp.footerBanner.description" />
        }
        btnText={<LanguageTranslater langKey="common.getStarted" />}
        fbTitleClass="mt-3"
        fbDesClass="pumapay_app_description"
        btn_url="https://console.pumapay.io/login"
        isDownloadButton={true}
      />
    </>
  );
};

export default PumaPayApp;
