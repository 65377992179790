import React from "react";
import { useTranslation } from "react-i18next";
import { supportedLanguages } from "../../../config/i18n";
import "./languageSwitcher.css";

const shortCode = (code: string) => {
  switch (code) {
    case "ja":
      return "JPN";
    default:
      return "ENG";
  }
};

export const LanguageSwitcher: React.FunctionComponent = () => {
  const { i18n } = useTranslation();
  return (
    <>
      <div className="dropdown">
        <div
          title="Pages"
          className={`dropdown-toggle`}
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {shortCode(i18n.language)}
        </div>
        <ul className="dropdown-menu">
          {supportedLanguages.map((lang) => (
            <li className="nav-item" key={lang.code}>
              <div
                className="nav-link"
                onClick={() => i18n.changeLanguage(lang.code)}
              >
                <img
                  className="img-fluid languge_img"
                  src={
                    require(`../../../assets/img/language/${lang.code}.png`)
                      .default
                  }
                  alt="forex-banner"
                />
                {lang.name}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default LanguageSwitcher;
