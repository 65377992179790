import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as Yup from "yup";
import "./contactForm.css";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";
import { useTranslation } from "react-i18next";

interface IResponce {
  status: boolean;
  message: string;
}

interface ReCaptchaRenderOptions {
  sitekey: string;
  size: "invisible";
}
interface ReCaptchaExecuteOptions {
  action: string;
}
interface ReCaptchaInstance {
  ready: (cb: () => void) => void;
  execute: (
    sitekey: string,
    options: ReCaptchaExecuteOptions
  ) => Promise<string>;
  render: (id: string, options: ReCaptchaRenderOptions) => void;
}

declare global {
  interface Window {
    grecaptcha: ReCaptchaInstance;
  }
}

interface IContact {
  name: string;
  subject: string;
  email: string;
  message: string;
}

export const ContactForm: React.FC = () => {
  const { t } = useTranslation();
  const [isSubmit, setSubmit] = useState(false);
  const [iResponse, setResponse] = useState<IResponce>({
    status: false,
    message: "",
  });

  const url: string = process.env.REACT_APP_MAILER_API_URL || "";
  const SITE_KEY: string = process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";

  // Header need to pass to submit the request
  const headers = {
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_MAILER_API_KEY,
  };

  // Form validation rules
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("contactUs.form.errorMessage.nameRequired")),
    subject: Yup.string().required(
      t("contactUs.form.errorMessage.subjectRequired")
    ),
    message: Yup.string().required(
      t("contactUs.form.errorMessage.messageRequired")
    ),
    email: Yup.string()
      .required(t("contactUs.form.errorMessage.emailRequired"))
      .email(t("contactUs.form.errorMessage.emailInvalid")),
  });

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onHandleClick = async (data: IContact) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "submit" })
        .then((token: string) => {
          onSubmit(token, data);
        });
    });
  };

  const onSubmit = async (token: string, data: IContact) => {
    const body = {
      subject: "Website Inquiry",
      text: `
        Name: ${data.name}
        Email: ${data.email}
        Subject: ${data.subject}
        Message: ${data.message}
      `,
      "g-recaptcha-response": token,
    };

    setSubmit(true);

    // API calling
    try {
      const result = await axios.post(url, body, {
        headers: headers,
      });
      if (result.status === 200) {
        setResponse({
          ...iResponse,
          status: true,
          message: result.data,
        });
        reset();
      } else {
        setResponse({
          ...iResponse,
          status: false,
          message: "Something went wrong.",
        });
      }
    } catch (error) {
      console.log("ERROR", error);
      setResponse({
        ...iResponse,
        status: false,
        message: "Something went wrong.",
      });
    }
  };

  useEffect(() => {
    const loadScriptByURL = (
      id: string,
      url: string,
      callback: VoidFunction
    ) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {
        console.log("Script loaded!");
      }
    );
  }, [SITE_KEY]);

  return (
    <section className="contact_info_area">
      <div className="container">
        <h2 className="title_contact f_p f_size_22 t_color3 f_400 l_height33 mb_33">
          {<LanguageTranslater langKey="contactUs.form.title" />}
        </h2>
        <form
          className="contact_form_box"
          method="post"
          id="contactForm"
          onSubmit={handleSubmit(onHandleClick)}
        >
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group text_box">
                <input
                  type="text"
                  id="name"
                  ref={register}
                  name="name"
                  placeholder={t("contactUs.form.name")}
                />
                <span className="error_res f_p">{errors.name?.message}</span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group text_box">
                <input
                  type="text"
                  name="email"
                  id="email"
                  ref={register}
                  placeholder={t("contactUs.form.email")}
                />
                <span className="error_res f_p">{errors.email?.message}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group text_box">
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  ref={register}
                  placeholder={t("contactUs.form.subject")}
                />
                <span className="error_res f_p">{errors.subject?.message}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group text_box">
                <textarea
                  name="message"
                  id="message"
                  ref={register}
                  placeholder={t("contactUs.form.message")}
                ></textarea>
                <span className="error_res f_p">{errors.message?.message}</span>
              </div>
            </div>
          </div>
          <button type="submit" className="btn_three">
            {<LanguageTranslater langKey="contactUs.form.btn_text" />}
          </button>
          <p className="f_p recaptcha_message">
            {<LanguageTranslater langKey="contactUs.form.captchaMsg1" />}
            <a
              href="https://policies.google.com/privacy"
              rel="noreferrer"
              target="_blank"
            >
              {" "}
              {<LanguageTranslater langKey="contactUs.form.captchaMsg2" />}{" "}
            </a>
            {<LanguageTranslater langKey="contactUs.form.captchaMsg3" />}
            <a
              href="https://policies.google.com/terms"
              rel="noreferrer"
              target="_blank"
            >
              {" "}
              {
                <LanguageTranslater langKey="contactUs.form.captchaMsg4" />
              }.{" "}
            </a>
            {<LanguageTranslater langKey="contactUs.form.captchaMsg5" />}
          </p>
        </form>
        {isSubmit && iResponse.message && (
          <div
            className={
              iResponse.status
                ? "alert alert-success text-center f_p"
                : "alert alert-danger text-center f_p"
            }
          >
            {iResponse.message}
          </div>
        )}
      </div>
    </section>
  );
};
export default ContactForm;
