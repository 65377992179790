import React from "react";
import { Link, NavLink } from "react-router-dom";
import Sticky from "react-stickynode";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../../Common";

import "./header.css";
interface HeaderProps {
  slogo: string;
  mClass: string;
  nClass: string;
  cClass: string;
  hbtnClass: string;
  isAltLogo?: boolean;
  isAltNav?: boolean;
}

export const HeaderMenu: React.FunctionComponent<HeaderProps> = (
  props: HeaderProps
) => {
  const { t } = useTranslation();
  const {
    mClass,
    nClass,
    cClass,
    slogo,
    hbtnClass,
    isAltLogo,
    isAltNav,
  } = props;
  const logoName = isAltLogo ? "whiteAlt" : "white";
  return (
    <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
      <header className="header_area">
        <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
          <div className={`container ${cClass}`}>
            <Link className={`navbar-brand ${slogo}`} to="/">
              <img
                src={require(`../../../assets/img/${logoName}.png`).default}
                alt={logoName}
              />
            </Link>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="menu_toggle">
                <span className="hamburger">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <span className="hamburger-cross">
                  <span></span>
                  <span></span>
                </span>
              </span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <div className="right_menu_bar">
                <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                  <li className="nav-item dropdown submenu mega_menu mega_menu_two">
                    <Link
                      to="./"
                      className={`dropdown-toggle nav-link ${
                        isAltNav ? "custom_nav" : ""
                      }`}
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {t("title.header.Home")}
                    </Link>
                  </li>

                  <li className="dropdown submenu nav-item business_menu">
                    <a
                      href="javascript:void(0);"
                      title="Pages"
                      className={`dropdown-toggle nav-link  ${
                        isAltNav ? "custom_nav" : ""
                      }`}
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {t("title.header.Bussinesses")}
                    </a>
                    <div className="dropdown-menu">
                      <div className="row">
                        <div className="col-sm-6">
                          <ul role="menu">
                            <li className="nav-item">
                              <NavLink
                                exact
                                title="Solutions"
                                className="nav-link"
                                to="/solutions"
                              >
                                {t("title.BUSINESS.Solutions")}
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                exact
                                title="Accept Crypto"
                                className="nav-link"
                                to="/accept-crypto"
                              >
                                {t("title.BUSINESS.AcceptCrypto")}
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                exact
                                title="Subscriptions"
                                className="nav-link"
                                to="/subscription"
                              >
                                {t("title.BUSINESS.Subscriptions")}
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                exact
                                title="Top Ups"
                                className="nav-link"
                                to="/top-ups"
                              >
                                {t("title.BUSINESS.TopUps")}
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                exact
                                title="Other Billing Models"
                                className="nav-link"
                                to="/other-billing-models"
                              >
                                {t("title.BUSINESS.OtherBillingModels")}
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                        <div className="col-sm-6">
                          <ul role="menu" className="">
                            <li className="nav-item">
                              <span
                                title="Solutions"
                                className="nav-link f_w_600 menu_bottom_border"
                              >
                                {t("title.BUSINESS.SolutionFor")}
                              </span>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                exact
                                title="Adult Entertainment"
                                className="nav-link"
                                to="/adult-entertainment"
                              >
                                {t("title.BUSINESS.AdultEntertainment")}
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                exact
                                title="Gaming"
                                className="nav-link"
                                to="/gaming"
                              >
                                {t("title.BUSINESS.Gaming")}
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                exact
                                title="Forex"
                                className="nav-link"
                                to="/forex"
                              >
                                {t("title.BUSINESS.Forex")}
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="dropdown submenu nav-item">
                    <a
                      href="javascript:void(0);"
                      title="Pages"
                      className={`dropdown-toggle nav-link ${
                        isAltNav ? "custom_nav" : ""
                      }`}
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {t("title.header.Personal")}
                    </a>
                    <ul role="menu" className=" dropdown-menu">
                      <li className="nav-item">
                        <NavLink
                          exact
                          title="Pay with Crypto"
                          className="nav-link"
                          to="/pay-with-crypto"
                        >
                          {t("title.PERSONAL.PaywithCrypto")}
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          exact
                          title="PumaPay App"
                          className="nav-link"
                          to="/puma-pay-app"
                        >
                          {t("title.PERSONAL.PumaPayApp")}
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <a
                          target="_blank"
                          title="PumaPay App"
                          className="nav-link"
                          href="https://wiki.pumapay.io/pumapay-wallet"
                          rel="noreferrer"
                        >
                          {t("title.PERSONAL.FAQ")}
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown submenu nav-item">
                    <a
                      href="javascript:void(0);"
                      title="Pages"
                      className={`dropdown-toggle nav-link ${
                        isAltNav ? "custom_nav" : ""
                      }`}
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {t("title.header.DeFi")}
                    </a>
                    <ul role="menu" className=" dropdown-menu">
                      <li className="nav-item">
                        <a
                          rel="noreferrer"
                          href="https://pumapay.io/introducing-pumapay-v3-0"
                          className="nav-link"
                          target="_blank"
                        >
                          {t("title.DeFi.PumaPay V3.0")}
                        </a>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          exact
                          title="Liquidity Providers"
                          className="nav-link"
                          to="/liquidity-providers"
                        >
                          {t("title.DeFi.LiquidityProviders")}
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          exact
                          title="Executors"
                          className="nav-link"
                          to="/executors"
                        >
                          {t("title.DeFi.Executors")}
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          exact
                          title="PumaPay Bridge"
                          className="nav-link"
                          to="/pumaPay-bridge"
                        >
                          {t("title.DeFi.PumaPayBridge")}
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown submenu nav-item">
                    <a
                      href="javascript:void(0);"
                      title="Pages"
                      className={`dropdown-toggle nav-link ${
                        isAltNav ? "custom_nav" : ""
                      }`}
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {t("title.header.Developers")}
                    </a>
                    <ul role="menu" className=" dropdown-menu">
                      <li className="nav-item">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://github.com/pumapayio"
                          className="nav-link"
                        >
                          {t("title.DEVELOPERS.Code")}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://api-docs.pumapay.io/#introduction"
                          className="nav-link"
                        >
                          {t("title.DEVELOPERS.Documentation")}
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown submenu">
                    <a
                      className={`dropdown-toggle nav-link ${
                        isAltNav ? "custom_nav" : ""
                      }`}
                      href="javascript:void(0);"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {t("title.header.About")}
                    </a>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <NavLink
                          exact
                          title="Info"
                          className="nav-link"
                          to="/info"
                        >
                          {t("title.ABOUT.Info")}
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          exact
                          title="Blog"
                          className="nav-link"
                          to="/blog"
                        >
                          {t("title.ABOUT.Blog")}
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          exact
                          title="Contact"
                          className="nav-link"
                          to="/contact"
                        >
                          {t("title.ABOUT.Contact")}
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
                <a
                  className={`btn_get btn_hover ${hbtnClass} ${
                    isAltNav ? "cutom_btn_header" : ""
                  }`}
                  href="https://console.pumapay.io/register"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("title.getstarted")}
                </a>
                {/* <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                  <li className="nav-item dropdown submenu mega_menu mega_menu_two">
                    ENG
                  </li>
                </ul> */}
              </div>
              <div
                className={`languageDrop ${
                  isAltNav === true ? "custom_drop_default" : "custom_drop"
                }`}
              >
                <LanguageSwitcher />
              </div>
            </div>
          </div>
        </nav>
      </header>
    </Sticky>
  );
};

export default HeaderMenu;
