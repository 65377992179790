import React from "react";
import { FeatureItem } from "../../Common";
import { useTranslation } from "react-i18next";
import "../liquidityproviders.css";

export const LiquidityPoolsSpecial: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className="agency_featured_area_two pb-0 bg_outlet liquidity_special_section">
      <div className="container">
        <h1 className="outlet_title">
          {t("liquidityProviders.liquidityPoolsSpecial.mainHeader")}
        </h1>
        <div className="features_info_steps">
          <FeatureItem
            rowClass="row flex-row-reverse paycrypto_mobile_reverse"
            fimage="pma_usability.png"
            bClass="text-center"
            fname="liquidity-providers"
            aClass="feature_title"
            iImg="icon-hand.png"
            ftitle={t("liquidityProviders.liquidityPoolsSpecial.header1")}
            firstDescription={t(
              "liquidityProviders.liquidityPoolsSpecial.text1"
            )}
          />
          <FeatureItem
            rowClass="row agency_featured_item_two paycrypto_mobile_reverse"
            fimage="higher_earning_potential.png"
            bClass="text-center"
            fname="liquidity-providers"
            aClass="text-left feature_title"
            iImg="icon-money.png"
            leftClass="col-lg-5 offset-lg-1"
            ftitle={t("liquidityProviders.liquidityPoolsSpecial.header2")}
          >
            <>
              {t("liquidityProviders.liquidityPoolsSpecial.text2.subText1")}
              <a href="https://go.pumapay.io/2QfLF6R" className="color_link">
                {t("liquidityProviders.liquidityPoolsSpecial.text2.subText2")}
              </a>
              {t("liquidityProviders.liquidityPoolsSpecial.text2.subText3")}
            </>
          </FeatureItem>
        </div>
      </div>
    </section>
  );
};

export default LiquidityPoolsSpecial;
