import React from "react";

interface ServiceItemsProps {
  itemTitle: string | JSX.Element;
  itemDescription: string | JSX.Element;
  children?: React.ReactNode;
}

const ServiceItems: React.FunctionComponent<ServiceItemsProps> = (
  props: ServiceItemsProps
) => {
  const { itemTitle, itemDescription, children } = props;
  return (
    <div className="">
      <div className="recurring-item">
        <h4 className="recurring-head">{itemTitle}</h4>
        <div className="recurring-text">{itemDescription}</div>
        {children && <div className="recurring-text">{children}</div>}
      </div>
    </div>
  );
};
export default ServiceItems;
