import React from "react";
import { FeatureItem } from "../../Common";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";
import "./top-ups.css";

export const TopUpsContent: React.FC = () => {
  return (
    <section className="agency_featured_area_two pb-0 topup_content">
      <div className="container">
        <div className="features_info_steps">
          <FeatureItem
            rowClass="row agency_featured_item_two item1"
            fimage="image_1.png"
            aClass="topUp-mobile-1"
            bClass="text-center"
            fname="top-up"
            iImg="icon-1.png"
            ftitle={
              <LanguageTranslater langKey="topups.topupContent.item1.title" />
            }
            firstDescription={
              <LanguageTranslater langKey="topups.topupContent.item1.description" />
            }
          />
          <FeatureItem
            rowClass="row flex-row-reverse item2"
            fimage="image_2.png"
            iImg="icon-2.png"
            fname="top-up"
            textClass="pl_60"
            headerClass="p-0"
            aClass="topUp-mobile-2 text-right"
            bClass="text-center"
            ftitle={
              <LanguageTranslater langKey="topups.topupContent.item2.title" />
            }
            firstDescription={
              <LanguageTranslater langKey="topups.topupContent.item2.description" />
            }
          />
          <FeatureItem
            rowClass="row agency_featured_item_two"
            bClass="text-center"
            fimage="image_3.png"
            fname="top-up"
            headerClass="pr_165"
            textClass="pr_86"
            aClass="topUp-mobile-1"
            iImg="icon-3.png"
            ftitle={
              <LanguageTranslater langKey="topups.topupContent.item3.title" />
            }
            firstDescription={
              <LanguageTranslater langKey="topups.topupContent.item3.description" />
            }
          />
        </div>
      </div>
      <div className="get-started-btn text-center">
        <a
          href="https://console.pumapay.io/register"
          rel="noreferrer"
          target="_blank"
          className="btn_hover agency_banner_btn section1_btn"
        >
          <LanguageTranslater langKey="common.getStarted" />
        </a>
      </div>
    </section>
  );
};

export default TopUpsContent;
