import React from "react";
import { SolutionSectitle, CardFlip, CardDetail } from "../../Common";
import AdultEntBenefit from "./AdultEntBenefit";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";

export const AdultEntertainmentBenefits: React.FC = () => {
  return (
    <section className="hosting_service_area sec_pad">
      <div className="benefits-bg">
        <img
          src={
            require("../../../assets/img/solutions-for/benefits-bg-01.png")
              .default
          }
        />
        <img
          src={
            require("../../../assets/img/solutions-for/benefits-bg-02.png")
              .default
          }
        />
      </div>
      <div className="container">
        <SolutionSectitle
          sClass="adult-ent-header text-center "
          title={<LanguageTranslater langKey="adult.benefits.title" />}
          subTitle=""
        />
        <div className="row form-group">
          <AdultEntBenefit
            className="benefits-item"
            icon="zero-transaction-fees.png"
            serviceTitle={
              <LanguageTranslater langKey="adult.benefits.item1.title" />
            }
            servicedescription={
              <LanguageTranslater langKey="adult.benefits.item1.text" />
            }
          />
          <AdultEntBenefit
            className="benefits-item"
            icon="no-chargebacks.png"
            serviceTitle={
              <LanguageTranslater langKey="adult.benefits.item2.title" />
            }
            servicedescription={
              <LanguageTranslater langKey="adult.benefits.item2.text" />
            }
          />
        </div>
        <div className="row form-group">
          <AdultEntBenefit
            className="benefits-item"
            icon="no-moddleman.png"
            serviceTitle={
              <LanguageTranslater langKey="adult.benefits.item3.title" />
            }
            servicedescription={
              <LanguageTranslater langKey="adult.benefits.item3.text" />
            }
          />
          <CardFlip
            customClass="adult_entertainment_flip_card col-sm-6"
            isV3Card={true}
            isComingSoonCard={true}
            FrontCard={
              <CardDetail
                sectionClass="adult_entertainment_benefits_front_card_detail"
                iconPath="solutions-for/no-volatility-risks.png"
                title={
                  <LanguageTranslater langKey="adult.benefits.item4.front.title" />
                }
                des={
                  <LanguageTranslater langKey="adult.benefits.item4.front.text" />
                }
              />
            }
            BackCard={
              <CardDetail
                customClass="adult_entertainment_benefits_back_card_detail"
                iconPath="solutions-for/no-volatility-risks.png"
                title={
                  <LanguageTranslater langKey="adult.benefits.item4.back.title" />
                }
                des={
                  <LanguageTranslater langKey="adult.benefits.item4.back.text" />
                }
              />
            }
          />
        </div>
        <div className="row">
          <AdultEntBenefit
            className="benefits-item benefits-item-with-border form-group"
            icon="accept-crypto.png"
            serviceTitle={
              <LanguageTranslater langKey="adult.benefits.item5.title" />
            }
            servicedescription={
              <LanguageTranslater langKey="adult.benefits.item5.text" />
            }
          />
          <AdultEntBenefit
            className="benefits-item benefits-item-with-border form-group"
            icon="processing-transaction.png"
            serviceTitle={
              <LanguageTranslater langKey="adult.benefits.item6.title" />
            }
            servicedescription={
              <LanguageTranslater langKey="adult.benefits.item6.text" />
            }
          />
        </div>
        <div className="row">
          <div className="col-sm-12 text-center mt_64 btn_get_started_today">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://console.pumapay.io/register"
              className="btn_get btn_hover"
            >
              {<LanguageTranslater langKey="adult.benefits.btn_text" />}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AdultEntertainmentBenefits;
