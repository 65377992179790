import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../ScrollToTop";
import { FooterMenu } from "../../Footer";
import { FloatingTelegram } from "../../Common";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
  const { t } = useTranslation();
  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>{t("document_title")}</title>
      </Helmet>
      <div className="body_wrapper">
        {props.children}
        <FooterMenu />
      </div>
    </>
  );
};

export default Layout;
