import React, { Component } from "react";
import { ServiceItems, LanguageTranslater } from "../../../components/Common";
import { Link } from "react-router-dom";
import "./liquidity-section.css";
const Reveal = require("react-reveal/Reveal");

export class LiquiditySection extends Component {
  render(): JSX.Element {
    return (
      <section className="liquidity-use-crypto-wrapper bg_img_liquidity">
        <div className="container">
          <h2
            className="f_500 text-center mb_20 wow fadeInUp f_p liquidity-use-crypto-title"
            data-wow-delay="0.2s"
          >
            <LanguageTranslater langKey="payWithCrypto.liquiditySection.title" />
          </h2>
          <p className="text-center f_p subheader-use-crypto">
            <LanguageTranslater langKey="payWithCrypto.liquiditySection.description" />
          </p>
          <div className="row justify-content-md-center mt_100">
            <Reveal effect="fadeInUp">
              <div className="col-lg-6 col-sm-6">
                <ServiceItems
                  icon="every-swap.png"
                  title={
                    <LanguageTranslater langKey="payWithCrypto.liquiditySection.item1.title" />
                  }
                  description={
                    <LanguageTranslater langKey="payWithCrypto.liquiditySection.item1.description" />
                  }
                  customClass="custom_wrap liquidity_item_title"
                />
              </div>
            </Reveal>
            <Reveal effect="fadeInUp">
              <div className="col-lg-6 col-sm-6">
                <ServiceItems
                  icon="liquidity-pool.png"
                  title={
                    <LanguageTranslater langKey="payWithCrypto.liquiditySection.item2.title" />
                  }
                  customClass="custom_wrap reverse_wrap liquidity_item_title"
                >
                  <LanguageTranslater langKey="payWithCrypto.liquiditySection.item2.description1" />{" "}
                  <a
                    className="color_link"
                    href="https://go.pumapay.io/3hqeGrJ"
                  >
                    <LanguageTranslater langKey="payWithCrypto.liquiditySection.item2.linkText" />
                  </a>
                  <LanguageTranslater langKey="payWithCrypto.liquiditySection.item2.description2" />
                </ServiceItems>
              </div>
            </Reveal>
          </div>
        </div>
        <div className="row ">
          <div className="col-sm-12 text-center complex_billing_btn mt_50 btn_learn_more">
            <Link to="/liquidity-providers" className="btn_get btn_hover">
              <LanguageTranslater langKey="common.learnMore" />
            </Link>
          </div>
        </div>
      </section>
    );
  }
}
export default LiquiditySection;
