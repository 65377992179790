import React from "react";
import useMediaQuery from "../../../services/UseMediaQuery";
import { ServiceItems, CardFlip, ComingSoonCard } from "../../Common";
import LanguageTranslater from "../../Common/LanguageTranslater";
import "./gameBenefits.css";
const Reveal = require("react-reveal/Reveal");

export const GamingBenefits: React.FC = () => {
  const [width] = useMediaQuery();
  return (
    <section className="game-benefit-wrapper bg_color">
      <div className="container custom_container">
        <h2
          className="f_p f_size_36 f_500 text-center mb_90 wow fadeInUp game-benefit-title"
          data-wow-delay="0.2s"
        >
          {<LanguageTranslater langKey="gaming.gamingBenefitsSection.title" />}
        </h2>
        <div className="row">
          <Reveal effect="fadeInUp">
            <div className="col-md-4 form-group">
              <ServiceItems
                icon="icon1.png"
                title={
                  <LanguageTranslater langKey="gaming.gamingBenefitsSection.No chargebacks.title" />
                }
                description={
                  <LanguageTranslater langKey="gaming.gamingBenefitsSection.No chargebacks.description" />
                }
                customClass="benefit benefit1"
              />
            </div>
          </Reveal>
          <Reveal effect="fadeInUp">
            <div className="col-md-4 form-group">
              <ServiceItems
                icon="zero_transaction.png"
                title={
                  <LanguageTranslater langKey="gaming.gamingBenefitsSection.Zero transaction fee.title" />
                }
                description={
                  <LanguageTranslater langKey="gaming.gamingBenefitsSection.Zero transaction fee.description" />
                }
                customClass="benefit"
              />
            </div>
          </Reveal>
          <Reveal effect="fadeInUp">
            <div className="col-md-4 form-group">
              <ServiceItems
                icon="icon3.png"
                title={
                  <LanguageTranslater langKey="gaming.gamingBenefitsSection.Quick processing time.title" />
                }
                description={
                  <LanguageTranslater langKey="gaming.gamingBenefitsSection.Quick processing time.description" />
                }
                customClass="benefit benefit3"
              />
            </div>
          </Reveal>
        </div>
        <div className="row">
          <Reveal effect="fadeInUp">
            <div className="col-md-4">
              <CardFlip
                isInvert={width < 769 ? true : false}
                FrontCard={
                  <ServiceItems
                    icon="icon4.png"
                    title={
                      <LanguageTranslater langKey="gaming.gamingBenefitsSection.No volatility risks.title" />
                    }
                    description={
                      <LanguageTranslater langKey="gaming.gamingBenefitsSection.No volatility risks.description" />
                    }
                    customClass={width < 769 ? "benefit " : "benefit benefit1"}
                  />
                }
                BackCard={
                  <ServiceItems
                    icon="icon4.png"
                    title={
                      <LanguageTranslater langKey="gaming.gamingBenefitsSection.No volatility risks.title" />
                    }
                    description={
                      <LanguageTranslater langKey="gaming.gamingBenefitsSection.No volatility risks.comingSoon.description" />
                    }
                    customClass={width < 769 ? "benefit " : "benefit benefit1"}
                  />
                }
                isV3Card={true}
                isComingSoonCard={true}
              />
            </div>
          </Reveal>
          <Reveal effect="fadeInUp">
            <div className="col-md-4 service_item_gaming_wrapper">
              <div className="service_item_gaming">
                <ComingSoonCard>
                  <ServiceItems
                    icon="icon5.png"
                    title={
                      <LanguageTranslater langKey="gaming.gamingBenefitsSection.Accept any crypto.title" />
                    }
                    description={
                      <LanguageTranslater langKey="gaming.gamingBenefitsSection.Accept any crypto.description" />
                    }
                    customClass="benefit benefit3"
                  />
                </ComingSoonCard>
              </div>
            </div>
          </Reveal>
          <Reveal effect="fadeInUp">
            <div className="col-md-4 service_item_gaming_wrapper">
              <div className="service_item_gaming">
                <ComingSoonCard isInvert={width < 769 ? true : false}>
                  <ServiceItems
                    icon="icon6.png"
                    title={
                      <LanguageTranslater langKey="gaming.gamingBenefitsSection.Make money by processing transactions.title" />
                    }
                    description={
                      <LanguageTranslater langKey="gaming.gamingBenefitsSection.Make money by processing transactions.description" />
                    }
                    customClass={width < 769 ? "benefit " : "benefit benefit3"}
                  />
                </ComingSoonCard>
              </div>
            </div>
          </Reveal>
        </div>
      </div>
    </section>
  );
};
export default GamingBenefits;
