import React from "react";
import GridItem from "./GridItems";
import { CardFlip } from "../../../components/Common";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";
import "./BillingModelSection.css";

export const BillingModelSection: React.FC = () => {
  return (
    <section className="pt_5 billing_bg">
      <div className="container">
        <h1 className="billing_title f_p">
          {<LanguageTranslater langKey="subscription.billingModel.title" />}
        </h1>
        <p className=" t_color3 l_height30 text-center mb_90 f_p billing_p">
          {
            <LanguageTranslater langKey="subscription.billingModel.description1" />
          }
          <br />
          {
            <LanguageTranslater langKey="subscription.billingModel.description2" />
          }
        </p>
        <div className="row p_service_info">
          <CardFlip
            customClass="subs_biling_nomi"
            FrontCard={
              <GridItem
                title={
                  <LanguageTranslater langKey="subscription.billingModel.nominationCurrency.title" />
                }
                description={
                  <LanguageTranslater langKey="subscription.billingModel.nominationCurrency.description" />
                }
                image="nomination.png"
                subClass="custom_detail"
              />
            }
            BackCard={
              <GridItem
                title={
                  <LanguageTranslater langKey="subscription.billingModel.nominationCurrency.title" />
                }
                description={
                  <LanguageTranslater langKey="subscription.billingModel.nominationCurrency.comingSoon.description" />
                }
                image="nomination-back.png"
                subClass="custom_detail"
              />
            }
            isV3Card={true}
            isComingSoonCard={true}
          />
          <GridItem
            title={
              <LanguageTranslater langKey="subscription.billingModel.frequency.title" />
            }
            description={
              <LanguageTranslater langKey="subscription.billingModel.frequency.description" />
            }
            image="frequency.png"
            customClass="col-lg-6 billing_custon_mobile my-auto"
            subClass="custom_detail frequency_detail"
          />
          <CardFlip
            customClass="subs_biling_settle"
            FrontCard={
              <GridItem
                title={
                  <LanguageTranslater langKey="subscription.billingModel.settlement.title" />
                }
                description={
                  <LanguageTranslater langKey="subscription.billingModel.settlement.description" />
                }
                image="settlement.png"
                customClass="settlement_item"
                subClass="custom_detail"
              />
            }
            BackCard={
              <GridItem
                title={
                  <LanguageTranslater langKey="subscription.billingModel.settlement.comingSoon.title" />
                }
                description={
                  <LanguageTranslater langKey="subscription.billingModel.settlement.comingSoon.description" />
                }
                image="settlement-back.png"
                customClass="settlement_item"
                subClass="custom_detail"
              />
            }
            isV3Card={true}
            isComingSoonCard={true}
          />

          <GridItem
            title={
              <LanguageTranslater langKey="subscription.billingModel.billingLifecycle.title" />
            }
            description={
              <LanguageTranslater langKey="subscription.billingModel.billingLifecycle.description" />
            }
            image="billing.png"
            customClass="col-lg-6 billing_item billing_custon_mobile my-auto"
          />
          <GridItem
            title={
              <LanguageTranslater langKey="subscription.billingModel.trialPeriod.title" />
            }
            description={
              <LanguageTranslater langKey="subscription.billingModel.trialPeriod.description" />
            }
            image="trial.png"
            customClass="col-lg-12"
            subClass="trial_detail"
          />
          <div className="media col-lg-12 h_features_item">
            <a
              href="https://console.pumapay.io/register"
              target="_blank"
              className="btn_sub btn_hover  agency_banner_btn pay_btn pay_btn_two section1_btn"
              rel="noreferrer"
            >
              {
                <LanguageTranslater langKey="common.Accept crypto subscriptions" />
              }
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default BillingModelSection;
