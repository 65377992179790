import React from "react";
const Reveal = require("react-reveal/Reveal");
import LanguageTranslater from "../../../components/Common/LanguageTranslater";
import "./automatedtopup.css";

export const AutomatedTopupSection: React.FC = () => {
  return (
    <section className="agency_featured_area forex_automated_section">
      <div className="container">
        <h2
          className="f_size_30 automated_title f_600 t_color3 l_height40 text-center wow fadeInUp f_p"
          data-wow-delay="0.3s"
        >
          {<LanguageTranslater langKey="forex.automatedTopUp.title" />}
        </h2>
        <div className="features_info">
          <div className={"agency_featured_item row agency_featured_item_two"}>
            <div className="col-lg-6">
              <Reveal effect="fadeInLeft">
                <div className="agency_featured_img text-right">
                  <img
                    src={
                      require("../../../assets/img/forex/forex_image.png")
                        .default
                    }
                    alt="forex_image"
                  />
                </div>
              </Reveal>
            </div>
            <div className="col-lg-6">
              <div
                className="agency_featured_content wow fadeInLeft pl_100"
                data-wow-delay="0.6s"
              >
                <Reveal effect="fadeInRight">
                  <ol>
                    <li className="list_custom">
                      {
                        <LanguageTranslater langKey="forex.automatedTopUp.text1" />
                      }
                    </li>
                    <li className="list_custom">
                      {
                        <LanguageTranslater langKey="forex.automatedTopUp.text2" />
                      }
                    </li>
                    <li className="list_custom">
                      {
                        <LanguageTranslater langKey="forex.automatedTopUp.text3" />
                      }
                    </li>
                    <li className="list_custom">
                      {
                        <LanguageTranslater langKey="forex.automatedTopUp.text4" />
                      }
                    </li>
                  </ol>
                </Reveal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AutomatedTopupSection;
