import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  BlogTagsProps,
  BlogTagItemProps,
} from "./../../ContentfulBlogs/PropTypes";

const BlogTags: React.FunctionComponent<BlogTagsProps> = (
  props: BlogTagsProps
) => {
  const { t } = useTranslation();
  const { blogTags } = props;
  const history = useHistory();
  const [selectedTags, updateSelectedTags] = useState<string[]>(
    props.selectedTags
  );

  const handleTagSearch = (selected_tag: string) => {
    if (props.isSingleBlog) {
      localStorage.setItem("selected_tag", selected_tag);
      history.push(`/blog`);
    }
    if (!selectedTags.includes(selected_tag)) {
      updateSelectedTags([selected_tag, ...selectedTags]);
    } else {
      const tempTags = [...selectedTags];
      tempTags.splice(tempTags.indexOf(selected_tag), 1);
      updateSelectedTags(tempTags);
    }
  };

  const isSelected = (tag_name: string) => {
    if (selectedTags && selectedTags.includes(tag_name)) return "selected_tag";
    else return "";
  };

  useEffect(() => {
    if (props?.filterByTag) {
      props.filterByTag(selectedTags);
    }
  }, [selectedTags, props]);

  return (
    <div className="pb-4 blog_tags">
      <div className="blogs_categories_header d-none d-sm-block">
        {t("blog.tagText")}
      </div>
      <div>
        {blogTags.map((tag: BlogTagItemProps) => {
          const is_tag_selected = isSelected(tag.id);
          return (
            tag.name.length > 0 && (
              <div
                key={tag.name}
                onClick={() => handleTagSearch(tag.id)}
                className={`blogs_tag form-group mr-2 ${is_tag_selected}`}
              >
                <div className="f_p">{tag.name}</div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default BlogTags;
