import React from "react";
import { LanguageTranslater } from "../../Common";
import "./howitwillwork.css";

export const HowItWillWork: React.FC = () => {
  return (
    <section className="executor_how_it_will_work_section">
      <div className="container">
        <div className="section_title">
          <h3>
            <LanguageTranslater langKey="executors.howItWorks.title" />
          </h3>
        </div>
        <div className="section_desc1">
          <p>
            <LanguageTranslater langKey="executors.howItWorks.description1" />
          </p>
        </div>
        <div className="row section_images">
          <div className="col-md-4 image_detail">
            <div className="image1">
              <img
                className="img-fluid"
                src={
                  require("../../../assets/img/executors/software.png").default
                }
                alt="node"
              />
            </div>
            <h3 className="image_title">
              <LanguageTranslater langKey="executors.howItWorks.Node" />
            </h3>
          </div>
          <div className="col-md-4 image_detail">
            <div className="image2">
              <img
                className="img-fluid"
                src={
                  require("../../../assets/img/executors/block_monitor.png")
                    .default
                }
                alt="block monitor"
              />
            </div>
            <h3 className="image_title">
              <LanguageTranslater langKey="executors.howItWorks.Block Monitor" />
            </h3>
          </div>
          <div className="col-md-4 image_detail">
            <div className="image1">
              <img
                className="img-fluid"
                src={
                  require("../../../assets/img/executors/database.png").default
                }
                alt="database"
              />
            </div>
            <h3 className="image_title">
              <LanguageTranslater langKey="executors.howItWorks.Database" />
            </h3>
          </div>
        </div>
        <div className="section_desc2">
          <p>
            <LanguageTranslater langKey="executors.howItWorks.description2" />
          </p>
        </div>
      </div>
    </section>
  );
};
export default HowItWillWork;
