import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Reveal = require("react-reveal/Reveal");
import { Image } from "../../Common";

export const BillingSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="home_customer_billing">
      <section className="payment_features_area home_billing">
        <div className="container d-flex flex-column-reverse">
          <div className="row featured_item">
            <div className="col-lg-6 monthy_subscription">
              <Reveal effect="fadeInLeft">
                <div className="">
                  <Image
                    src={"home/monthy_subs.png"}
                    className="monthy_subs"
                    alt="monthy_subs"
                  />
                  <Image
                    src={"home9/monthy_subs_mobile.png"}
                    className="monthy_subs_mobile"
                    alt="monthy_subs"
                  />
                  <Link
                    to="/subscription"
                    className="btn_hover agency_banner_btn pay_btn pay_btn_two section1_btn home_learn_more_mobile"
                  >
                    {t("common.learnMore")}
                  </Link>
                </div>
              </Reveal>
            </div>
            <div className="col-lg-6 d-flex align-items-center monthy_subscription_detail">
              <Reveal effect="fadeInRight" duration={800}>
                <div className="payment_features_content pl_70">
                  <div className="icon">
                    <img
                      className="img_shape"
                      src={
                        require("../../../assets/img/home9/icon_shape.png")
                          .default
                      }
                      alt="icon_shape"
                    />
                    <img
                      className="icon_img"
                      src={
                        require("../../../assets/img/home9/billing.png").default
                      }
                      alt="Advanced billing models"
                    />
                  </div>
                  <h1 className="f_p f_size_26 f_400 section1_h1">
                    {t("home.billingModels.header")}
                  </h1>
                  <p className="f_p f_size_18 section1_p">
                    {t("home.billingModels.text1")}
                    <b>{t("home.billingModels.text2")}</b>
                    <br /> <br />
                    {t("home.billingModels.text3")}
                  </p>
                  <Link
                    to="/subscription"
                    className="btn_hover agency_banner_btn pay_btn pay_btn_two section1_btn home_learn_more_web"
                  >
                    {t("common.learnMore")}
                  </Link>
                </div>
              </Reveal>
            </div>
          </div>
          <div className="row announcement_section">
            <div className="col-sm-12">
              <div className="announcement_text_area">
                <div className="announcement_text">
                  {t("home.billingModels.announcementText")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default BillingSection;
