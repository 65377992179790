import React from "react";
import { FeatureItem, LanguageTranslater } from "../../Common";
import { Link } from "react-router-dom";
import "./outletforscrypto.css";

export const OutletForCryptoSection: React.FC = () => {
  return (
    <section className="agency_featured_area_two pb-0 bg_outlet">
      <div className="container">
        <h1 className="outlet_title">
          <LanguageTranslater langKey="payWithCrypto.outletForCryptoSection.title" />
        </h1>
        <p className="outlet_desc">
          <LanguageTranslater langKey="payWithCrypto.outletForCryptoSection.description1" />{" "}
          <Link to="/puma-pay-app">
            <LanguageTranslater langKey="payWithCrypto.outletForCryptoSection.linkText" />
          </Link>
          <LanguageTranslater langKey="payWithCrypto.outletForCryptoSection.description2" />
        </p>
        <div className="features_info_steps">
          <FeatureItem
            rowClass="row flex-row-reverse paycrypto_mobile_reverse"
            fimage="ecosystem.png"
            bClass="text-center"
            fname="paywithcrypto"
            aClass="feature_title"
            iImg="icon-3.png"
            ftitle={
              <LanguageTranslater langKey="payWithCrypto.outletForCryptoSection.item1.title" />
            }
            firstDescription={
              <LanguageTranslater langKey="payWithCrypto.outletForCryptoSection.item1.description" />
            }
          />
          <FeatureItem
            rowClass="row agency_featured_item_two paycrypto_mobile_reverse"
            fimage="automate.png"
            bClass="text-center"
            fname="paywithcrypto"
            aClass="text-left feature_title"
            iImg="icon-2.png"
            leftClass="col-lg-5 offset-lg-1"
            ftitle={
              <LanguageTranslater langKey="payWithCrypto.outletForCryptoSection.item2.title" />
            }
            firstDescription={
              <LanguageTranslater langKey="payWithCrypto.outletForCryptoSection.item2.description" />
            }
          />
          <FeatureItem
            rowClass="row flex-row-reverse paycrypto_mobile_reverse"
            bClass="text-center"
            fimage="exchange.png"
            aClass="feature_title"
            fname="paywithcrypto"
            iImg="icon-1.png"
            ftitle={
              <LanguageTranslater langKey="payWithCrypto.outletForCryptoSection.item3.title" />
            }
          >
            <>
              <LanguageTranslater langKey="payWithCrypto.outletForCryptoSection.item3.description1" />{" "}
              <a href="/puma-pay-app" className="color_link">
                <LanguageTranslater langKey="payWithCrypto.outletForCryptoSection.item3.linkText" />
              </a>{" "}
              <LanguageTranslater langKey="payWithCrypto.outletForCryptoSection.item3.description2" />
            </>
          </FeatureItem>
        </div>
      </div>
    </section>
  );
};

export default OutletForCryptoSection;
