import React from "react";
import "./StepsItem.css";
const Reveal = require("react-reveal/Reveal");
import { Image, LanguageTranslater } from "../../../Common";

interface StepItemsProps {
  rowClass: string;
  fimage: string;
  aClass?: string;
  iImg: string;
  ftitle: string | JSX.Element;
  buttonIcon: string;
  firstDescription: string | JSX.Element;
  secondDescription?: string | JSX.Element;
  isLink?: string;
  isSetupBtn?: boolean;
  iconClass: string;
  mobileClass?: string;
}

const StepItems: React.FunctionComponent<StepItemsProps> = (
  props: StepItemsProps
) => {
  const {
    rowClass,
    fimage,
    aClass,
    iImg,
    ftitle,
    buttonIcon,
    firstDescription,
    secondDescription,
    isLink,
    isSetupBtn,
    iconClass,
    mobileClass,
  } = props;

  return (
    <div className={`agency_featured_item ${rowClass} mt-0`}>
      <div className="col-lg-6">
        <Reveal effect="fadeInLeft">
          <div className="agency_featured_img">
            <Image
              className="img-fluid"
              alt={fimage}
              src={`accept-crypto/${fimage}`}
            />
            <a href="javascript:void(0)" className={`icon ${mobileClass}`}>
              <i className={buttonIcon}></i>
            </a>
          </div>
        </Reveal>
      </div>
      <div className="col-lg-6">
        <div
          className={`agency_featured_content crypto_featured_content  wow fadeInLeft ${aClass}`}
          data-wow-delay="0.6s"
        >
          <Reveal effect="fadeInRight">
            <img
              src={require(`../../../../assets/img/home4/${iImg}`).default}
              alt={iImg}
            />
            <h3 className="crypto-step-title">{ftitle}</h3>
            <p className="crypto-step-description">{firstDescription}</p>
            <p className="crypto-step-description">{secondDescription}</p>
            <p>
              {isSetupBtn && (
                <div className="button_slider justify-content-center">
                  <a
                    className={`btn_get btn_hover `}
                    href={isLink}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <LanguageTranslater langKey="common.setUpYourAccount" />
                  </a>
                </div>
              )}
            </p>
            <a href="javascript:void(0)" className={`icon ${iconClass}`}>
              <i className={buttonIcon}></i>
            </a>
          </Reveal>
        </div>
      </div>
    </div>
  );
};
export default StepItems;
