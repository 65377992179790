import React from "react";
import Image from "../../../Common/Image";
import "./sectionitems.css";
const Reveal = require("react-reveal/Reveal");

interface SectionItemsProps {
  rowClass: string;
  image: string;
  title: string | JSX.Element;
  descriptions: string | JSX.Element;
  aClass?: string;
}

const SectionItems: React.FunctionComponent<SectionItemsProps> = (
  props: SectionItemsProps
) => {
  const { rowClass, image, title, descriptions, aClass } = props;

  return (
    <div className={`agency_featured_item ${rowClass}`}>
      <div className="col-lg-6 agency_featured_item_img">
        <Reveal effect="fadeInLeft">
          <div className="agency_featured_img text-right">
            <Image
              className="img-fluid"
              src={`otherbilling/${image}`}
              alt={image}
            />
          </div>
        </Reveal>
      </div>
      <div className="col-lg-6 m_a">
        <div
          className={`agency_featured_content wow fadeInLeft ${aClass}`}
          data-wow-delay="0.6s"
        >
          <Reveal effect="fadeInRight">
            <h1 className="section_title">{title}</h1>
            <p className="section_desc">{descriptions}</p>
          </Reveal>
        </div>
      </div>
    </div>
  );
};

export default SectionItems;
