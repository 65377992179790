import React, { useState, useEffect } from "react";
const Reveal = require("react-reveal/Reveal");
import useMediaQuery from "../../../services/UseMediaQuery";
import "./floatingtelegram.css";

export const FloatingTelegram: React.FC = () => {
  const [icon, setIcon] = useState(false);
  const [width] = useMediaQuery();

  const listenScrollEvent = () => {
    if (window.scrollY > 50) {
      return setIcon(true);
    }
    return setIcon(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <div>
      {width < 769 ? (
        <a href="https://pumapay.io" target="_blank" rel="noreferrer">
          <div
            className={
              icon !== true ? "float_telegram" : "float_telegram float_scroll"
            }
          >
            <Reveal effect="fadeInLeft">
              <img
                src={
                  require(`../../../assets/img/common/new_floating_tele.svg`)
                    .default
                }
                alt="Floating-Telegram"
                className="img-fluid"
              />
            </Reveal>
            <span>Join our community</span>
          </div>
        </a>
      ) : null}
    </div>
  );
};

export default FloatingTelegram;
