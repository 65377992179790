import React from "react";
import "./serviceItems.css";

interface ServiceItemsProps {
  icon: string;
  title: string | JSX.Element;
  description?: string | JSX.Element;
  customClass?: string;
  children?: React.ReactNode;
}

export const ServiceItems: React.FC<ServiceItemsProps> = (
  props: ServiceItemsProps
) => {
  const { icon, title, description, customClass, children } = props;
  return (
    <div className={`wow fadeInUp ${customClass}`}>
      <div>
        <img
          src={require(`../../../assets/img/common/${icon}`).default}
          alt={icon}
        />
      </div>
      <div>
        <h5 className="f_p item-title">{title}</h5>
        <p className="f_p item-description">
          {children ? children : description}
        </p>
      </div>
    </div>
  );
};
export default ServiceItems;
