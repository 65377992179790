import React from "react";
import { useTranslation } from "react-i18next";

interface LanguageTranslaterProps {
  langKey: string;
}
export const LanguageTranslater: React.FunctionComponent<LanguageTranslaterProps> = (
  props: LanguageTranslaterProps
) => {
  const { t } = useTranslation();
  const { langKey } = props;
  return <>{t(langKey)}</>;
};
export default LanguageTranslater;
