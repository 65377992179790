import React from "react";
import { FeatureItem } from "../../Common";
import LanguageTranslater from "../../Common/LanguageTranslater";
import "./game-usecase.css";

export const GamingUseCase: React.FC = () => {
  return (
    <section className="game-usecase-wrapper">
      <div className="container custom_container">
        <h2
          className="f_500 f_size_36 l_height54 text-center wow fadeInUp mb_24 f_p game-usecase-title"
          data-wow-delay="0.2s"
        >
          {<LanguageTranslater langKey="gaming.gamingUseCase.title" />}
        </h2>
        <p className="f_p l_height24 text-center des">
          {<LanguageTranslater langKey="gaming.gamingUseCase.description" />}
        </p>
        <section className="agency_featured_area_two pb-0">
          <div className="container">
            <div className="features_info_steps">
              <FeatureItem
                rowClass="row flex-row-reverse gaming-page-items"
                fimage="useCase1.png"
                iImg="icon1.png"
                fname="gaming"
                aClass="text-left"
                firstDescription={
                  <LanguageTranslater langKey="gaming.gamingUseCase.Your client sets up the top-up.description" />
                }
                ftitle={
                  <LanguageTranslater langKey="gaming.gamingUseCase.Your client sets up the top-up.title" />
                }
              />
              <FeatureItem
                rowClass="row agency_featured_item_two gaming-page-items"
                aClass="mx-auto gaming-mobile-use"
                fimage="useCase2.png"
                fname="gaming"
                iImg="icon2.png"
                firstDescription={
                  <LanguageTranslater langKey="gaming.gamingUseCase.Our protocol automates the payment.description" />
                }
                ftitle={
                  <LanguageTranslater langKey="gaming.gamingUseCase.Our protocol automates the payment.title" />
                }
                imgClass="text-right"
              />
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};
export default GamingUseCase;
