import React from "react";
import SectionItems from "./SectionItems";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";
import "./multiplepaymentsection.css";

interface MultiplePaymentSectionProps {
  customClass?: string;
}

export const MultiplePaymentSection: React.FunctionComponent<MultiplePaymentSectionProps> = (
  props: MultiplePaymentSectionProps
) => {
  const { customClass } = props;
  return (
    <section
      className={`agency_featured_area payment_bg align-items-center h-100 ${customClass}`}
    >
      <div className="container">
        <h2
          className="f_size_30 f_p f_500 t_color3 l_height45 text-center wow fadeInUp"
          data-wow-delay="0.3s"
        >
          {
            <LanguageTranslater langKey="billing.MultiplePaymentSection.title" />
          }
        </h2>
        <div className="features_info">
          <SectionItems
            rowClass="row agency_featured_item_one"
            image="single.png"
            title={
              <LanguageTranslater langKey="billing.MultiplePaymentSection.Single Payment.title" />
            }
            descriptions={
              <LanguageTranslater langKey="billing.MultiplePaymentSection.Single Payment.description" />
            }
            aClass="pl_100"
          />
          <SectionItems
            rowClass="row flex-row-reverse agency_featured_item_two"
            image="pertime.png"
            title={
              <LanguageTranslater langKey="billing.MultiplePaymentSection.Pay-Per-Time.title" />
            }
            descriptions={
              <LanguageTranslater langKey="billing.MultiplePaymentSection.Pay-Per-Time.description" />
            }
            aClass="pr_70 pl_70"
          />
          <SectionItems
            rowClass="row agency_featured_item_three"
            image="peruse.png"
            title={
              <LanguageTranslater langKey="billing.MultiplePaymentSection.Pay-Per-Use.title" />
            }
            descriptions={
              <LanguageTranslater langKey="billing.MultiplePaymentSection.Pay-Per-Use.description" />
            }
            aClass="pl_100"
          />
          <SectionItems
            rowClass="row flex-row-reverse agency_featured_item_four"
            image="api.png"
            title={
              <LanguageTranslater langKey="billing.MultiplePaymentSection.Create & manage.title" />
            }
            descriptions={
              <LanguageTranslater langKey="billing.MultiplePaymentSection.Create & manage.description" />
            }
          />
          <div className="get-started-btn text-center">
            <a
              href="https://console.pumapay.io/register"
              target="_blank"
              className="btn_hover agency_banner_btn section1_btn"
              rel="noreferrer"
            >
              {<LanguageTranslater langKey="common.getStarted" />}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MultiplePaymentSection;
