import React from "react";
import { HeaderMenu, PlainHeaderBanner } from "../../components/Header";
import {
  SpeedAgilitySection,
  MultiplePaymentSection,
} from "../../components/OthersBilling/";
import LanguageTranslater from "../../components/Common/LanguageTranslater";
import { FooterBanner } from "../../components/Footer";
import "./billing.css";

const Billing: React.FC = () => {
  return (
    <>
      <HeaderMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        cClass=""
        hbtnClass=""
      />
      <PlainHeaderBanner
        mClass="other_billing_header"
        title={<LanguageTranslater langKey="billing.headerBanner.title" />}
        text={<LanguageTranslater langKey="billing.headerBanner.description" />}
        headerClass="other_billing_title"
        subHeaderClass="other_billing_subtitle"
      />
      <MultiplePaymentSection customClass="other_billing_payment" />
      <SpeedAgilitySection />

      <FooterBanner
        imgClass="billing_content_bg_simple"
        fbClass="other_billing_footer"
        title={<LanguageTranslater langKey="footerBanner.billing.header" />}
        description={
          <LanguageTranslater langKey="footerBanner.billing.description" />
        }
        btnText={<LanguageTranslater langKey="common.getStarted" />}
        btn_url="https://console.pumapay.io/register"
        fbTitleClass="other_billing"
        fbDesClass="other_billing_des"
      />
    </>
  );
};

export default Billing;
