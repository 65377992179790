import React from "react";
const Reveal = require("react-reveal/Reveal");

interface SectitleProps {
  title: string | JSX.Element;
  subTitle: string | JSX.Element;
  sClass: string;
}
export const SolutionSectitle: React.FunctionComponent<SectitleProps> = (
  props: SectitleProps
) => {
  const { title, subTitle, sClass } = props;
  return (
    <div className={`${sClass}`}>
      <Reveal effect="fadeInUp" duration={1300}>
        <h2 className={`f_p f_size_30 mb_80 l_height50 f_600`}>{title}</h2>
        {subTitle && (
          <p className="mx-auto" style={{ lineHeight: "24px" }}>
            {subTitle}
          </p>
        )}
      </Reveal>
    </div>
  );
};
export default SolutionSectitle;
