import React from "react";
import { ClaimRewardBanner, FeatureItem } from "../../Common/";
import "./infoContentSection.css";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";
import { useTranslation } from "react-i18next";
import { getImageUrl } from "../../../services/utility";

export const InfoContentSection: React.FC = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const imgPath = getImageUrl(language);
  return (
    <section className="agency_featured_area_two pb-0">
      <div className="container info_feature_section">
        <div className="features_info_steps">
          <FeatureItem
            rowClass="row info_feature info_feature_item1"
            fimage="info_image_01.png"
            fname="info"
            iImg=""
            ftitle={<LanguageTranslater langKey="info.content.item1.title" />}
            firstDescription={
              <LanguageTranslater langKey="info.content.item1.text" />
            }
          />
          <FeatureItem
            rowClass="row flex-row-reverse info_feature"
            fimage="info_image_02.png"
            iImg=""
            fname="info"
            bClass="text-center"
            ftitle={<LanguageTranslater langKey="info.content.item2.title" />}
          >
            <span>
              {<LanguageTranslater langKey="info.content.item2.text1" />}
            </span>
            <span>
              {<LanguageTranslater langKey="info.content.item2.text2" />}
            </span>
            <span>
              {<LanguageTranslater langKey="info.content.item2.text3" />}
            </span>
          </FeatureItem>
        </div>
      </div>
      <ClaimRewardBanner />
      <div className="row info_pumapay_journey">
        <div className="col-sm-12 info_pumapay_journey_header text-center">
          {<LanguageTranslater langKey="info.journey.title" />}
        </div>
        <div className="col-sm-12 text-center form-group">
          <img
            src={
              require(`../../../assets/${imgPath}/info/mobile/info_timeline.png`)
                .default
            }
            alt="info_timeline.png"
            className="d-block d-xs-none d-sm-none"
          />
          <img
            src={
              require(`../../../assets/${imgPath}/info/info_timeline.png`)
                .default
            }
            alt="info_timeline.png"
            className="d-none d-xs-block d-sm-block"
          />
        </div>
      </div>
    </section>
  );
};

export default InfoContentSection;
