import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ContentfulBlog from "../../ContentfulBlogs/Contentfulblog";
import { createClient } from "contentful";
import Helmet from "react-helmet";
import BlogNotFound from "../../ContentfulBlogs/BlogNotFound";
import marked from "marked";
import parse from "html-react-parser";
import ImageLoader from "../../../components/Common/ImageLoader";
import {
  blogProps,
  blogItemProps,
  tagItemProps,
} from "../../ContentfulBlogs/PropTypes";

interface ContentfulProps {
  blogData: blogProps;
}

const ContentfulBlogs: React.FunctionComponent<ContentfulProps> = (
  props: ContentfulProps
) => {
  const [blogList, setBlogList] = useState<Array<blogProps>>([]);
  const { blogData } = props;
  const history = useHistory();
  const contentTitle = blogData.title || "";
  const heroImage = blogData.heroImage && blogData.heroImage.fields.file.url;
  const contentDescription = blogData.description || contentTitle;
  const contentBody = blogData.body || contentDescription;
  const dt = new Date(blogData.publishDate);
  const createdDate = dt.getDate();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const createdMonth = dt.getMonth();

  const getClient = async () => {
    return await createClient({
      space: process.env.REACT_APP_CONTENTFUL_ID || "",
      accessToken: process.env.REACT_APP_CONTENTFUL_KEY || "",
    });
  };

  useEffect(() => {
    const getContentfulBlogs = async () => {
      const cClient = await getClient();
      const blogs = await cClient.getEntries({
        limit: 2,
        content_type: "blogPost",
      });
      const blogList = blogs.items.map((item: blogItemProps) => {
        return {
          ...item.fields,
          blog_id: item.sys.id,
          created_at: item.sys.createdAt,
        };
      });
      setBlogList(blogList);
    };
    getContentfulBlogs();
  }, []);

  return (
    <>
      {blogData?.seo && <Helmet>{parse(blogData.seo)}</Helmet>}
      <div>
        <div className="mb_60">
          {heroImage && (
            <ImageLoader
              sClass="w-100 img-fluid single-blog-img"
              imageSrc={heroImage}
              imageAlt={heroImage}
            />
          )}
        </div>
        <div className="blogs_timestamp">
          <div className="date">{createdDate}</div>
          <div className="month">{months[createdMonth]}</div>
        </div>
        <h4 className="h_head f_p">{contentTitle}</h4>
        <div
          className="f_p"
          dangerouslySetInnerHTML={{ __html: marked(contentBody) || "" }}
        />
        {blogData?.tags?.length && (
          <div>
            <hr></hr>
            <div className="d-flex align-items-center single-blog-tags-wrapper">
              <div className="mr-2 single-blog-tags f_p">Tags:</div>
              <div>
                {blogData.tags.map(
                  (tag: tagItemProps) =>
                    tag.fields.name.length > 0 && (
                      <div className="blogs_tag form-group mr-2">
                        <div
                          className="f_p"
                          onClick={() => history.push("/blog")}
                        >
                          {tag.fields.name}
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        )}
        <div className="single-related-wrapper">
          <h3 className="f_p single-related-posts">Related Post</h3>
          <div className="row">
            {blogList.length ? (
              blogList.map(
                (blog: blogProps) =>
                  blog.title.length > 0 && (
                    <ContentfulBlog key={blog.title} blogData={blog} />
                  )
              )
            ) : (
              <BlogNotFound
                header="No Blog Found!!"
                instruction="Search with other query."
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ContentfulBlogs;
