import React from "react";
import { LanguageTranslater } from "../../../components/Common";
import "./pullpayment.css";

export const PullPayment: React.FC = () => {
  return (
    <section className="executor_pull_payment_section">
      <div className="top_bg">
        <img
          className="img-fluid top_bg_img"
          src={
            require("../../../assets/img/executors/pull_payment_top_bg.png")
              .default
          }
          alt="pull-payment"
        />
        <img
          className="img-fluid top_bg_img_mobile"
          src={
            require("../../../assets/img/executors/pull_payment_top_bg_mobile.png")
              .default
          }
          alt="pull-payment"
        />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 my-auto pull_payment_detail">
            <h3>
              {<LanguageTranslater langKey="executors.pullpayment.title" />}
            </h3>
            <p>
              {
                <LanguageTranslater langKey="executors.pullpayment.description" />
              }
            </p>
            <div className="row my-5">
              <div className="col-md-12 executor_program">
                <div className="program_image">
                  <img
                    src={
                      require("../../../assets/img/executors/speaker.png")
                        .default
                    }
                    alt="speaker"
                  />
                </div>
                <div className="program_detail">
                  <h3>
                    {
                      <LanguageTranslater langKey="executors.pullpayment.announcement" />
                    }
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <img
                className="img-fluid executors_img"
                src={
                  require("../../../assets/img/executors/executors.png").default
                }
                alt="pull-payment"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default PullPayment;
