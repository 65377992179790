import React from "react";
import { HeaderMenu } from "../../components/Header";
import {
  AnnouncingBanner,
  ProvideLiquidity,
  BillingSection,
  CustomerInfoSection,
  DecentralizedSolution,
} from "../../components/Home";

import "./home.css";

const Home: React.FC = () => {
  return (
    <>
      <HeaderMenu
        slogo="sticky_logo"
        mClass="menu_four home_page_menu"
        nClass="w_menu"
        cClass=""
        hbtnClass="header_btn"
        isAltNav={true}
      />
      <AnnouncingBanner />
      <ProvideLiquidity />
      <BillingSection />
      <CustomerInfoSection />
      <DecentralizedSolution />
    </>
  );
};

export default Home;
