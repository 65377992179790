const calculateDefaultLang = () => {
  if (indentifyDomainLang("jp.")) {
    return "ja";
  } else {
    return "en";
  }
};

const indentifyDomainLang = (val: string) => {
  return window.location.href.indexOf(val) > -1;
};

export const defaultLanguage: string = calculateDefaultLang();
export const supportedLanguages = [
  { code: "en", name: "English" },
  { code: "ja", name: "日本語" },
];
