import React from "react";
import "./bannerImg.css";

interface BannerImgProps {
  icon: string;
  altText: string;
}

const BannerImg: React.FunctionComponent<BannerImgProps> = (
  props: BannerImgProps
) => {
  const { icon, altText } = props;
  return (
    <div className="col-lg-3 col-md-3 col-4 clients_item four">
      <img
        className="img-fluid"
        src={
          require(`../../../../assets/img/solutions/ecosystem/${icon}`).default
        }
        alt={altText}
      />
    </div>
  );
};
export default BannerImg;
