import React from "react";
import { useHistory } from "react-router-dom";
import { ImageLoader } from "../../../components/Common";
import { blogProps } from "./../PropTypes";

interface RecentPostsProps {
  recentBlogs: blogProps[];
}

const RecentPosts: React.FunctionComponent<RecentPostsProps> = (
  props: RecentPostsProps
) => {
  const { recentBlogs } = props;
  const history = useHistory();
  const getDateFromTimestamp = (dt: string) => {
    const dateTime = new Date(dt);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${
      months[dateTime.getMonth()]
    } ${dateTime.getDate()}, ${dateTime.getFullYear()}`;
  };

  const routeChange = (blog_id: string) => {
    const path = `/${blog_id}`;
    history.push(path);
  };

  const textLimit = 40;

  return (
    <div className="pb-4 blogs_recent_post">
      <div className="recent_post_header d-none d-sm-block">Recent Posts</div>
      {recentBlogs.map(
        (post: blogProps) =>
          post.title.length > 0 && (
            <div
              key={post.slug}
              className="row row d-flex justify-content-center align-items-center form-group"
            >
              <div
                onClick={() => routeChange(post.slug)}
                className="col-4 recent_post_image"
              >
                <ImageLoader
                  sClass="form-group"
                  imageSrc={post.heroImage?.fields.file.url}
                  imageAlt={post.heroImage?.fields.title}
                />
              </div>
              <div className="col-8">
                <div
                  onClick={() => routeChange(post.slug)}
                  className="recent_post_title"
                >
                  {post.title.length > textLimit
                    ? post.title.substring(0, textLimit - 3) + "..."
                    : post.title}
                </div>
                <div className="recent_post_date">
                  {getDateFromTimestamp(post.publishDate)}
                </div>
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default RecentPosts;
