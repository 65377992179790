import React, { useState } from "react";
interface ButtonProps {
  sClass?: string;
  imageSrc: string;
  imageAlt: string;
}
export const ImageLoader: React.FunctionComponent<ButtonProps> = (
  props: ButtonProps
) => {
  const { sClass, imageSrc, imageAlt } = props;
  const [loading, setLoading] = useState(true);
  const imageLoaded = () => {
    setLoading(false);
  };
  return (
    <div className={`${sClass}`}>
      <div
        className="pp_img_loader"
        style={{ display: loading ? "flex" : "none" }}
      >
        <img
          src={require("../../../assets/img/loader.gif").default}
          alt="Loading..."
        />
      </div>
      <img
        style={{ display: loading ? "none" : "block" }}
        src={imageSrc}
        alt={imageAlt}
        onLoad={imageLoaded}
      />
    </div>
  );
};
export default ImageLoader;
