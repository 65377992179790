import React from "react";
const Reveal = require("react-reveal/Reveal");

interface SocialWidgetProps {
  ftitle: string;
}

const SocialWidget: React.FunctionComponent<SocialWidgetProps> = (
  props: SocialWidgetProps
) => {
  const { ftitle } = props;
  return (
    <Reveal effect="fadeInUp" duration={1200}>
      <div className="col-lg-3 col-md-6">
        <div
          className="f_widget about-widget wow fadeInLeft"
          data-wow-delay="0.4s"
          id="social"
        >
          <h3 className="f-title f_600 t_color f_size_18 mb_40">{ftitle}</h3>
          <ul className="list-unstyled f_list">
            <li>
              <a
                href="https://twitter.com/PumaPay"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="social-images"
                  src={
                    require("../../../../assets/img/footer/twitter.png").default
                  }
                  alt="twitter"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/PumaPayOfficial"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="social-images"
                  src={
                    require("../../../../assets/img/footer/facebook.png")
                      .default
                  }
                  alt="facebook"
                />
              </a>
            </li>
            <li>
              <a
                href="https://medium.com/@pumapay"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="social-images"
                  src={
                    require("../../../../assets/img/footer/medium.png").default
                  }
                  alt="medium"
                />
              </a>
            </li>
            <li>
              <a href="/blog">
                <img
                  className="social-images"
                  src={
                    require("../../../../assets/img/footer/blog.png").default
                  }
                  alt="blog"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.reddit.com/r/PumaPay/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="social-images"
                  src={
                    require("../../../../assets/img/footer/reddit.png").default
                  }
                  alt="reddit"
                />
              </a>
            </li>
            <li>
              <a
                href="https://github.com/pumapayio"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="social-images"
                  src={
                    require("../../../../assets/img/footer/github.png").default
                  }
                  alt="github"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Reveal>
  );
};

export default SocialWidget;
