import React from "react";
import WalletCarouselItem from "./PumapayWalletCarouselItem";
import "./pumapay-wallet-carousel.css";
import { LanguageTranslater } from "../../Common";

export const PumapayWalletCarousel: React.FC = () => {
  return (
    <section className="pumapay_wallet_carousel">
      <div className="container">
        <div className="row">
          <div
            id="pumapayWalletApp"
            className="carousel slide wallet_carousel_bg"
            data-ride="carousel"
          >
            <ol className="carousel-indicators pumapay_custom_indicators">
              <li data-target="#pumapayWalletApp" data-slide-to="0"></li>
              <li data-target="#pumapayWalletApp" data-slide-to="1"></li>
              <li
                data-target="#pumapayWalletApp"
                className="active"
                data-slide-to="2"
              ></li>
              <li data-target="#pumapayWalletApp" data-slide-to="3"></li>
              <li data-target="#pumapayWalletApp" data-slide-to="4"></li>
            </ol>
            <div className="carousel-inner custom_carousel">
              <div className="carousel-item active">
                <WalletCarouselItem
                  fimage="mwallet1.png"
                  title={
                    <LanguageTranslater langKey="pumaPayApp.walletCarousel.slide1.title" />
                  }
                  description={
                    <LanguageTranslater langKey="pumaPayApp.walletCarousel.slide1.description" />
                  }
                />
              </div>
              <div className="carousel-item">
                <WalletCarouselItem
                  fimage="mwallet2.png"
                  title={
                    <LanguageTranslater langKey="pumaPayApp.walletCarousel.slide2.title" />
                  }
                  description={
                    <LanguageTranslater langKey="pumaPayApp.walletCarousel.slide2.description" />
                  }
                />
              </div>
              <div className="carousel-item">
                <WalletCarouselItem
                  fimage="mwallet3.png"
                  title={
                    <LanguageTranslater langKey="pumaPayApp.walletCarousel.slide3.title" />
                  }
                  description={
                    <LanguageTranslater langKey="pumaPayApp.walletCarousel.slide3.description" />
                  }
                />
              </div>
              <div className="carousel-item">
                <WalletCarouselItem
                  fimage="mwallet4.png"
                  title={
                    <LanguageTranslater langKey="pumaPayApp.walletCarousel.slide4.title" />
                  }
                  description={
                    <LanguageTranslater langKey="pumaPayApp.walletCarousel.slide4.description" />
                  }
                />
              </div>
              <div className="carousel-item">
                <WalletCarouselItem
                  fimage="mwallet5.png"
                  title={
                    <LanguageTranslater langKey="pumaPayApp.walletCarousel.slide5.title" />
                  }
                  description={
                    <LanguageTranslater langKey="pumaPayApp.walletCarousel.slide5.description" />
                  }
                />
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#pumapayWalletApp"
              role="button"
              data-slide="prev"
            >
              <img
                src={
                  require("../../../assets/img/common/arrow_left.png").default
                }
                alt="arrow_left"
              />
            </a>
            <a
              className="carousel-control-next"
              href="#pumapayWalletApp"
              role="button"
              data-slide="next"
            >
              <img
                src={
                  require("../../../assets/img/common/arrow_right.png").default
                }
                alt="arrow_right"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default PumapayWalletCarousel;
