import React from "react";
import useMediaQuery from "../../services/UseMediaQuery";
import { HeaderMenu } from "../../components/Header";
import {
  AdultEntertainmentBanner,
  AdultEntertainmentBenefits,
  ComplexBilling,
  CommentsOnPumaPay,
  RecurringBillingModel,
  TrustedByIndustry,
} from "../../components/AdultEntertainment";
import LanguageTranslater from "../../components/Common/LanguageTranslater";
import { FooterBanner } from "../../components/Footer";
import "../../assets/css/solutions-for.css";
import "../../assets/css/adult-entertainment.css";

const AdultEntertainment: React.FC = () => {
  const [width] = useMediaQuery();
  return (
    <>
      <HeaderMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        cClass="adult_header"
        hbtnClass=""
        isAltLogo={!(width < 769)}
      />
      <AdultEntertainmentBanner
        title={<LanguageTranslater langKey="adult.subTitle" />}
        header={<LanguageTranslater langKey="adult.title" />}
        description={<LanguageTranslater langKey="adult.text" />}
        customClass="adult_header_banner"
        btnText={<LanguageTranslater langKey="adult.btn_text" />}
        bgImage="adult-entertainment-header.png"
        btnUrl="https://console.pumapay.io/register"
      />
      <AdultEntertainmentBenefits />
      <ComplexBilling />
      <CommentsOnPumaPay />
      <RecurringBillingModel />
      <TrustedByIndustry />

      <FooterBanner
        imgClass="billing_content_bg_dark"
        title={<LanguageTranslater langKey="adult.footerBanner.title" />}
        description={<LanguageTranslater langKey="adult.footerBanner.text" />}
        btnText={<LanguageTranslater langKey="adult.footerBanner.btn_text" />}
        btn_url="https://console.pumapay.io/register"
      />
    </>
  );
};

export default AdultEntertainment;
