import Home from "../containers/Home";
import Solutions from "../containers/Solutions";
import Blogs from "../containers/Blogs";
import Blog from "../containers/Blog";
import Subscription from "../containers/Subscription";
import AcceptCrypto from "../containers/AcceptCrypto";
import Billing from "../containers/Billing";
import Info from "../containers/Info";
import Contact from "../containers/Contact";
import AdultEntertainment from "../containers/AdultEntertainment";
import Gaming from "../containers/Gaming";
import Forex from "../containers/Forex";
import PayWithCrypto from "../containers/PayWithCrypto";
import PumaPayApp from "../containers/PumaPayApp";
import TopUps from "../containers/TopUps";
import NotFound from "../containers/404NotFound";
import LiquidityProviders from "../containers/LiquidityProviders";
import Executors from "../containers/Executors";
import PumaPayBridge from "../containers/PumaPayBridge";
import RewardScheme from "../containers/RewardScheme";
import React from "react";

interface IRoutes {
  name: string;
  path: string;
  component: React.ComponentType;
  exact?: boolean;
  containParam?: boolean;
  params?: Array<Param>;
}

interface Param {
  id: string;
  value: string;
}

export const jsonData: Array<IRoutes> = [
  { path: "/", component: Home, name: "Home", exact: true },
  { path: "/solutions", component: Solutions, name: "Solutions", exact: false },
  { path: "/blog", component: Blogs, name: "Blogs", exact: false },
  {
    path: "/subscription",
    component: Subscription,
    name: "Subscription",
    exact: false,
  },
  {
    path: "/accept-crypto",
    component: AcceptCrypto,
    name: "AcceptCrypto",
    exact: false,
  },
  {
    path: "/other-billing-models",
    component: Billing,
    name: "Billing",
    exact: false,
  },
  { path: "/info", component: Info, name: "Info", exact: false },
  { path: "/contact", component: Contact, name: "Contact", exact: false },
  {
    path: "/adult-entertainment",
    component: AdultEntertainment,
    name: "AdultEntertainment",
    exact: false,
  },
  { path: "/gaming", component: Gaming, name: "Gaming", exact: false },
  { path: "/forex", component: Forex, name: "Forex", exact: false },
  {
    path: "/pay-with-crypto",
    component: PayWithCrypto,
    name: "PayWithCrypto",
    exact: false,
  },
  {
    path: "/puma-pay-app",
    component: PumaPayApp,
    name: "PumaPayApp",
    exact: false,
  },
  { path: "/top-ups", component: TopUps, name: "TopUps", exact: false },
  { path: "/not-found", component: NotFound, name: "NotFound", exact: false },
  {
    path: "/liquidity-providers",
    component: LiquidityProviders,
    name: "LiquidityProviders",
    exact: false,
  },
  { path: "/executors", component: Executors, name: "Executors", exact: false },
  {
    path: "/pumaPay-bridge",
    component: PumaPayBridge,
    name: "PumaPayBridge",
    exact: false,
  },
  {
    path: "/rewards",
    component: RewardScheme,
    name: "RewardScheme",
    exact: false,
  },
  {
    path: "/:slug",
    component: Blog,
    name: "Blog",
    exact: false,
  },
];

export default jsonData;
