import React from "react";
import "./announcingBanner.css";
import { Image } from "../../../components/Common";

export const AnnouncingBanner: React.FC = () => {
  return (
    <div className="row pt-100">
      <div className="col-sm-12 text-center announcing_banner">
        <a href="https://pumapay.io/introducing-pumapay-v3-0">
          <Image
            className="img-fluid announcing_banner_bg"
            src="home/home-page-header-bg.png"
            alt="home-page-header-bg"
          />
          <Image
            className="img-fluid announcing_banner_bg_mobile"
            src="home/home-page-header-bg-mobile.png"
            alt="home-page-header-bg-mobile"
          />
        </a>
      </div>
    </div>
  );
};

export default AnnouncingBanner;
