import React from "react";
import { LanguageTranslater } from "../../../components/Common";
import "./partners.css";

export const Partners: React.FC = () => {
  return (
    <section className="executor_partners_section">
      <div className="container">
        <h1 className="executor_partners_title">
          {<LanguageTranslater langKey="executors.partners.description1" />}
          <span>
            {<LanguageTranslater langKey="executors.partners.description2" />}
          </span>
        </h1>
      </div>
      <div className="partners_logs">
        <img
          className="img-fluid partners_logos_img"
          src={
            require("../../../assets/img/executors/partners_logos.png").default
          }
          alt="partner's logo"
        />
        <img
          className="img-fluid partners_logos_img_mobile"
          src={
            require("../../../assets/img/executors/partners_logos_mobile.png")
              .default
          }
          alt="partner's logo"
        />
      </div>
    </section>
  );
};
export default Partners;
