import React from "react";
import { HeaderMenu } from "../../components/Header";
import { InfoBanner, InfoContentSection } from "../../components/Info";
import LanguageTranslater from "../../components/Common/LanguageTranslater";
import "../../assets/css/info.css";

const Info: React.FC = () => {
  return (
    <div className="info_page">
      <HeaderMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        cClass="info_header"
        hbtnClass=""
      />
      <InfoBanner
        title=""
        customClass="info_page_banner"
        header={
          <>
            <LanguageTranslater langKey="info.title1" />{" "}
            <strong>
              <LanguageTranslater langKey="info.title2" />
            </strong>
          </>
        }
        description={<LanguageTranslater langKey="info.text" />}
        btnText={<LanguageTranslater langKey="info.btn_text" />}
        bgImage="adult-entertainment-header.png"
        btnUrl="/docs/pumapay_whitepaper.pdf"
      />
      <InfoContentSection />
    </div>
  );
};

export default Info;
