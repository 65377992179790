import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  BlogCategoryProps,
  BlogCategoryItemProps,
} from "./../../ContentfulBlogs/PropTypes";

const BlogCategory: React.FunctionComponent<BlogCategoryProps> = (
  props: BlogCategoryProps
) => {
  const { t } = useTranslation();
  const { categories } = props;
  const history = useHistory();
  const [selectedCategory, updateSelectedCategory] = useState<string[]>(
    props.selectedCategory
  );

  const handleCategorySearch = (selected_category: string) => {
    if (props.isSingleBlog) {
      localStorage.setItem("selected_category", selected_category);
      history.push(`/blog`);
    }
    if (!selectedCategory.includes(selected_category)) {
      updateSelectedCategory([selected_category, ...selectedCategory]);
    } else {
      const tempCategory = [...selectedCategory];
      tempCategory.splice(tempCategory.indexOf(selected_category), 1);
      updateSelectedCategory(tempCategory);
    }
  };

  const isSelected = (category_name: string) => {
    if (selectedCategory && selectedCategory.includes(category_name))
      return "selected_category";
    else return "";
  };

  useEffect(() => {
    if (props?.filterByCategory) {
      props.filterByCategory(selectedCategory);
    }
  }, [selectedCategory, props]);

  return (
    <div className="pb-4 blogs_categories">
      <div className="blogs_categories_header d-none d-sm-block">
        {t("blog.categoryText")}
      </div>
      {categories.map((category: BlogCategoryItemProps) => {
        const is_category_selected = isSelected(category.id);
        return (
          category.name.length > 0 && (
            <div
              key={category.id}
              onClick={() => handleCategorySearch(category.id)}
              className={`blogs_category row row d-flex justify-content-center align-items-center form-group pt-2 pb-2 ${is_category_selected}`}
            >
              <div className="col-sm-12 f_p">{category.name}</div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default BlogCategory;
