import React from "react";
import PumaPayBridge from "./PumaPayBridge";
import PumaPayBridgeWork from "./PumaPayBridgeWork";
import "./pumapaybridge.css";

export const PumaPayBridgeMain: React.FC = () => {
  return (
    <section className="pumapay_bridge_main_section">
      <PumaPayBridge />
      <PumaPayBridgeWork />
    </section>
  );
};
export default PumaPayBridgeMain;
