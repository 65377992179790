import React from "react";
import "./how-it-works.css";
const { Reveal, Fade } = require("react-reveal");
import LanguageTranslater from "../../../components/Common/LanguageTranslater";
import Image from "../../../components/Common/Image";

export const HowItWorks: React.FC = () => {
  return (
    <section className="how_its_work_wrapper">
      <div className="container">
        <div className="how-it-works-title text-center">
          <Reveal effect="fadeInUp" duration={1300}>
            <h2>
              <LanguageTranslater langKey="topups.howItWorks.title" />
            </h2>
          </Reveal>
        </div>
        <div className="row">
          <Fade bottom duration={500}>
            <div className="col-md-6 how_its_work_item1">
              <Image src="top-up/step1.png" className="img-fluid" alt="step1" />
              <div className="works-content step-1">
                <LanguageTranslater langKey="topups.howItWorks.item1.description1" />{" "}
                <a
                  href="https://console.pumapay.io/register"
                  rel="noreferrer"
                  target="_blank"
                >
                  <LanguageTranslater langKey="topups.howItWorks.item1.linkText" />
                </a>{" "}
                <LanguageTranslater langKey="topups.howItWorks.item1.description2" />
              </div>
            </div>
          </Fade>
          <Fade bottom duration={500}>
            <div className="col-md-6 how_its_work_item2">
              <Image src="top-up/step2.png" className="img-fluid" alt="step2" />
              <div className="works-content">
                <LanguageTranslater langKey="topups.howItWorks.item2.description" />
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};
export default HowItWorks;
