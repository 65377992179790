import React from "react";
import { useTranslation } from "react-i18next";
const Reveal = require("react-reveal/Reveal");

const PumaPayBridgeWork: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="container pumapay_bridge_work_container">
      <hr className="d-sm-none pb-4" />
      <h1 className="title">{t("pumapayBridge.pumaPayBridgeWork.title")}</h1>
      <p className="title_desc">
        <span>{t("pumapayBridge.pumaPayBridgeWork.description.para1")}</span>
        <span>{t("pumapayBridge.pumaPayBridgeWork.description.para2")}</span>
        <span>{t("pumapayBridge.pumaPayBridgeWork.description.para3")}</span>
      </p>
      <div className="row bridge_flow">
        <Reveal effect="fadeInUp">
          <div className="col-lg-6 col-sm-6 item1">
            <h5 className="bridge_flow_title">
              {t("pumapayBridge.pumaPayBridgeWork.bridgeFlow.item1.title")}
            </h5>
            <div className="bridge_flow_img">
              <img
                src={
                  require(`../../../../assets/img/pumapay-bridge/bridge-flow-1.png`)
                    .default
                }
                alt="bridge-flow-1"
              />
            </div>
            <div className="my-auto">
              <p className="desc">
                {t(
                  "pumapayBridge.pumaPayBridgeWork.bridgeFlow.item1.description"
                )}
              </p>
            </div>
          </div>
        </Reveal>
        <Reveal effect="fadeInUp">
          <div className="col-lg-6 col-sm-6 item2">
            <h5 className="bridge_flow_title">
              {t("pumapayBridge.pumaPayBridgeWork.bridgeFlow.item2.title")}
            </h5>
            <div className="bridge_flow_img">
              <img
                src={
                  require(`../../../../assets/img/pumapay-bridge/bridge-flow-2.png`)
                    .default
                }
                alt="bridge-flow-1"
              />
            </div>
            <div className="my-auto">
              <p className="desc">
                {t(
                  "pumapayBridge.pumaPayBridgeWork.bridgeFlow.item2.description"
                )}
              </p>
            </div>
          </div>
        </Reveal>
      </div>
    </div>
  );
};
export default PumaPayBridgeWork;
