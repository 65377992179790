import React from "react";
import { LanguageTranslater } from "../../components/Common";
import { HeaderMenu, PlainHeaderBanner } from "../../components/Header";
import { TopUpsContent, HowItWorks } from "../../components/Topup";
import { FooterBanner } from "../../components/Footer";

const TopUps: React.FC = () => {
  return (
    <>
      <HeaderMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        cClass=""
        hbtnClass=""
      />
      <PlainHeaderBanner
        title={<LanguageTranslater langKey="topups.headerBanner.title" />}
        text={<LanguageTranslater langKey="topups.headerBanner.description" />}
        headerClass="topup_title"
        subHeaderClass="topup_subtitle"
      />
      <TopUpsContent />
      <HowItWorks />

      <FooterBanner
        isTelegram={false}
        imgClass="billing_content_bg_simple"
        title={<LanguageTranslater langKey="topups.footerBanner.title" />}
        description={
          <LanguageTranslater langKey="topups.footerBanner.description" />
        }
        fbDesClass="topup_des"
        btnText={<LanguageTranslater langKey="common.createBillingModel" />}
        btn_url="https://console.pumapay.io/register"
        fbTitleClass="topup"
      />
    </>
  );
};

export default TopUps;
