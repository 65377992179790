import React from "react";
import ContactItem from "./contactItem";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";
import "./contact-content.css";

export const ContactSection: React.FC = () => {
  return (
    <section className="contact_features_area">
      <div className="container">
        <div className="row">
          <ContactItem
            contact_url="bizdev@pumapay.io"
            title={<LanguageTranslater langKey="contactUs.item1.title" />}
            image="contact1.png"
            description={
              <LanguageTranslater langKey="contactUs.item1.subTitle" />
            }
            subDescription={
              <LanguageTranslater langKey="contactUs.item1.text1" />
            }
            type={<LanguageTranslater langKey="contactUs.item1.text2" />}
          />
          <ContactItem
            contact_url="pr@pumapay.io"
            title={<LanguageTranslater langKey="contactUs.item2.title" />}
            image="contact2.png"
            description={
              <LanguageTranslater langKey="contactUs.item2.subTitle" />
            }
            type={<LanguageTranslater langKey="contactUs.item2.text" />}
          />
          <ContactItem
            contact_url="support@pumapay.io"
            title={<LanguageTranslater langKey="contactUs.item3.title" />}
            image="contact3.png"
            description={
              <LanguageTranslater langKey="contactUs.item3.subTitle" />
            }
            subDescription={
              <LanguageTranslater langKey="contactUs.item3.text" />
            }
            isSupport={true}
          />
        </div>
      </div>
    </section>
  );
};
export default ContactSection;
