import React from "react";
import CarouselItem from "./CarouselItem";
import { CardFlip } from "../../../components/Common";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";
import "./billingcaraousel.css";

export const BillingModelCaraousel: React.FC = () => {
  return (
    <section className="h_pricing_area sec_pad bg_img forex_carousel_section">
      <div className="container">
        <div className="text-center custom_head">
          <h1 className="slider_header">
            {<LanguageTranslater langKey="forex.billingModelCarousel.title" />}
          </h1>
          <p className="slider_desc">
            {
              <LanguageTranslater langKey="forex.billingModelCarousel.description" />
            }
          </p>
        </div>
        <div className="row">
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators custom_indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                className="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="2"
              ></li>
            </ol>
            <div className="carousel-inner custom_carousel">
              <div>
                <div className="carousel-item active">
                  <CardFlip
                    customClass="forex-carousel-slide1"
                    FrontCard={
                      <CarouselItem
                        fimage="carousel1.png"
                        iImg="icon01.png"
                        buttonIcon={true}
                        ftitle={
                          <LanguageTranslater langKey="forex.billingModelCarousel.carousel1.title" />
                        }
                        firstDescription={
                          <LanguageTranslater langKey="forex.billingModelCarousel.carousel1.description" />
                        }
                      />
                    }
                    BackCard={
                      <CarouselItem
                        fimage="carousel1-back.png"
                        iImg="icon01.png"
                        buttonIcon={false}
                        ftitle={
                          <LanguageTranslater langKey="forex.billingModelCarousel.carousel1.comingSoon.title" />
                        }
                        firstDescription={
                          <LanguageTranslater langKey="forex.billingModelCarousel.carousel1.comingSoon.description1" />
                        }
                        secondDescription={
                          <>
                            <span>
                              {
                                <LanguageTranslater langKey="forex.billingModelCarousel.carousel1.comingSoon.description2" />
                              }
                            </span>
                            <span>
                              <a
                                href="https://metamask.io/download.html"
                                target="_blank"
                                rel="noreferrer"
                              >
                                {
                                  <LanguageTranslater langKey="forex.billingModelCarousel.carousel1.comingSoon.link" />
                                }
                              </a>
                            </span>
                          </>
                        }
                      />
                    }
                    isV3Card={true}
                    isComingSoonCard={true}
                  />
                </div>
                <div className="carousel-item">
                  <CarouselItem
                    fimage="carousel2.png"
                    iImg="icon02.png"
                    buttonIcon={false}
                    ftitle={
                      <LanguageTranslater langKey="forex.billingModelCarousel.carousel2.title" />
                    }
                    firstDescription={
                      <LanguageTranslater langKey="forex.billingModelCarousel.carousel2.description1" />
                    }
                    secondDescription={
                      <LanguageTranslater langKey="forex.billingModelCarousel.carousel2.description2" />
                    }
                  />
                </div>
                <div className="carousel-item">
                  <CarouselItem
                    fimage="carousel3.png"
                    iImg="icon3.png"
                    buttonIcon={false}
                    ftitle={
                      <LanguageTranslater langKey="forex.billingModelCarousel.carousel3.title" />
                    }
                    firstDescription={
                      <LanguageTranslater langKey="forex.billingModelCarousel.carousel3.description1" />
                    }
                    secondDescription={
                      <LanguageTranslater langKey="forex.billingModelCarousel.carousel3.description2" />
                    }
                  />
                </div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <img
                src={
                  require("../../../assets/img/common/arrow_left.png").default
                }
                alt="arrow_left"
              />
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <img
                src={
                  require("../../../assets/img/common/arrow_right.png").default
                }
                alt="arrow_right"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default BillingModelCaraousel;
