import React from "react";
import LanguageTranslater from "../../components/Common/LanguageTranslater";
import { HeaderMenu, PlainHeaderBanner } from "../../components/Header";
import {
  SolutionService,
  SubBanner,
  ServiceCarouselSection,
  SimpleFeatureSection,
  FastFeatureSection,
  FlexibleFeatureSection,
  RiskFreeFeatureSection,
} from "../../components/Solutions";
import { FooterBanner } from "../../components/Footer";

import "./Solution.css";

const Solutions: React.FC = () => {
  return (
    <>
      <HeaderMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        cClass="solutions_header"
        hbtnClass=""
      />
      <PlainHeaderBanner
        title={<LanguageTranslater langKey="bussiness.headerBanner.title" />}
        text={
          <LanguageTranslater langKey="bussiness.headerBanner.description" />
        }
        mClass="solotion"
        headerClass="solution_title"
        subHeaderClass="solution_subtitle"
      />
      <SolutionService customClass="solution_service_section" />
      <SubBanner
        aClass="h_action_area solution_ecosystem"
        Atext={<LanguageTranslater langKey="bussiness.partners.subtitle" />}
      >
        {<LanguageTranslater langKey="bussiness.partners.title1" />}{" "}
        <span>
          {<LanguageTranslater langKey="bussiness.partners.title2" />}
        </span>
      </SubBanner>
      <SimpleFeatureSection customClass="solution_simple" />
      <FastFeatureSection customClass="solution_fast" />
      <FlexibleFeatureSection customClass="solution_flexible" />
      <RiskFreeFeatureSection customClass="solution_risk_free" />
      <ServiceCarouselSection customClass="solution_carousel" />

      <FooterBanner
        imgClass="billing_content_bg_simple"
        fbClass="solution_footer_banner"
        title={<LanguageTranslater langKey="bussiness.footerBanner.title" />}
        description={
          <LanguageTranslater langKey="bussiness.footerBanner.text" />
        }
        btnText={
          <LanguageTranslater langKey="bussiness.footerBanner.btn_text" />
        }
        btn_url="https://console.pumapay.io/register"
      />
    </>
  );
};

export default Solutions;
