import React from "react";
import "./PlainBanner.css";
interface PlainHeaderBannerProps {
  title: string | JSX.Element;
  text: string | JSX.Element;
  headerClass?: string;
  subHeaderClass?: string;
  mClass?: string;
  isButton?: boolean;
  btnUrl?: string;
  btnText?: string;
}
export const PlainHeaderBanner: React.FC<PlainHeaderBannerProps> = (
  props: PlainHeaderBannerProps
) => {
  const {
    title,
    text,
    headerClass,
    subHeaderClass,
    mClass,
    isButton,
    btnText,
    btnUrl,
  } = props;
  return (
    <section className={`hosting_banner_area ${mClass}`}>
      <div className="container">
        <div className="row">
          <div className="hosting_content mx-auto">
            <h1
              className={`${headerClass} header_banner_title custom_soln_header_banner custom_align `}
              data-wow-delay="0.3s"
            >
              {title}
            </h1>
            <p
              className={` ${subHeaderClass} header_banner_des wow fadeInUp custom_align f_size16 f_p`}
              data-wow-delay="0.5s"
            >
              {text}
            </p>
            {isButton && (
              <div className="btn_header_banner">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={btnUrl}
                  className="btn pp-btn pp-btn-outline-primary"
                >
                  {btnText}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default PlainHeaderBanner;
