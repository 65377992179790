import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface BlogSearchProps {
  handleSearchTextChange: (text: string) => void;
}

const BlogSearch: React.FunctionComponent<BlogSearchProps> = (
  props: BlogSearchProps
) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>("");
  const [debounce, setDebounce] = useState<number>(-1);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchText(value);
    clearTimeout(debounce);
    const inDebounce = window.setTimeout(
      () => props.handleSearchTextChange(value),
      200
    );
    setDebounce(inDebounce);
  };

  return (
    <div className="pb-4 position-relative">
      <input
        placeholder={t("blog.searchText")}
        className="form-control blog_search_input"
        type="text"
        value={searchText}
        onChange={handleInputChange}
      />
      <img
        src={require("../../../assets/img/blogs/search_icon.png").default}
        className="blog_search_icon"
      />
    </div>
  );
};

export default BlogSearch;
