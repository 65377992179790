import React from "react";
import "./infoBanner.css";

interface InfoBannerProps {
  title: string;
  header: string | JSX.Element;
  description: string | JSX.Element;
  btnText: string | JSX.Element;
  bgImage: string;
  btnUrl: string;
  customClass?: string;
}
export const InfoBanner: React.FunctionComponent<InfoBannerProps> = (
  props: InfoBannerProps
) => {
  const { title, header, description, btnText, btnUrl, customClass } = props;
  return (
    <section className={`info_banner ${customClass}`}>
      <div className=""></div>
      <div className="container">
        <div className="row header_detail align-items-center h-100">
          <div className="col-md-6">
            <div className="info_custom">
              <div className="solutions_for_adult_title info_title">
                {title}
              </div>
              <h1
                className="solutions_for_adult_header f_p info_title"
                data-wow-delay="0.3s"
              >
                {header}
              </h1>
              <p
                className="solutions_for_adult_description wow fadeInUp f_size16 f_p info_desc"
                data-wow-delay="0.5s"
              >
                {description}
              </p>
              <div className="solutions_for_adult_button">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={btnUrl}
                  className="btn pp-btn pp-btn-outline-primary f_p f_500"
                >
                  {btnText}
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <img
              src={
                require(`../../../assets/img/info/isometric_banner_bg.png`)
                  .default
              }
              className="img-fluid img_isometric"
              alt="isometric_banner_bg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default InfoBanner;
