import moment from "moment";

const FooterData = {
  //FOR PHASE-1
  footerTitle: ["BUSINESS", "PERSONAL", "DeFi", "DEVELOPERS", "ABOUT"],
  copywrite: `© Decentralized Vision Ltd. All Rights Reserved ${moment().format(
    "YYYY"
  )}`,
  BUSINESS: [
    {
      id: 1,
      url: "/solutions",
      text: "Solutions",
      key: "Solutions",
    },
    {
      id: 2,
      url: "/accept-crypto",
      text: "Accept Crypto",
      key: "AcceptCrypto",
    },
    {
      id: 3,
      url: "/subscription",
      text: "Subscriptions",
      key: "Subscriptions",
    },
    {
      id: 4,
      url: "/top-ups",
      text: "Top-Ups",
      key: "TopUps",
    },
    {
      id: 5,
      url: "/other-billing-models",
      text: "Other Billing Models",
      key: "OtherBillingModels",
    },
    {
      id: 6,
      url: "https://wiki.pumapay.io/pumapay-business-console/overview",
      text: "Documentation",
      isTargetBlank: true,
      key: "Documentation",
    },
  ],
  "": [
    {
      id: 1,
      url: "/",
      text: "Resources",
      isBold: true,
    },
    {
      id: 2,
      url: "/",
      text: "Getting Started",
    },
    {
      id: 3,
      url: "/",
      text: "FAQ",
    },
  ],
  PERSONAL: [
    {
      id: 1,
      url: "/pay-with-crypto",
      text: "Pay with Crypto",
      key: "PaywithCrypto",
    },
    {
      id: 2,
      url: "/puma-pay-app",
      text: "PumaPay App",
      key: "PumaPayApp",
    },
  ],
  DeFi: [
    {
      id: 1,
      url: "https://pumapay.io/introducing-pumapay-v3-0",
      text: "PumaPay V3.0",
      key: "PumaPay V3.0",
      isTargetBlank: true,
    },
    {
      id: 2,
      url: "/liquidity-providers",
      text: "Liquidity Providers",
      key: "LiquidityProviders",
    },
    {
      id: 3,
      url: "/executors",
      text: "Executors",
      key: "Executors",
    },
    {
      id: 4,
      url: "/pumaPay-bridge",
      text: "PumaPay Bridge",
      key: "PumaPayBridge",
    },
  ],
  DEVELOPERS: [
    {
      id: 1,
      url: "https://github.com/pumapayio",
      text: "Code",
      key: "Code",
      isTargetBlank: true,
    },
    {
      id: 2,
      url: "https://api-docs.pumapay.io/#introduction",
      text: "Documentation",
      key: "Documentation",
      isTargetBlank: true,
    },
  ],
  ABOUT: [
    {
      id: 1,
      url: "/info",
      text: "Info",
      key: "Info",
    },
    {
      id: 2,
      url: "/blog",
      text: "Blog",
      key: "Blog",
    },
    {
      id: 4,
      url: "/contact",
      text: "Contact",
      key: "Contact",
    },
    {
      id: 5,
      url: "/docs/pumapay_whitepaper.pdf",
      text: "WhitePaper",
      isTargetBlank: true,
      key: "WhitePaper",
    },
  ],
};
export default FooterData;
