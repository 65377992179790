import React from "react";
import { Link } from "react-router-dom";
const Reveal = require("react-reveal/Reveal");
import Image from "../../../components/Common/Image";
import "./payment.css";

interface PaymentSectionProps {
  mTitle: string | JSX.Element;
  mDescription: string | JSX.Element;
  pImage: string;
  sTitle: string | JSX.Element;
  sDecsription: string | JSX.Element;
  btn_text: string | JSX.Element;
  btn_url: string;
  l_more_btn: string | JSX.Element;
  l_more_url: string;
  fColorDes?: string;
  mDescriptionClass?: string;
}

export const PaymentSection: React.FunctionComponent<PaymentSectionProps> = (
  props: PaymentSectionProps
) => {
  const {
    mTitle,
    mDescription,
    pImage,
    sTitle,
    sDecsription,
    btn_text,
    btn_url,
    l_more_btn,
    l_more_url,
    fColorDes,
    mDescriptionClass,
  } = props;
  return (
    <section className="payment_wrapper">
      <div className="container">
        <div className="payment-title text-center mb_80">
          <Reveal effect="fadeInUp" duration={1300}>
            <h2 className="f_p f_size_36 mb_20 f_500 l_height54 text-center wow fadeInUp payment-title">
              {mTitle}
            </h2>
            <p className={`f_p f_size_20 l_height30 ${mDescriptionClass}`}>
              {mDescription}
            </p>
          </Reveal>
        </div>
        <div className="row forex_payment_content">
          <Reveal effect="fadeInRight" duration={800}>
            <div className="action_btn d-flex align-items-center mt_60 forex_action_btn_mobile">
              <a
                href={btn_url}
                target="_blank"
                rel="noreferrer"
                className="btn_hover agency_banner_btn"
              >
                {btn_text}
              </a>
              <Link
                to={l_more_url}
                className="f_p f_500 l_height30 d-flex learn-more-btn "
              >
                {l_more_btn}
                <i className="ti-arrow-right ml-2"></i>
              </Link>
            </div>
            <div className="col-md-6 forex_payment_img">
              <Image
                className="img-fluid"
                src={`common/${pImage}`}
                alt={pImage}
              />
            </div>
          </Reveal>
          <Reveal effect="fadeInLeft" duration={1200}>
            <div className="col-lg-6 d-flex pl_100 align-items-center gaming_payment_section">
              <div className="pr_70 gaming_payment_content">
                <h1 className="f_p f_size_30 f_600 l_height45 payment-title mb_20">
                  {sTitle}
                </h1>
                <p className={`f_p f_size_16 l_height24 ${fColorDes}`}>
                  {sDecsription}
                </p>
                <div className="action_btn d-flex align-items-center mt_60 forex_action_btn_web">
                  <a
                    href={btn_url}
                    target="_blank"
                    rel="noreferrer"
                    className="btn_hover agency_banner_btn"
                  >
                    {btn_text}
                  </a>
                  <Link
                    to={l_more_url}
                    className="f_p f_500 l_height30 d-flex learn-more-btn "
                  >
                    {l_more_btn}
                    <i className="ti-arrow-right ml-2"></i>
                  </Link>
                </div>
              </div>
            </div>
          </Reveal>
        </div>
      </div>
    </section>
  );
};

export default PaymentSection;
