import React from "react";
import { SolutionSectitle } from "../../Common";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";
//

export const CommentsOnPumaPay: React.FC = () => {
  return (
    <section className="hosting_service_area sec_pad pumapay-comment-section">
      <div className="container">
        <div className="row pumapay-comment-inner-section">
          <div className="col-sm-6">
            <SolutionSectitle
              sClass="adult-ent-header pumapay-comment"
              title={<LanguageTranslater langKey="adult.comments.title" />}
              subTitle={
                <LanguageTranslater langKey="adult.comments.subTitle" />
              }
            />
          </div>
          <div className="col-sm-6 pornhub-comment-section">
            <img
              className="d-none d-sm-block"
              alt="pornhub-comment-bg"
              src={
                require("../../../assets/img/solutions-for/pornhub-comment-bg.png")
                  .default
              }
            />
            <img
              className="d-sm-none"
              alt="pornhub-comment-bg"
              src={
                require("../../../assets/img/solutions-for/mobile/pornhub-comment-mobile-bg.png")
                  .default
              }
            />
            <div className="pornhub-comment-inner-section">
              <img
                src={
                  require("../../../assets/img/solutions-for/pornhub-logo.png")
                    .default
                }
                alt="pornhub-logo"
                className="img-fluid"
              />
              <p>{<LanguageTranslater langKey="adult.comments.comment" />}</p>
              <div className="pornhub-comment-by">
                <h3>
                  {
                    <LanguageTranslater langKey="adult.comments.commenterName" />
                  }
                </h3>
                {<LanguageTranslater langKey="adult.comments.commenterRole" />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CommentsOnPumaPay;
