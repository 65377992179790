import React from "react";
import { HeaderMenu, PlainHeaderBanner } from "../../components/Header";

import Blog from "../../components/ContentfulBlog";
import "../../assets/css/blogs.css";

const ContentfulBlog: React.FC = () => {
  return (
    <>
      <HeaderMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        cClass=""
        hbtnClass=""
      />
      <PlainHeaderBanner title="PumaPay Blog" text="PumaPay Blog" />
      <Blog />
    </>
  );
};

export default ContentfulBlog;
