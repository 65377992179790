import React from "react";
import BannerImg from "./BannerImg";

interface BrandListsProps {
  adult?: boolean;
}

export const BrandLists: React.FunctionComponent<BrandListsProps> = (
  props: BrandListsProps
) => {
  const { adult } = props;
  return (
    <div className="row justify-content-center ecosystem">
      <BannerImg icon="pornhub.png" altText="pornhub" />
      <BannerImg icon="imlive.png" altText="imlive" />
      <BannerImg icon="Camster.png" altText="camster" />
      <BannerImg icon="grooby.png" altText="grooby" />
      {adult && <BannerImg icon="brazzers.png" altText="brazzers" />}
      {adult && <BannerImg icon="Babes.png" altText="babes" />}
      {/* <BannerImg icon="rent14.png" altText="rent14" /> */}
      {!adult && <BannerImg icon="lottocity.png" altText="lottocity" />}
      {!adult && <BannerImg icon="mojohost.png" altText="mojohost" />}
      <BannerImg icon="twistys.png" altText="twistys" />
    </div>
  );
};

export default BrandLists;
