import React from "react";
import useMediaQuery from "../../../services/UseMediaQuery";
import {
  DownloadButtonSection,
  LanguageTranslater,
} from "../../../components/Common";
import "./cryptoBanner.css";

export const PaywithCryptoBanner: React.FC = () => {
  const [width] = useMediaQuery();
  return (
    <section className="crypto_hosting_banner_area">
      <div className="container">
        <div className="row align-items-center h-100">
          <div className="col-md-6 my-auto">
            <h1 className="header_first text-left f_p mb_20">
              <LanguageTranslater langKey="payWithCrypto.headerBanner.title" />
            </h1>
            <p
              className="wow fadeInUp f_p desc_first mb_40"
              data-wow-delay="0.5s"
            >
              <LanguageTranslater langKey="payWithCrypto.headerBanner.description" />
            </p>
            <div>
              <p className="wow fadeInUp f_p l_height21 f_size_18 mb_10 mt_30 banner_color">
                <LanguageTranslater langKey="payWithCrypto.headerBanner.downloadCaption" />
              </p>
              <DownloadButtonSection
                iUrl={process.env.REACT_APP_APP_STORE_URL_I || "#"}
                gUrl={process.env.REACT_APP_APP_STORE_URL_G || "#"}
                iPhoneImg={width < 767 ? "iPhone.png" : "iPhone-black.png"}
                gPlayImg={
                  width < 767 ? "googlePlay.png" : "googlePlay-black.png"
                }
              />
            </div>
          </div>
          <div className="col-md-6 use-crypto-banner-img">
            <img
              src={
                require(`../../../assets/img/paywithcrypto/paywithcrypto.png`)
                  .default
              }
              alt="paywithcrypto1"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default PaywithCryptoBanner;
