import React from "react";

interface BlogNotFoundProps {
  header: string;
  instruction: string;
}

const BlogNotFound: React.FunctionComponent<BlogNotFoundProps> = (
  props: BlogNotFoundProps
) => {
  const { header, instruction } = props;
  return (
    <div className="row pb-4 blog_tags text-center">
      <div className="col-sm-12 blogs_categories_header">{header}</div>
      <div className="col-sm-12">{instruction}</div>
    </div>
  );
};

export default BlogNotFound;
