import React from "react";
import { HeaderMenu, PlainHeaderBanner } from "../../components/Header";
import { ContactSection, ContactForm } from "../../components/Contact";
import LanguageTranslater from "../../components/Common/LanguageTranslater";

import "./contact.css";

const Contact: React.FC = () => {
  return (
    <>
      <HeaderMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        cClass="contact_header"
        hbtnClass=""
      />
      <PlainHeaderBanner
        title={<LanguageTranslater langKey="contactUs.headerBanner.title" />}
        text={<LanguageTranslater langKey="contactUs.headerBanner.text" />}
        mClass="contact_page"
      />
      <div className="contact_bg">
        <ContactSection />
        <ContactForm />
      </div>
    </>
  );
};

export default Contact;
