import React from "react";
import { SolutionSectitle, CardFlip } from "../../Common";
import RecurringBillingItem from "./RecurringBillingItem";
import { useTranslation } from "react-i18next";
import { getImageUrl } from "../../../services/utility";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";

export const RecurringBillingModel: React.FC = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const imgPath = getImageUrl(language);
  return (
    <section className="hosting_service_area sec_pad recurring-billing-model-section">
      <div className="container">
        <SolutionSectitle
          sClass="adult-ent-header text-center"
          title={<LanguageTranslater langKey="adult.recurringBilling.title" />}
          subTitle={
            <LanguageTranslater langKey="adult.recurringBilling.subTitle" />
          }
        />
        <div className="recurring-billing-model-images">
          <div className="row pt-100 recurring_content">
            <div className="col-sm-4 recurring_item1">
              <div className="btn_set_up_ac_mobile">
                <a
                  href="https://console.pumapay.io/register"
                  target="_blank"
                  className="btn pp-btn-outline-primary mt-4"
                  rel="noreferrer"
                >
                  {
                    <LanguageTranslater langKey="adult.recurringBilling.item1.front.btn_text" />
                  }{" "}
                </a>
              </div>
              <CardFlip
                customClass="adult_entertainment_flip_card"
                isV3Card={true}
                isComingSoonCard={true}
                FrontCard={
                  <>
                    <img
                      className="pumapay-business-console-logo"
                      src={
                        require("../../../assets/img/solutions-for/pumapay-business-console-logo.png")
                          .default
                      }
                    />
                    <img
                      className="pumapay-business-item-image"
                      src={
                        require(`../../../assets/${imgPath}/solutions-for/pumapay-business-console.png`)
                          .default
                      }
                    />
                    <RecurringBillingItem
                      itemTitle={
                        <LanguageTranslater langKey="adult.recurringBilling.item1.front.title" />
                      }
                      itemDescription={
                        <LanguageTranslater langKey="adult.recurringBilling.item1.front.text" />
                      }
                    >
                      <div className="btn_set_up_ac_web">
                        <a
                          rel="noreferrer"
                          href="https://console.pumapay.io/register"
                          target="_blank"
                          className="btn pp-btn-outline-primary mt-4"
                        >
                          {
                            <LanguageTranslater langKey="adult.recurringBilling.item1.front.btn_text" />
                          }
                        </a>
                      </div>
                    </RecurringBillingItem>
                  </>
                }
                BackCard={
                  <>
                    <img
                      className="pumapay-business-item-image"
                      src={
                        require(`../../../assets/${imgPath}/solutions-for/pumaypay_connect.png`)
                          .default
                      }
                    />
                    <RecurringBillingItem
                      itemTitle={
                        <LanguageTranslater langKey="adult.recurringBilling.item1.back.title" />
                      }
                      itemDescription={
                        <LanguageTranslater langKey="adult.recurringBilling.item1.back.text" />
                      }
                    />
                  </>
                }
              />
            </div>
            <div className="col-sm-4 recurring_item2">
              <img
                src={
                  require(`../../../assets/${imgPath}/solutions-for/billing-model.png`)
                    .default
                }
              />
              <RecurringBillingItem
                itemTitle={
                  <LanguageTranslater langKey="adult.recurringBilling.item2.title" />
                }
                itemDescription={
                  <LanguageTranslater langKey="adult.recurringBilling.item2.text" />
                }
              />
            </div>
            <div className="col-sm-4 recurring_item3">
              <img
                src={
                  require(`../../../assets/${imgPath}/solutions-for/videozing.png`)
                    .default
                }
              />
              <RecurringBillingItem
                itemTitle={
                  <LanguageTranslater langKey="adult.recurringBilling.item3.title" />
                }
                itemDescription={
                  <LanguageTranslater langKey="adult.recurringBilling.item3.text" />
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="recurring-bg1">
        <img
          src={
            require("../../../assets/img/solutions-for/recurring-billing-bg01.png")
              .default
          }
        />
      </div>
    </section>
  );
};
export default RecurringBillingModel;
