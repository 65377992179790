import React from "react";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";
import { CardFlip, CardDetail } from "../../Common/";

interface FlexibleFeatureProps {
  customClass?: string;
}

export const FlexibleFeatureSection: React.FunctionComponent<FlexibleFeatureProps> = (
  props: FlexibleFeatureProps
) => {
  const { customClass } = props;
  return (
    <section className={`hosting_features_area ${customClass}`}>
      <div className="container">
        <div className={`row item_detail`}>
          <div className="col-lg-6">
            <div className="h_features_img">
              <img
                src={
                  require("../../../assets/img/solutions/flexible.png").default
                }
                alt="flexible"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div>
              <div className="hosting_title">
                <h1 className="custom_section_h">
                  {<LanguageTranslater langKey="bussiness.flexible.title" />}
                </h1>
              </div>
              <div className="media h_features_item">
                <img
                  src={
                    require("../../../assets/img/solutions/flexibleicon.png")
                      .default
                  }
                  alt="flexibleicon"
                />
                <div className="media-body">
                  <h4 className="h_head">
                    {
                      <LanguageTranslater langKey="bussiness.flexible.item1.title" />
                    }
                  </h4>
                  <p className="f_p">
                    {
                      <LanguageTranslater langKey="bussiness.flexible.item1.text" />
                    }
                  </p>
                </div>
              </div>

              <div className="media h_features_item">
                <img
                  src={
                    require("../../../assets/img/solutions/full_api.png")
                      .default
                  }
                  alt="chose_currency"
                />
                <div className="media-body">
                  <h4 className="h_head">
                    {
                      <LanguageTranslater langKey="bussiness.flexible.item2.title" />
                    }
                  </h4>
                  <p className="f_p">
                    {
                      <LanguageTranslater langKey="bussiness.flexible.item2.text" />
                    }
                  </p>
                </div>
              </div>
              <CardFlip
                FrontCard={
                  <CardDetail
                    iconPath="solutions/chose_currency.png"
                    title={
                      <LanguageTranslater langKey="bussiness.flexible.item3.front.title" />
                    }
                    des={
                      <LanguageTranslater langKey="bussiness.flexible.item3.front.text" />
                    }
                  />
                }
                BackCard={
                  <>
                    <CardDetail
                      iconPath="solutions/chose_currency.png"
                      title={
                        <LanguageTranslater langKey="bussiness.flexible.item3.back.title1" />
                      }
                      des={
                        <LanguageTranslater langKey="bussiness.flexible.item3.back.text1" />
                      }
                    />
                    <CardDetail
                      iconPath="solutions/play_with_any_crypto.png"
                      title={
                        <LanguageTranslater langKey="bussiness.flexible.item3.back.title2" />
                      }
                      des={
                        <LanguageTranslater langKey="bussiness.flexible.item3.back.text2" />
                      }
                    />
                  </>
                }
                isV3Card={true}
                isComingSoonCard={true}
              />
              <div className="media h_features_item btn_get_statrted">
                <a
                  href="https://api-docs.pumapay.io/#introduction"
                  target="_blank"
                  className="btn_hover agency_banner_btn pay_btn pay_btn_two section1_btn"
                  rel="noreferrer"
                >
                  {<LanguageTranslater langKey="bussiness.flexible.btn_text" />}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FlexibleFeatureSection;
