import React from "react";
import {
  DownloadButtonSection,
  LanguageTranslater,
  Image,
} from "../../../Common";
import "./wallet-carousel-item.css";

interface WalletCarouselItemProps {
  fimage: string;
  title: string | JSX.Element;
  description: string | JSX.Element;
}

const WalletCarouselItem: React.FunctionComponent<WalletCarouselItemProps> = (
  props: WalletCarouselItemProps
) => {
  const { fimage, title, description } = props;

  return (
    <div className={"agency_featured_item row agency_featured_item_two"}>
      <div className="row align-items-center h-100">
        <div className="col-lg-6 col-md-6">
          <div className="agency_featured_img text-right">
            <h3 className="step-title-mobile f_p">{title}</h3>
            <Image src={`pumapay-app/${fimage}`} alt={fimage} />
          </div>
        </div>
        <div className="col-lg-6 col-md-6 wallet_mobile">
          <div
            className={"pumapay_wallet_content wow fadeInLeft"}
            data-wow-delay="0.6s"
          >
            <h3 className="step-title f_p">{title}</h3>
            <p className="step-description f_p">{description}</p>
            <p className="wow fadeInUp f_p l_height21 p-0 download-text">
              <LanguageTranslater langKey="pumaPayApp.walletCarousel.downloadCaption" />
            </p>
            <DownloadButtonSection
              iUrl="#"
              gUrl="#"
              iPhoneImg="iPhone-black.png"
              gPlayImg="googlePlay-black.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default WalletCarouselItem;
