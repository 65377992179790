import React from "react";
import "./speedagility.css";

interface SpeedAgilityItemsProps {
  icon: string;
  text: string | JSX.Element;
  description: string | JSX.Element;
  iClass: string;
}

const SpeedAgilityItems: React.FunctionComponent<SpeedAgilityItemsProps> = (
  props: SpeedAgilityItemsProps
) => {
  const { iClass, icon, text, description } = props;
  return (
    <div
      className={` ${iClass} p_service_item agency_service_item pr_70 wow fadeInUp`}
    >
      <div className="icon ml_m7">
        <img
          src={require(`../../../../assets/img/otherbilling/${icon}`).default}
          alt={icon}
        />
      </div>
      <h1 className="f_500 f_p t_color3 section_header">{text}</h1>
      <p className="f_p l_height24 speed-agility-description">{description}</p>
    </div>
  );
};
export default SpeedAgilityItems;
