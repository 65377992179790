import React from "react";
import { useTranslation } from "react-i18next";
const Reveal = require("react-reveal/Reveal");
import { Image } from "../../../Common";

const PumaPayBridge: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="container pumapay_bridge_container">
      <div className="row pumapay_bridge_detail">
        <Reveal effect="fadeInUp">
          <div className="col-lg-6 col-sm-6">
            <Image
              className="img-fluid"
              src={"pumapay-bridge/bridge-screen.png"}
              alt="bridge-screen"
            />
          </div>
        </Reveal>
        <Reveal effect="fadeInUp">
          <div className="col-lg-6 col-sm-6 my-auto">
            <div className="pumapay_bridge_content">
              <h5>{t("pumapayBridge.pumapayBridgeSection.title")}</h5>
              <p>{t("pumapayBridge.pumapayBridgeSection.description")}</p>
              <div className="btn_pumapay_bridge">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://bridge.pumapay.io/"
                  className="btn pp-btn pp-btn-outline-primary"
                >
                  {t("common.goToBridge")}
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.pumapay.io/home/pumapay-bridge/"
                  className="f_p f_500 l_height30 d-flex learn-more-btn "
                >
                  {t("common.learnMore")}
                  <i className="ti-arrow-right ml-2"></i>
                </a>
              </div>
            </div>
          </div>
        </Reveal>
      </div>
    </div>
  );
};
export default PumaPayBridge;
