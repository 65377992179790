import React from "react";
import Image from "../../../Common/Image";
import "./gridItems.css";

interface GridItemsProps {
  title: string | JSX.Element;
  description: string | JSX.Element;
  image: string;
  customClass?: string;
  subClass?: string;
}

const GridItem: React.FunctionComponent<GridItemsProps> = (
  props: GridItemsProps
) => {
  const { title, description, image, customClass, subClass } = props;
  return (
    <div className={customClass}>
      <div className="blog_list_item blog_list_item_two ">
        <Image
          className="grid_img img-fluid"
          src={`subscription/${image}`}
          alt={image}
        />

        <div className={`blog_content detail custom_content ${subClass}`}>
          <h1 className="griditem_h f_p grid_title">{title}</h1>
          <p className="griditem_p f_p grid_desc">{description}</p>
        </div>
      </div>
    </div>
  );
};
export default GridItem;
