import React from "react";
import { Link } from "react-router-dom";
import "./error.css";

interface ErrorProps {
  imageName: string;
  des?: string;
}

export const Error: React.FunctionComponent<ErrorProps> = (
  props: ErrorProps
) => {
  const { imageName, des } = props;
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 error_img">
            <img
              src={require(`../../../assets/img/error/${imageName}`).default}
              className="img-fluid"
              alt={imageName}
            />
          </div>
          <div className="col-lg-12">
            <p className="error_des f_p">{des}</p>
          </div>
          <div className="col-lg-12 back_to_home">
            <Link
              className="btn_hover pay_btn pay_btn_two section1_btn"
              to="/"
              title="Back to home"
            >
              Back to home
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Error;
