import React from "react";
import { DownloadButtonSection } from "../../../components/Common";
import "./footerbanner.css";

interface FootereBannerProps {
  imgClass: string;
  isTelegram?: boolean;
  title: string | JSX.Element;
  description: string | JSX.Element;
  btnText: string | JSX.Element;
  fbClass?: string;
  btn_url: string;
  fbTitleClass?: string;
  fbDesClass?: string;
  isDownloadButton?: boolean;
}

export const FooterBanner: React.FunctionComponent<FootereBannerProps> = (
  props: FootereBannerProps
) => {
  const {
    imgClass,
    title,
    description,
    btnText,
    fbClass,
    fbTitleClass,
    fbDesClass,
    btn_url,
    isTelegram,
    isDownloadButton,
  } = props;
  return (
    <div className={`container ${fbClass}`}>
      <div className="create-billing-wrapper">
        <div className={`billing_content mb_50 ${imgClass}`}>
          {isTelegram && (
            <img
              className="tele_icon"
              src={
                require("../../../assets/img/footerbanner/icon_telegram.png")
                  .default
              }
              alt=""
            />
          )}
          <h2 className={`f_600 f_size_30 l_height40 title ${fbTitleClass}`}>
            {title}
          </h2>
          <p className={`${fbDesClass}`}>{description}</p>
          {isDownloadButton ? (
            <DownloadButtonSection
              iUrl="#"
              gUrl="#"
              iPhoneImg="iPhone.png"
              gPlayImg="googlePlay.png"
            />
          ) : (
            <a
              className="btn_get btn_hover banner_button"
              href={btn_url}
              target="_blank"
              rel="noreferrer"
            >
              {btnText}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default FooterBanner;
