import React from "react";
import "./download-button.css";

interface downloadButtonItemProps {
  iUrl: string;
  gUrl: string;
  iPhoneImg: string;
  gPlayImg: string;
}

export const DownloadButtonSection: React.FunctionComponent<downloadButtonItemProps> = (
  props: downloadButtonItemProps
) => {
  const { iPhoneImg, gPlayImg } = props;

  return (
    <div
      className="action_btn d-flex align-items-center wow fadeInLeft mb_40"
      data-wow-delay="0.6s"
    >
      <a
        href={process.env.REACT_APP_APP_STORE_URL_I || "#"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="iphone-btn"
          src={require(`../../../assets/img/common/${iPhoneImg}`).default}
          alt={iPhoneImg}
        />
      </a>
      <a
        href={process.env.REACT_APP_APP_STORE_URL_G || "#"}
        className="ml_20"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="gplay-btn"
          src={require(`../../../assets/img/common/${gPlayImg}`).default}
          alt={gPlayImg}
        />
      </a>
    </div>
  );
};
export default DownloadButtonSection;
