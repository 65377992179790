import React from "react";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";
import { useTranslation } from "react-i18next";
import { getImageUrl } from "../../../services/utility";
import "./carousel.css";

interface SolutionCarouselProps {
  customClass?: string;
}
export const ServiceCarouselSection: React.FunctionComponent<SolutionCarouselProps> = (
  props: SolutionCarouselProps
) => {
  const { customClass } = props;
  const { i18n } = useTranslation();
  const { language } = i18n;
  const imgPath = getImageUrl(language);
  return (
    <section className={`h_pricing_area sec_pad ${customClass}`}>
      <div className="container">
        <div className="text-center">
          <h1 className="slider_header">
            {" "}
            {
              <LanguageTranslater langKey="bussiness.serviceCarousel.title" />
            }{" "}
          </h1>
        </div>
        <div className="row">
          <div
            id="carouselExampleIndicators"
            className="carousel slide carousel_bg"
            data-ride="carousel"
          >
            <ol className="carousel-indicators custom_indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                className="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="2"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="3"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="4"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="5"
              ></li>
            </ol>
            <div className="carousel-inner custom_carousel">
              <div>
                <div className="carousel-item active">
                  <img
                    className="d-block w-100 img-fluid"
                    src={
                      require(`../../../assets/${imgPath}/solutions/1.png`)
                        .default
                    }
                    alt="First slide"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100 img-fluid"
                    src={
                      require(`../../../assets/${imgPath}/solutions/2.png`)
                        .default
                    }
                    alt="Second slide"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100 img-fluid"
                    src={
                      require(`../../../assets/${imgPath}/solutions/3.png`)
                        .default
                    }
                    alt="Third slide"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100 img-fluid"
                    src={
                      require(`../../../assets/${imgPath}/solutions/4.png`)
                        .default
                    }
                    alt="Third slide"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100 img-fluid"
                    src={
                      require(`../../../assets/${imgPath}/solutions/5.png`)
                        .default
                    }
                    alt="Third slide"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100 img-fluid"
                    src={
                      require(`../../../assets/${imgPath}/solutions/6.png`)
                        .default
                    }
                    alt="Third slide"
                  />
                </div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <img
                src={
                  require("../../../assets/img/common/arrow_left.png").default
                }
                alt="arrow_left"
              />
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <img
                src={
                  require("../../../assets/img/common/arrow_right.png").default
                }
                alt="arrow_right"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ServiceCarouselSection;
