import React from "react";
import ServiceItems from "../../Common/ServiceItems";
import { CardFlip, ComingSoonCard } from "../../../components/Common";
import useMediaQuery from "../../../services/UseMediaQuery";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";
import "./benefitSection.css";
const Reveal = require("react-reveal/Reveal");

export const BenefitSection: React.FC = () => {
  const [width] = useMediaQuery();
  return (
    <section className="forex-benefit-wrapper bg_color bg_img_benefit">
      <div className="container custom_container">
        <h2
          className="f_500 text-center mb_90 wow fadeInUp f_p forex-benefit-title"
          data-wow-delay="0.2s"
        >
          {<LanguageTranslater langKey="forex.benefitSection.title" />}
        </h2>
        <div className="row justify-content-md-center">
          <Reveal effect="fadeInUp">
            <div className="col-lg-4 col-sm-6 forex_benefit_item">
              <ServiceItems
                icon="chargebacks.png"
                title={
                  <LanguageTranslater langKey="forex.benefitSection.No chargebacks.title" />
                }
                description={
                  <LanguageTranslater langKey="forex.benefitSection.No chargebacks.description" />
                }
                customClass="custom_wrap"
              />
            </div>
          </Reveal>
          <Reveal effect="fadeInUp">
            <div className="col-lg-4 col-sm-6 forex_benefit_item">
              <ServiceItems
                icon="zeroTransaction.png"
                title={
                  <LanguageTranslater langKey="forex.benefitSection.Zero transaction fees.title" />
                }
                description={
                  <LanguageTranslater langKey="forex.benefitSection.Zero transaction fees.description" />
                }
                customClass="custom_wrap reverse_wrap"
              />
            </div>
          </Reveal>
        </div>
        <div className="row justify-content-md-center">
          <Reveal effect="fadeInUp">
            <div className="col-lg-4 col-sm-6 my-auto forex_benefit_item">
              <ServiceItems
                icon="middleman.png"
                title={
                  <LanguageTranslater langKey="forex.benefitSection.No middleman.title" />
                }
                description={
                  <LanguageTranslater langKey="forex.benefitSection.No middleman.description" />
                }
                customClass="custom_wrap"
              />
            </div>
          </Reveal>
          <Reveal effect="fadeInUp">
            <div className="col-lg-4 col-sm-6 forex_benefit_item">
              <CardFlip
                customClass="forex_benefit_volitality"
                isInvert={width < 769 ? true : false}
                FrontCard={
                  <ServiceItems
                    icon="volitality.png"
                    title={
                      <LanguageTranslater langKey="forex.benefitSection.No volatility risks.title" />
                    }
                    description={
                      <LanguageTranslater langKey="forex.benefitSection.No volatility risks.description" />
                    }
                    customClass="custom_wrap reverse_wrap"
                  />
                }
                BackCard={
                  <ServiceItems
                    icon="volitality.png"
                    title={
                      <LanguageTranslater langKey="forex.benefitSection.No volatility risks.title" />
                    }
                    description={
                      <LanguageTranslater langKey="forex.benefitSection.No volatility risks.comingSoon.description" />
                    }
                    customClass="custom_wrap reverse_wrap"
                  />
                }
                isV3Card={true}
                isComingSoonCard={true}
              />
            </div>
          </Reveal>
        </div>
        <div className="row justify-content-md-center mb-30">
          <Reveal effect="fadeInUp">
            <div className="col-lg-4 col-sm-6 forex_benefit_item">
              <ServiceItems
                icon="complete_api.png"
                title={
                  <LanguageTranslater langKey="forex.benefitSection.Complete API.title" />
                }
                description={
                  <LanguageTranslater langKey="forex.benefitSection.Complete API.description" />
                }
                customClass="custom_wrap"
              />
            </div>
          </Reveal>
          <Reveal effect="fadeInUp">
            <div className="col-lg-4 col-sm-6 forex_benefit_item">
              <ServiceItems
                icon="security.png"
                title={
                  <LanguageTranslater langKey="forex.benefitSection.Security & Privacy.title" />
                }
                description={
                  <LanguageTranslater langKey="forex.benefitSection.Security & Privacy.description" />
                }
                customClass="custom_wrap reverse_wrap"
              />
            </div>
          </Reveal>
        </div>
        <div className="row justify-content-md-center mb-30">
          <Reveal effect="fadeInUp">
            <div
              className={`col-lg-4 col-sm-6 pr_0 pl_0 forex_benefit_item ${
                width < 769 ? "pl_0" : ""
              }`}
            >
              <ComingSoonCard customClass="forex_benefit_crypto_item">
                <ServiceItems
                  icon="share.png"
                  title={
                    <LanguageTranslater langKey="forex.benefitSection.Accept any crypto.title" />
                  }
                  description={
                    <LanguageTranslater langKey="forex.benefitSection.Accept any crypto.description" />
                  }
                  customClass="custom_wrap"
                />
              </ComingSoonCard>
            </div>
          </Reveal>
          <Reveal effect="fadeInUp">
            <div
              className={`col-lg-4 col-sm-6 pr_0  forex_benefit_item ${
                width < 769 ? "pl_0" : ""
              }`}
            >
              <ComingSoonCard
                customClass="forex_benefit_many_item"
                isInvert={width < 769 ? true : false}
              >
                <ServiceItems
                  icon="transaction.png"
                  title={
                    <LanguageTranslater langKey="forex.benefitSection.Make money by processing transactions.title" />
                  }
                  description={
                    <LanguageTranslater langKey="forex.benefitSection.Make money by processing transactions.description" />
                  }
                  customClass="custom_wrap reverse_wrap"
                />
              </ComingSoonCard>
            </div>
          </Reveal>
        </div>
      </div>
    </section>
  );
};
export default BenefitSection;
