import React from "react";

interface ServiceItemsProps {
  serviceTitle: string | JSX.Element;
  servicedescription: string | JSX.Element;
  icon: string;
  desClass?: string;
}

const ServiceItems: React.FunctionComponent<ServiceItemsProps> = (
  props: ServiceItemsProps
) => {
  const { serviceTitle, servicedescription, icon, desClass } = props;
  return (
    <div className="col-sm-4">
      <div className="complex-billing-item text-center">
        <div className="p-3">
          <img
            src={
              require(`../../../../assets/img/solutions-for/${icon}`).default
            }
            alt={icon}
            className="img-fluid"
          />
        </div>
        <h4 className="h_head f_p">{serviceTitle}</h4>
        <p className={`f_p ${desClass}`}>{servicedescription}</p>
      </div>
    </div>
  );
};
export default ServiceItems;
