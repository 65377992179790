import React from "react";
import { HeaderMenu, PlainHeaderBanner } from "../../components/Header";
import LanguageTranslater from "../../components/Common/LanguageTranslater";

import Blogs from "../../components/ContentfulBlogs";
import "./../../assets/css/blogs.css";

const ContentfulBlogs: React.FC = () => {
  return (
    <>
      <HeaderMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        cClass=""
        hbtnClass=""
      />
      <PlainHeaderBanner
        title={<LanguageTranslater langKey="blog.title" />}
        text={<LanguageTranslater langKey="blog.subTitle" />}
        headerClass="blog_header_title"
        subHeaderClass="blog_header_subtitle"
      />
      <Blogs />
    </>
  );
};

export default ContentfulBlogs;
