import React from "react";
import { BrandLists, LanguageTranslater } from "../../Common";
import "./partnerspay.css";

interface PartnersPaySectionProps {
  aClass: string;
}
export const PartnersPaySection: React.FunctionComponent<PartnersPaySectionProps> = (
  props: PartnersPaySectionProps
) => {
  const { aClass } = props;
  return (
    <section className={`${aClass}`}>
      <h1 className="pps_title">
        <LanguageTranslater langKey="payWithCrypto.partnerSection.title" />
      </h1>
      <div className="container mb_90">
        <BrandLists adult={false} />
        <div className="h_action_content"></div>
      </div>
    </section>
  );
};
export default PartnersPaySection;
