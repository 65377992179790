import React from "react";
import { HeaderMenu, TriangleHeaderBanner } from "../../components/Header";
import {
  GamingBenefits,
  GamingCustomAPISection,
  GamingUseCase,
} from "../../components/Gaming";
import LanguageTranslater from "../../components/Common/LanguageTranslater";
import { PaymentSection } from "../../components/Common";
import { FooterBanner } from "../../components/Footer";
import "./gaming.css";

const Gaming: React.FC = () => {
  return (
    <>
      <HeaderMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        cClass=""
        hbtnClass=""
      />
      <TriangleHeaderBanner
        title={<LanguageTranslater langKey="gaming.headerBanner.title" />}
        header={<LanguageTranslater langKey="gaming.headerBanner.header" />}
        description={
          <LanguageTranslater langKey="gaming.headerBanner.description" />
        }
        sImage="banner-image.png"
        aClass="gaming_banner"
      />
      <GamingBenefits />
      <PaymentSection
        mTitle={<LanguageTranslater langKey="gaming.paymetSection.title" />}
        mDescription={
          <LanguageTranslater langKey="gaming.paymetSection.description" />
        }
        pImage="automate.png"
        sTitle={<LanguageTranslater langKey="gaming.paymetSection.subTitle" />}
        sDecsription={
          <LanguageTranslater langKey="gaming.paymetSection.subDescription" />
        }
        btn_text={<LanguageTranslater langKey="common.getStarted" />}
        btn_url="https://console.pumapay.io/register"
        l_more_btn={<LanguageTranslater langKey="common.learnMore" />}
        l_more_url="/top-ups"
        fColorDes="gaming_des_color"
        mDescriptionClass="gaming_payment"
      />
      <GamingCustomAPISection
        title={<LanguageTranslater langKey="gaming.gamingAPISection.title" />}
        description={
          <LanguageTranslater langKey="gaming.gamingAPISection.description" />
        }
      />
      <GamingUseCase />

      <FooterBanner
        imgClass="billing_content_bg_dark"
        title={<LanguageTranslater langKey="footerBanner.gaming.header" />}
        description={
          <LanguageTranslater langKey="footerBanner.gaming.description" />
        }
        btnText={<LanguageTranslater langKey="common.getStarted" />}
        btn_url="https://console.pumapay.io/register"
        fbTitleClass="gaming"
        fbDesClass="gaming_des"
      />
    </>
  );
};

export default Gaming;
