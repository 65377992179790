import React from "react";
import "./feedBack-item.css";

interface PumapayFeedbackItemProps {
  name: string;
  feedback: string | JSX.Element;
  img: string;
}
const FeedBackItem: React.FunctionComponent<PumapayFeedbackItemProps> = (
  props: PumapayFeedbackItemProps
) => {
  const { name, feedback, img } = props;
  return (
    <div className="item">
      <div className="userfeed-back-item">
        <span className="userfeed-border"></span>
        <h4 className="f_600 f_p l_height27 t_color feed-back-name">{name}</h4>
        <img
          className="ratings"
          src={require(`../../../../assets/img/common/star.png`).default}
          alt="star"
        />
        <p className="f_p userfeed-p feed-back-description">{feedback}</p>
        <img
          className="googlePlay"
          src={require(`../../../../assets/img/common/${img}`).default}
          alt={img}
        />
      </div>
    </div>
  );
};
export default FeedBackItem;
