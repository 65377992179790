import React, { useState, useEffect } from "react";
import { BlogPaginationProps, BlogPaginationPageProps } from "./../PropTypes";

const BlogPagination: React.FunctionComponent<BlogPaginationProps> = (
  props: BlogPaginationProps
) => {
  const { totalItems, itemPerPage } = props;
  const [pages, setPages] = useState<BlogPaginationPageProps[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const totalPage = Math.ceil(totalItems / itemPerPage);
  const maxPages = 4;

  const goToPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const nextPage = (start: number, showPages: number) => {
      const blogPages: BlogPaginationPageProps[] = [];
      for (let i = start; i <= showPages; i++) {
        if (currentPage == i) {
          blogPages.push({ page_number: i, selected: "selected" });
        } else {
          blogPages.push({ page_number: i });
        }
      }
      setPages(blogPages);
    };
    if (totalPage > maxPages) {
      if (currentPage + maxPages < totalPage) {
        nextPage(currentPage, currentPage + maxPages);
      } else {
        nextPage(totalPage - maxPages, totalPage);
      }
    } else {
      nextPage(1, totalPage);
    }
  }, [totalItems, currentPage, totalPage]);

  return (
    <div className="row blog_pagination">
      <hr className="" />
      <div className="blog_pagination_pages">
        <div
          title="First"
          className="blog_pagination_page mr-2 pointer"
          onClick={() => {
            props.reloadBlogs(1);
            goToPage(1);
          }}
        >
          {"<<"}
        </div>
        {totalPage > 1 && currentPage > 1 && (
          <div
            title="Previous"
            className="blog_pagination_page mr-2 pointer previous_page"
            onClick={() => {
              props.reloadBlogs(currentPage - 1);
              goToPage(currentPage - 1);
            }}
          >
            <img
              className=""
              src={
                require("../../../assets/img/blogs/next_page_arrow.png").default
              }
            />
          </div>
        )}

        {pages.map((page: BlogPaginationPageProps) => (
          <div
            title={String(page.page_number)}
            key={page.page_number}
            className={`blog_pagination_page mr-2 ${page.selected}`}
            onClick={() => {
              props.reloadBlogs(page.page_number);
              goToPage(page.page_number);
            }}
          >
            {page.page_number}
          </div>
        ))}
        {totalPage - currentPage > 1 && (
          <div
            title="Next"
            className="blog_pagination_page mr-2 pointer next_page"
            onClick={() => {
              props.reloadBlogs(currentPage + 1);
              goToPage(currentPage + 1);
            }}
          >
            <img
              className=""
              src={
                require("../../../assets/img/blogs/next_page_arrow.png").default
              }
            />
          </div>
        )}
        <div
          title="Last"
          className="blog_pagination_page mr-2 pointer"
          onClick={() => {
            props.reloadBlogs(totalPage);
            goToPage(totalPage);
          }}
        >
          {">>"}
        </div>
      </div>
    </div>
  );
};

export default BlogPagination;
