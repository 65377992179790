import React from "react";
import { useTranslation } from "react-i18next";
import { DownloadButtonSection, LanguageTranslater } from "../../Common";
import "../../../app/App.css";
import "./triangleHeaderBanner.css";

interface TriangleHeaderBannerProps {
  title?: string | JSX.Element;
  header?: string | JSX.Element;
  description?: string | JSX.Element;
  sImage?: string;
  btn_text?: string;
  isDownloadButton?: boolean;
  aClass?: string;
  isButton?: boolean;
  btnUrl?: string;
  isHtmlContent?: boolean;
  children?: React.ReactNode;
  learnMoreUrl?: string;
}
export const TriangleHeaderBanner: React.FC<TriangleHeaderBannerProps> = (
  props: TriangleHeaderBannerProps
) => {
  const {
    title,
    header,
    description,
    sImage,
    isButton,
    btn_text,
    isDownloadButton,
    aClass,
    btnUrl,
    isHtmlContent,
    learnMoreUrl,
  } = props;
  const { t } = useTranslation();
  return (
    <section className={aClass}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="solutions_for_content">
              <div className="f_p solutions_for_title l_height30 f_size_20 f_600">
                {title}
              </div>
              {isHtmlContent ? (
                props.children
              ) : (
                <>
                  <h1
                    className="solutions_for_header f_p f_600 mb_20"
                    data-wow-delay="0.3s"
                  >
                    {header}
                  </h1>
                  <p
                    className="solutions_for_description wow fadeInUp f_p l_height27"
                    data-wow-delay="0.5s"
                  >
                    {description}
                  </p>
                </>
              )}

              {isDownloadButton && (
                <div>
                  <p className="solutions_for_description_btn wow fadeInUp f_p l_height21 f_size_14 mb_10 mt_30">
                    <LanguageTranslater langKey="pumaPayApp.headerBanner.downloadCaption" />
                  </p>
                  <DownloadButtonSection
                    iUrl="#"
                    gUrl="#"
                    iPhoneImg="iPhone.png"
                    gPlayImg="googlePlay.png"
                  />
                </div>
              )}
              {isButton && (
                <div className="btn_header_banner_2">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={btnUrl}
                    className="btn pp-btn pp-btn-outline-primary"
                  >
                    {btn_text}
                  </a>
                  {learnMoreUrl && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={learnMoreUrl}
                      className="f_p f_500 l_height30 d-flex learn-more-btn "
                    >
                      {t("common.learnMore")}
                      <i className="ti-arrow-right ml-2"></i>
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-7">
            <div className="solutiom_for_sImage ">
              <img
                className="solutions_for_header_image_gaming img-fluid"
                src={require(`../../../assets/img/common/${sImage}`).default}
                alt={sImage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default TriangleHeaderBanner;
