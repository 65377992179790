import React from "react";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";
import { BrandLists } from "../../Common";
import "./subBanner.css";
interface SubBannerProps {
  aClass: string;
  Atitle?: string;
  Atext: string | JSX.Element;
  children?: React.ReactNode;
}

export const SubBanner: React.FC<SubBannerProps> = (props: SubBannerProps) => {
  const { aClass, Atitle, Atext, children } = props;
  return (
    <section className={`${aClass}`}>
      <div className="container">
        <div className="h_action_content">
          <h1 className="custom_subbanner_h">{children ? children : Atitle}</h1>
          <p className="custom_subbanner_p m_0">{Atext}</p>
        </div>
        <BrandLists adult={true} />
        <div className="text-center btn_start">
          <a
            href="https://console.pumapay.io/register"
            target="_blank"
            className="btn_hover agency_banner_btn pay_btn pay_btn_two section1_btn"
            rel="noreferrer"
          >
            {<LanguageTranslater langKey="bussiness.partners.btn_text" />}
          </a>
        </div>
      </div>
    </section>
  );
};
export default SubBanner;
