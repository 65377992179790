import React from "react";
import "./CustomAPISection.css";

interface GamingCustomAPIProps {
  title: string | JSX.Element;
  description: string | JSX.Element;
}

export const GamingCustomAPISection: React.FunctionComponent<GamingCustomAPIProps> = (
  props: GamingCustomAPIProps
) => {
  const { title, description } = props;
  return (
    <section className="custom_api_section">
      <div className="container">
        <div className={`custom_api_detail row mb_50`}>
          <div className="col-md-2">
            <img
              src={
                require("../../../assets/img/gaming/custom_api_Icon.png")
                  .default
              }
              alt="Custom Api"
            />
          </div>
          <div className="col-md-8 p-0">
            <h2 className={`f_600 f_p f_size_30 l_height30 title`}>{title}</h2>
            <p className="f_p complex_api_description">{description}</p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default GamingCustomAPISection;
