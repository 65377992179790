import React from "react";

interface SmartChainItemProps {
  imgName: string;
  altText: string;
  title: string;
  desc: string;
  customClass?: string;
}

const SmartChainItem: React.FunctionComponent<SmartChainItemProps> = (
  props: SmartChainItemProps
) => {
  const { imgName, altText, title, desc, customClass } = props;
  return (
    <div className={`smart_chain_item ${customClass}`}>
      <div className="smart_chain_img">
        <img
          src={
            require(`../../../../assets/img/pumapay-bridge/${imgName}`).default
          }
          alt={altText}
        />
      </div>
      <div className="smart_chain_content my-auto">
        <h5>{title}</h5>
        <p>{desc}</p>
      </div>
    </div>
  );
};
export default SmartChainItem;
