import React from "react";
import SliderFeature from "./Slider";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";
import "./sliderFeature.css";
const Reveal = require("react-reveal/Reveal");

export const SliderFeatureSection: React.FC = () => {
  return (
    <section className="prototype_service_area_two slide_bg">
      <div className="container custom_container">
        <div className="sec_title text-center">
          <Reveal effect="fadeInUp" duration={1300}>
            <h2 className="slide_title f_p">
              <span>
                {
                  <LanguageTranslater langKey="subscription.Automate your payments & grow your business" />
                }
              </span>
            </h2>
          </Reveal>
        </div>
        <SliderFeature />
      </div>
    </section>
  );
};
export default SliderFeatureSection;
