import React from "react";
import { HeaderMenu } from "../../components/Header";
import { Error } from "../../components/Common";
import "../../assets/css/error-page.css";

const NotFound: React.FC = () => {
  return (
    <div className="info_page">
      <HeaderMenu
        slogo="sticky_logo no_filter"
        mClass="menu_four error"
        nClass="w_menu error_page"
        cClass=""
        hbtnClass=""
        isAltLogo={true}
      />
      <Error
        imageName="404-not-found.png"
        des="We can’t find the page you’re looking for."
      />
    </div>
  );
};

export default NotFound;
