import React from "react";
const Reveal = require("react-reveal/Reveal");
import { useTranslation } from "react-i18next";
import { Image } from "../../Common";

export const ProvideLiquidity: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className="payment_clients_area home_liquidity_provider">
      <img
        className="img-fluid liquidity_provider_layer w-100"
        src={
          require("../../../assets/img/home/liquidity_provider_layer.png")
            .default
        }
        alt="liquidity_provider_layer"
      />
      <img
        className="img-fluid liquidity_provider_layer_mobile w-100"
        src={
          require("../../../assets/img/home/liquidity_provider_layer_mobile.png")
            .default
        }
        alt="liquidity_provider_layer_mobile"
      />
      <div className="container">
        <div className="row featured_item">
          <div className="col-lg-6 d-flex align-items-center liquidity_provider_detail">
            <Reveal effect="fadeInLeft" duration={1200}>
              <div className="payment_features_content">
                <div className="icon">
                  <img
                    className="img_shape"
                    src={
                      require("../../../assets/img/home/liquidity_provider_icon.png")
                        .default
                    }
                    alt="liquidity_provider_icon"
                  />
                </div>
                <h1 className="f_p f_size_26 f_400 section1_h1">
                  {t("home.provideLiquidity.header")}
                </h1>
                <p className="f_p f_size_18 section1_p">
                  {t("home.provideLiquidity.text1")}
                  <br />
                  <br />
                  {t("home.provideLiquidity.text2")}
                  <br />
                  <br />
                  {t("home.provideLiquidity.text3")}
                  <a
                    href="https://go.pumapay.io/3hiM7fI"
                    className="text-purple-blue"
                  >
                    {t("home.provideLiquidity.text4")}
                  </a>
                  {t("home.provideLiquidity.text5")}
                </p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://exchange.pancakeswap.finance/#/add/BNB/0x43a167B15a6F24913A8B4D35488B36Ac15d39200"
                  className="btn_hover agency_banner_btn pay_btn pay_btn_two section1_btn"
                >
                  {t("home.provideLiquidity.depositLiquidity")}
                </a>
                <a
                  href="/liquidity-providers"
                  className="pl-4 deposit_liquidity_right_arrow"
                >
                  {t("common.learnMore")}
                  <img
                    className="pl-3"
                    src={
                      require("../../../assets/img/home/home_right_arrow.png")
                        .default
                    }
                    alt="home_right_arrow"
                  />
                </a>
              </div>
            </Reveal>
          </div>
          <div className="col-lg-6">
            <Reveal effect="fadeInRight" duration={800}>
              <div className="liquidity_provider_right_section">
                <img
                  className="img-fluid liquidity_provider_right_bg"
                  src={
                    require("../../../assets/img/home/liquidity_provider_right_bg.png")
                      .default
                  }
                  alt="liquidity_provider_right_bg"
                />
                <Image
                  className="img-fluid liquidity_provider_right_image"
                  src={"home/liquidity_provider_right_image.png"}
                  alt="liquidity_provider_right_bg"
                />
              </div>
            </Reveal>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProvideLiquidity;
