import React from "react";
import ReactCardFlip from "react-card-flip";
import ComingSoonCard from "./ComingSoonCard";
import { isMobile } from "react-device-detect";
import V3Card from "./V3Card";
import "./cardflip.css";

interface CardFlipProps {
  FrontCard: JSX.Element;
  BackCard: JSX.Element;
  isV3Card?: boolean;
  isComingSoonCard?: boolean;
  customClass?: string;
  isInvert?: boolean;
}

export const CardFlip: React.FunctionComponent<CardFlipProps> = (
  props: CardFlipProps
) => {
  const {
    FrontCard,
    BackCard,
    isV3Card,
    isComingSoonCard,
    customClass,
    isInvert,
  } = props;
  const [isFlipped, setIsFlipped] = React.useState(false);
  return (
    <div
      className={`card_flip ${customClass}`}
      onMouseLeave={() => (!isMobile ? setIsFlipped(false) : null)}
    >
      <ReactCardFlip
        isFlipped={isFlipped}
        flipDirection="horizontal"
        flipSpeedBackToFront={0.6}
        flipSpeedFrontToBack={0.6}
      >
        <div
          className="version-three-btn"
          onClick={() => setIsFlipped((prev) => !prev)}
          onMouseEnter={() =>
            !isMobile ? setIsFlipped((prev) => !prev) : null
          }
        >
          {isV3Card ? (
            <V3Card isInvert={isInvert}>{FrontCard}</V3Card>
          ) : (
            FrontCard
          )}
        </div>
        <div
          className="coming-soon-btn"
          onClick={() => setIsFlipped((prev) => !prev)}
          onMouseLeave={() =>
            !isMobile ? setIsFlipped((prev) => !prev) : null
          }
        >
          {isComingSoonCard ? (
            <ComingSoonCard isInvert={isInvert}>{BackCard}</ComingSoonCard>
          ) : (
            BackCard
          )}
        </div>
      </ReactCardFlip>
    </div>
  );
};

export default CardFlip;
