import React from "react";
import { useTranslation } from "react-i18next";
import { HeaderMenu, TriangleHeaderBanner } from "../../components/Header";
import {
  LiquidityPoolsSpecial,
  LiquidityPools,
} from "../../components/LiquidityProviders";
import { ClaimRewardBanner } from "../../components/Common";
import { FooterBanner } from "../../components/Footer";
import "./liquidityproviders.css";

const LiquidityProviders: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="liquidity_providers_page">
      <HeaderMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        cClass=""
        hbtnClass=""
      />
      <TriangleHeaderBanner
        header={t("liquidityProviders.headerBanner.header")}
        description={t("liquidityProviders.headerBanner.text")}
        sImage="liquidity_provider.png"
        aClass="gaming_banner liquidity_banner"
        isButton={true}
        btn_text={t("common.getStarted")}
        btnUrl="https://exchange.pancakeswap.finance/#/add/BNB/0x43a167B15a6F24913A8B4D35488B36Ac15d39200"
        learnMoreUrl="https://app.gitbook.com/@pumapay/s/home/pma-liquidity-pools/how-to-add-liquidity"
      />
      <LiquidityPoolsSpecial />
      <ClaimRewardBanner />
      <LiquidityPools />
      <FooterBanner
        imgClass="billing_content_bg_pumapay_app"
        title={t("footerBanner.liquidityProviders.header")}
        description={t("footerBanner.liquidityProviders.description")}
        btnText={t("common.joinNow")}
        fbTitleClass="mt-3"
        fbDesClass="pumapay_app_description"
        btn_url="https://go.pumapay.io/3yaszAc"
      />
    </div>
  );
};

export default LiquidityProviders;
