import React from "react";
import { useTranslation } from "react-i18next";
import { getImageUrl } from "../../../services/utility";

interface ImageProps {
  src: string;
  alt: string;
  className?: string;
}

export const Image: React.FC<ImageProps> = (props: ImageProps) => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const { className, alt, src } = props;
  const imgPath = getImageUrl(language);

  return (
    <img
      className={className}
      src={require(`../../../assets/${imgPath}/${src}`).default}
      alt={alt}
    />
  );
};

export default Image;
