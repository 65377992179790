import React from "react";
import { useTranslation } from "react-i18next";
const Reveal = require("react-reveal/Reveal");

export const DecentralizedSolution: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className="home_decentralized">
      <div className="container">
        <hr className="d-md-none" />
        <div className="row featured_item">
          <div className="col-lg-6">
            <Reveal effect="fadeInRight" duration={200}>
              <div className="payment_featured_img">
                <img
                  className=""
                  src={
                    require("../../../assets/img/home9/decentralized.png")
                      .default
                  }
                  alt="decentralized"
                />
              </div>
            </Reveal>
          </div>
          <div className="col-lg-6 d-flex align-items-center home_decentralized_detail">
            <Reveal effect="fadeInLeft" duration={1200}>
              <div className="payment_features_content pr_70">
                <div className="icon">
                  <img
                    className="img_shape"
                    src={
                      require("../../../assets/img/home9/icon_shape.png")
                        .default
                    }
                    alt="icon_shape"
                  />
                  <img
                    className="icon_img"
                    src={
                      require("../../../assets/img/home9/icon_decentralized.png")
                        .default
                    }
                    alt="icon_decentralized"
                  />
                </div>
                <h1 className="f_p f_size_26 f_400 section1_h1 decentralized_title">
                  {t("home.decentralizedSection.header")}
                </h1>
                <p className="f_p f_size_18 section1_p decentralized_description">
                  {t("home.decentralizedSection.text")}
                </p>
                <a
                  href="/solutions"
                  className="btn_hover agency_banner_btn pay_btn pay_btn_two section1_btn"
                >
                  {t("common.learnMore")}
                </a>
              </div>
            </Reveal>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DecentralizedSolution;
