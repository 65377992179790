import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import "../assets/themify-icon/themify-icons.css";
import "../assets/simple-line-icon/simple-line-icons.css";
import "../assets/font-awesome/css/all.css";
import "../assets/elagent/style.css";
import "../assets/animate.css";
import "../assets/main.css";
import "../assets/responsive.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "../components/Common/Layout";
import BaseRoute from "../routes";
import Routes from "../services/routes";
import { BrowserRouter as Router } from "react-router-dom";
import { hotjar } from "react-hotjar";

const App: React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.dir = i18n.dir();
    document.documentElement.lang = i18n.language;
  }, [i18n, i18n.language]);

  const HotJar_ID: number = parseInt(`${process.env.REACT_APP_HOTJAR_ID}`) || 0;
  hotjar.initialize(HotJar_ID, 6);

  return (
    <Router>
      <Layout>
        <BaseRoute routes={Routes} />
      </Layout>
    </Router>
  );
};

export default App;
