import React from "react";
import Slider from "react-slick/";
import FeatureRichItem from "../FeatureRichItem";
import { LanguageTranslater } from "../../../Common";

const FeatureRichSlider: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="case_studies_slider">
      <FeatureRichItem
        title={
          <LanguageTranslater langKey="pumaPayApp.contentSection.slide1.title" />
        }
        description={
          <LanguageTranslater langKey="pumaPayApp.contentSection.slide1.description" />
        }
        image="buy crypto.png"
      />
      <FeatureRichItem
        title={
          <LanguageTranslater langKey="pumaPayApp.contentSection.slide2.title" />
        }
        description={
          <LanguageTranslater langKey="pumaPayApp.contentSection.slide2.description" />
        }
        image="support.png"
      />
      <FeatureRichItem
        title={
          <LanguageTranslater langKey="pumaPayApp.contentSection.slide3.title" />
        }
        description={
          <LanguageTranslater langKey="pumaPayApp.contentSection.slide3.description" />
        }
        image="control.png"
      />
      <FeatureRichItem
        title={
          <LanguageTranslater langKey="pumaPayApp.contentSection.slide4.title" />
        }
        description={
          <LanguageTranslater langKey="pumaPayApp.contentSection.slide4.description" />
        }
        image="privecy.png"
      />
      <FeatureRichItem
        title={
          <LanguageTranslater langKey="pumaPayApp.contentSection.slide5.title" />
        }
        description={
          <LanguageTranslater langKey="pumaPayApp.contentSection.slide5.description" />
        }
        image="live.png"
      />
    </Slider>
  );
};

export default FeatureRichSlider;
