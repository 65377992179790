import React from "react";
import { useTranslation } from "react-i18next";
import { getImageUrl } from "../../../services/utility";

interface ComingSoonCardProps {
  customClass?: string;
  isInvert?: boolean;
  children?: React.ReactNode;
}

export const ComingSoonCard: React.FC<ComingSoonCardProps> = (
  props: ComingSoonCardProps
) => {
  const { children, customClass, isInvert } = props;
  const { i18n } = useTranslation();
  const { language } = i18n;
  const imgPath = getImageUrl(language);
  return (
    <div className={`card_back card_coming_soon ${customClass}`}>
      {isInvert === true ? (
        <img
          className="img_coming_soon_invert"
          src={
            require("../../../assets/img/card-flip/coming_soon_invert.png")
              .default
          }
          alt="coming-soon"
        />
      ) : (
        <img
          className="img_coming_soon"
          src={
            require(`../../../assets/${imgPath}/card-flip/coming_soon.png`)
              .default
          }
          alt="coming-soon"
        />
      )}
      <div className="card_back_content">{children}</div>
    </div>
  );
};

export default ComingSoonCard;
