import React from "react";
import { useTranslation } from "react-i18next";
import { Image } from "../../Common";
const Reveal = require("react-reveal/Reveal");

export const RewardLiquidityProvider: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className="reward_liquidity_provider">
      <div className="container">
        <div className="row featured_item">
          <h1 className="col-sm-12 section1_h1">
            {t("rewardScheme.rewardLiquidityProvider.header")}
          </h1>
        </div>
        <div className="row featured_item">
          <div className="col-lg-6 align-items-center reward_liquidity_provider_detail">
            <Reveal effect="fadeInLeft" duration={1200}>
              <p className="section1_p">
                {t(
                  "rewardScheme.rewardLiquidityProvider.description1.subDescription1"
                )}
                <br />
                <br />
                {t(
                  "rewardScheme.rewardLiquidityProvider.description1.subDescription2"
                )}
                <br />
                <br />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://exchange.pancakeswap.finance/#/add/BNB/0x43a167B15a6F24913A8B4D35488B36Ac15d39200"
                  className="btn_hover agency_banner_btn pay_btn pay_btn_two section1_btn"
                >
                  {t(
                    "rewardScheme.rewardLiquidityProvider.Earn tokens & yields"
                  )}
                </a>
                <b style={{ color: "#7023FF" }}>
                  {t(
                    "rewardScheme.rewardLiquidityProvider.description4.750m PMA tokens"
                  )}
                </b>{" "}
                <br />
                {t(
                  "rewardScheme.rewardLiquidityProvider.description4.subDescription"
                )}
                <br />
              </p>
            </Reveal>
          </div>
          <div className="col-lg-6">
            <Reveal effect="fadeInRight" duration={800}>
              <div className="reward_liquidity_provider_right_section">
                <Image
                  className="img-fluid"
                  src={"reward_scheme/pma_token_card.png"}
                  alt="liquidity_provider_right_bg"
                />
              </div>
            </Reveal>
          </div>
        </div>
        <div className="row featured_item">
          <div className="col-lg-12 reward_liquidity_provider_detail">
            <Reveal effect="fadeInLeft" duration={1200}>
              <p className="section1_p">
                <br />
                {t("rewardScheme.rewardLiquidityProvider.description2")}
                <br />
                <br />
                {t(
                  "rewardScheme.rewardLiquidityProvider.description3.subDesc1"
                )}
                <b>
                  {t(
                    "rewardScheme.rewardLiquidityProvider.description3.subDesc2"
                  )}
                </b>
                {t(
                  "rewardScheme.rewardLiquidityProvider.description3.subDesc3"
                )}
                <br />
              </p>
              <br />

              <p className="subHeaderRewards mt-2">
                {t("rewardScheme.rewardLiquidityProvider.subHeader")}
              </p>
              <p className="section1_p">
                {t(
                  "rewardScheme.rewardLiquidityProvider.description12.Our first pool is"
                )}
                <b>
                  {t(
                    "rewardScheme.rewardLiquidityProvider.description12.PMA<>BNB"
                  )}
                </b>{" "}
                {t("rewardScheme.rewardLiquidityProvider.description12.on")}
                <a
                  className="text-purple-blue"
                  href="https://exchange.pancakeswap.finance/#/add/BNB/0x43a167B15a6F24913A8B4D35488B36Ac15d39200 "
                >
                  {t(
                    "rewardScheme.rewardLiquidityProvider.description12.PancakeSwap."
                  )}
                </a>
                <br />
                {t(
                  "rewardScheme.rewardLiquidityProvider.description5.subDesc1"
                )}
                <b>
                  {t(
                    "rewardScheme.rewardLiquidityProvider.description5.subDesc2"
                  )}
                </b>
                {t(
                  "rewardScheme.rewardLiquidityProvider.description5.subDesc3"
                )}
                <b>
                  {t(
                    "rewardScheme.rewardLiquidityProvider.description5.subDesc4"
                  )}
                </b>
                {t(
                  "rewardScheme.rewardLiquidityProvider.description5.subDesc5"
                )}
                <br />
                <br />
              </p>
            </Reveal>
          </div>
          <div className="col-md-1">
            <div>
              <img
                className="img-fluid"
                src={
                  require("../../../assets/img/reward_scheme/timer.svg").default
                }
                alt="timer"
              />
            </div>
          </div>
          <div className="col-md-11 reward_liquidity_provider_detail">
            <p className="section1_p mt-3">
              {t("rewardScheme.rewardLiquidityProvider.description13")}
            </p>
          </div>
          <div className="col-md-11 reward_liquidity_provider_detail">
            <p className="section1_p">
              <br />
              {t("rewardScheme.rewardLiquidityProvider.description14")}
            </p>
          </div>
        </div>
        <div className="row featured_item">
          <div className="col-lg-12 reward_liquidity_provider_detail">
            <Reveal effect="fadeInLeft" duration={1200}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://pumapay.io"
                className="btn_hover agency_banner_btn pay_btn pay_btn_two section1_btn btn_join"
              >
                {t("common.join")}
              </a>
            </Reveal>
          </div>
        </div>
        <div className="row featured_item">
          <div className="col-lg-12 reward_liquidity_provider_detail d-flex">
            <p className="deposit_liquidity">
              {t(
                "rewardScheme.rewardLiquidityProvider.description10.subDescription1"
              )}
              <a className="text-purple-blue" href="/pumaPay-bridge">
                {t(
                  "rewardScheme.rewardLiquidityProvider.description10.subDescription2"
                )}
              </a>
              {t(
                "rewardScheme.rewardLiquidityProvider.description10.subDescription3"
              )}
            </p>
          </div>
          <div className="col-lg-12 reward_liquidity_provider_detail d-flex">
            <p className="deposit_liquidity">
              {t(
                "rewardScheme.rewardLiquidityProvider.description9.subDescription1"
              )}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/docs/terms-liquidity-provider.pdf"
              >
                {t(
                  "rewardScheme.rewardLiquidityProvider.description9.subDescription2"
                )}
              </a>
              {t(
                "rewardScheme.rewardLiquidityProvider.description9.subDescription3"
              )}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://exchange.pancakeswap.finance/#/add/BNB/0x43a167B15a6F24913A8B4D35488B36Ac15d39200"
              >
                {t(
                  "rewardScheme.rewardLiquidityProvider.description9.subDescription4"
                )}
              </a>
            </p>
          </div>
          <div className="col-lg-12 reward_liquidity_provider_detail d-flex">
            <Reveal effect="fadeInLeft" duration={1200}>
              <img
                className="img-fluid"
                src={
                  require("../../../assets/img/reward_scheme/exclamation_icon.png")
                    .default
                }
                alt="liquidity_provider_right_bg"
              />
              <p className="deposit_liquidity disclaimer">
                {t(
                  "rewardScheme.rewardLiquidityProvider.description11.subDesc1"
                )}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/docs/terms-liquidity-provider.pdf"
                >
                  {t(
                    "rewardScheme.rewardLiquidityProvider.description11.subDesc2"
                  )}
                </a>
                {t(
                  "rewardScheme.rewardLiquidityProvider.description11.subDesc3"
                )}
              </p>
            </Reveal>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RewardLiquidityProvider;
