import React from "react";
import Slider from "react-slick";
import FeedBackItem from "./PumapayFeedBackItem";
import { LanguageTranslater } from "../../Common";

export const PumaPayUserFeedBack: React.FC = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="event_team_area sec_pad">
      <h2
        className="f_500 f_p f_size_36 t_color l_height54 text-center mb_90 wow fadeInUp feedback-title"
        data-wow-delay="0.2s"
      >
        <LanguageTranslater langKey="pumaPayApp.userFeedback.title" />
      </h2>
      <Slider {...settings} className="case_studies_slider">
        <FeedBackItem
          name="Jack Higgins"
          feedback={
            <LanguageTranslater langKey="pumaPayApp.userFeedback.slide1Desc" />
          }
          img="googlePlay-black.png"
        />
        <FeedBackItem
          name="Max Rest"
          feedback={
            <LanguageTranslater langKey="pumaPayApp.userFeedback.slide2Desc" />
          }
          img="googlePlay-black.png"
        />
        <FeedBackItem
          name="Kumar Patel"
          feedback={
            <LanguageTranslater langKey="pumaPayApp.userFeedback.slide3Desc" />
          }
          img="googlePlay-black.png"
        />
        <FeedBackItem
          name="John Ameobi"
          feedback={
            <LanguageTranslater langKey="pumaPayApp.userFeedback.slide4Desc" />
          }
          img="googlePlay-black.png"
        />
        <FeedBackItem
          name="Dan Webster"
          feedback={
            <LanguageTranslater langKey="pumaPayApp.userFeedback.slide5Desc" />
          }
          img="googlePlay-black.png"
        />
        <FeedBackItem
          name="Ethan Madison"
          feedback={
            <LanguageTranslater langKey="pumaPayApp.userFeedback.slide6Desc" />
          }
          img="iPhone-white.png"
        />
        <FeedBackItem
          name="Rewaski Reeas"
          feedback={
            <LanguageTranslater langKey="pumaPayApp.userFeedback.slide7Desc" />
          }
          img="iPhone-white.png"
        />
      </Slider>
    </section>
  );
};
export default PumaPayUserFeedBack;
