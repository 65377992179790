import React from "react";
import "./feature-rich-item.css";

interface FeatureRichItemProps {
  title: string | JSX.Element;
  description: string | JSX.Element;
  image: string;
}
const FeatureRichItem: React.FunctionComponent<FeatureRichItemProps> = (
  props: FeatureRichItemProps
) => {
  const { title, description, image } = props;
  return (
    <div className="iitem">
      <div className="service_item">
        <h4 className="f_600 f_p l_height27 t_color">{title}</h4>
        <p className="f_p">{description}</p>
        <img
          src={require(`../../../../assets/img/pumapay-app/${image}`).default}
          alt={image}
        />
      </div>
    </div>
  );
};
export default FeatureRichItem;
