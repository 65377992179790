import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import Helmet from "react-helmet";
import { createClient } from "contentful";
import SingleContentfulBlog from "./Contentfulblog";
import RecentPosts from "../../components/ContentfulBlogs/RecentPosts";
import BlogCategories from "../../components/ContentfulBlogs/BlogCategories";
import BlogTags from "../../components/ContentfulBlogs/BlogTags";
import FilterHeader from "../../components/ContentfulBlogs/FilterHeader";
import {
  blogProps,
  blogItemProps,
  tagItemProps,
} from "./../ContentfulBlogs/PropTypes";

const Blog: React.FC = () => {
  type Params = {
    slug: string;
  };
  const params = useParams<Params>();
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const [blog, setBlog] = useState<any>({});
  const [recentBlogs, setRecentBlogs] = useState<blogProps[]>([]);
  const [isBlog, setIsBlog] = useState<boolean>(true);
  const [categories, setCategories] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const category: string[] = [];
  const tag: string[] = [];
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState<boolean>(false);
  const [menuSelected, setMenuSelected] = useState<string>("recent_post");
  const [screenSize, setScreenSize] = useState<number>(0);
  const screenSizeForFilterToggle = 768;

  const getClient = async () => {
    return await createClient({
      space: process.env.REACT_APP_CONTENTFUL_ID || "",
      accessToken: process.env.REACT_APP_CONTENTFUL_KEY || "",
    });
  };

  const toggleFilterMenu = (menu_status: boolean) => {
    setIsFilterMenuOpen(menu_status);
  };

  const showFilterMenu = (menu_name: string) => {
    setMenuSelected(menu_name);
  };

  useEffect(() => {
    localStorage.setItem("selected_category", "");
    localStorage.setItem("selected_tag", "");

    const getContentfulBlogs = async () => {
      const cClient = await getClient();
      const blogData = await cClient.getEntries({
        content_type: "blogPost",
        include: 2,
        "fields.slug[in]": encodeURI(params.slug).toLowerCase(),
      });

      if (blogData.items.length > 0) {
        setBlog(blogData.items[0].fields);
        setIsBlog(true);
      } else {
        setBlog({});
        setIsBlog(false);
      }
    };

    const getCategories = async () => {
      const cClient = await getClient();
      const categoriesData = await cClient.getEntries({
        content_type: "category",
        limit: 10,
        "fields.priority[gt]": 0,
        order: "fields.priority",
      });

      const categoriesList = categoriesData.items.map((item: blogItemProps) => {
        return {
          ...item.fields,
          id: item.sys.id,
        };
      });
      setCategories(categoriesList);
    };
    const getTags = async () => {
      const cClient = await getClient();
      const tagData = await cClient.getEntries({
        content_type: "tag",
        limit: 10,
        "fields.priority[gt]": 0,
        order: "fields.priority",
      });

      const TagList = tagData.items.map((item: tagItemProps) => {
        return {
          ...item.fields,
          id: item.sys.id,
        };
      });
      setTags(TagList);
    };
    const getRecentPosts = async () => {
      const cClient = await getClient();
      const blogs = await cClient.getEntries({
        limit: 4,
        content_type: "blogPost",
        order: "sys.createdAt",
      });
      const blogList = blogs.items.map((item: blogItemProps) => {
        return {
          ...item.fields,
          blog_id: item.sys.id,
          created_at: item.sys.createdAt,
        };
      });
      setRecentBlogs(blogList);
    };

    getContentfulBlogs();
    getRecentPosts();
    getCategories();
    getTags();
    setScreenSize(window.screen.width);
    window.addEventListener(
      "resize",
      () => setScreenSize(window.screen.width),
      false
    );
  }, [params.slug]);

  return (
    <>
      {!isBlog && <Redirect to="/not-found" />}
      <Helmet>
        <title>{`PumaPay - ${blog.title}`}</title>
      </Helmet>
      <section className="hosting_service_area sec_pad pb_70">
        <div className="container">
          <div className="row blog_page_container">
            <div className="col-sm-8">
              <SingleContentfulBlog key={blog.title} blogData={blog} />
            </div>
            <div className="col-md-4">
              <div className="blog_filter_section pb-4">
                {screenSize < screenSizeForFilterToggle && (
                  <FilterHeader toggleFilterMenu={toggleFilterMenu} />
                )}
                {(screenSize > screenSizeForFilterToggle ||
                  isFilterMenuOpen) && (
                  <div className="blog_menu_section">
                    {screenSize < screenSizeForFilterToggle && (
                      <div className="blog_menu_header">
                        <div className="blog_menu d-flex">
                          <div
                            key="recent_post"
                            onClick={() => showFilterMenu("recent_post")}
                            className={
                              "blog_menu_item " +
                              (menuSelected == "recent_post" ? "selected" : "")
                            }
                          >
                            Recent Posts
                          </div>
                          <div
                            key="categories"
                            onClick={() => showFilterMenu("categories")}
                            className={
                              "blog_menu_item " +
                              (menuSelected == "categories" ? "selected" : "")
                            }
                          >
                            Categories
                          </div>
                          <div
                            key="tags"
                            onClick={() => showFilterMenu("tags")}
                            className={
                              "blog_menu_item " +
                              (menuSelected == "tags" ? "selected" : "")
                            }
                          >
                            Tags
                          </div>
                        </div>
                      </div>
                    )}
                    {(screenSize > screenSizeForFilterToggle ||
                      menuSelected == "recent_post") && (
                      <RecentPosts recentBlogs={recentBlogs} />
                    )}
                    {(screenSize > screenSizeForFilterToggle ||
                      menuSelected == "categories") && (
                      <BlogCategories
                        categories={categories}
                        selectedCategory={category}
                        isSingleBlog={true}
                      />
                    )}
                    {(screenSize > screenSizeForFilterToggle ||
                      menuSelected == "tags") && (
                      <BlogTags
                        blogTags={tags}
                        selectedTags={tag}
                        isSingleBlog={true}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
