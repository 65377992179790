import React from "react";
import "../../../app/App.css";
import "../../../assets/css/solutions-for.css";

interface AdultEntertainmentBannerProps {
  title: string | JSX.Element;
  header: string | JSX.Element;
  description: string | JSX.Element;
  btnText: string | JSX.Element;
  bgImage: string;
  btnUrl: string;
  customClass?: string;
}
export const AdultEntertainmentBanner: React.FunctionComponent<AdultEntertainmentBannerProps> = (
  props: AdultEntertainmentBannerProps
) => {
  const { title, header, description, btnText, btnUrl, customClass } = props;
  return (
    <section className={`solutions_for_adult_section ${customClass}`}>
      <div className="container">
        <div className="row align-items-center h-100">
          <div className="col-lg-6">
            <div className="solutions_for_adult_title">{title}</div>
            <h1
              className="solutions_for_adult_header mb_20 f_p"
              data-wow-delay="0.3s"
            >
              {header}
            </h1>
            <p
              className="solutions_for_adult_description wow fadeInUp f_size16 f_p"
              data-wow-delay="0.5s"
            >
              {description}
            </p>
            <div className="solutions_for_adult_button">
              <a
                rel="noreferrer"
                target="_blank"
                href={btnUrl}
                className="btn pp-btn pp-btn-outline-primary"
              >
                {btnText}
              </a>
            </div>
          </div>
          <div className="col-lg-6 adult-banner-side">
            <img
              src={
                require(`../../../assets/img/solutions-for/adultBannerImage.png`)
                  .default
              }
              alt="adultBannerImage"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default AdultEntertainmentBanner;
