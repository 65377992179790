import React from "react";
import { useTranslation } from "react-i18next";
import "./claimRewardBanner.css";

export const ClaimRewardBanner: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className="claim_reward_section">
      <div className="container">
        <div className={`claim_reward_detail row`}>
          <div className="col-md-12 text-center">
            <h2 className="title">
              {t("liquidityProviders.provideLiquidity.Claim your free")}
              <span className="sub_title">
                <span className="claim_reward_amount">
                  {t("liquidityProviders.provideLiquidity.750,000,000 PMA")}
                </span>{" "}
                {t("liquidityProviders.provideLiquidity.tokens")}
              </span>
            </h2>
            <a
              className="btn_get btn_hover banner_button"
              href="https://go.pumapay.io/3fdrnDv"
            >
              {t("common.learnMore")}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClaimRewardBanner;
