import React from "react";
import { useTranslation } from "react-i18next";
import { FeatureItem } from "../../Common";
import "../liquidityproviders.css";

export const LiquidityPools: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className="agency_featured_area_two pb-0 bg_outlet liquidity_pools_section">
      <div className="container">
        <h1 className="outlet_title">
          {t(
            "liquidityProviders.liquidityPools.Expect higher earnings from PMA liquidity pools"
          )}
        </h1>
        <div className="features_info_steps">
          <FeatureItem
            rowClass="row flex-row-reverse paycrypto_mobile_reverse"
            fimage="illustrations.png"
            bClass="text-center position-relative mappingWrapper"
            fname="liquidity-providers"
            aClass="feature_title"
            iImg=""
            ftitle={t(
              "liquidityProviders.liquidityPools.Collect a fee from each swap (x2 per transaction)"
            )}
            firstDescription={t(
              "liquidityProviders.liquidityPools.PumaPay facilitates description"
            )}
          />
          <FeatureItem
            rowClass="row agency_featured_item_two paycrypto_mobile_reverse"
            fimage="item.png"
            bClass="text-center"
            fname="liquidity-providers"
            aClass="text-left feature_title"
            iImg=""
            leftClass="col-lg-5 offset-lg-1"
            ftitle={t(
              "liquidityProviders.liquidityPools.Earn rewards by providing liquidity"
            )}
            altImg={true}
          >
            <>
              {t(
                "liquidityProviders.liquidityPools.Earn rewards description.description1"
              )}
              <a href="https://go.pumapay.io/3y8L0W0" className="color_link">
                {t(
                  "liquidityProviders.liquidityPools.Earn rewards description.description2"
                )}
              </a>
              {t(
                "liquidityProviders.liquidityPools.Earn rewards description.description3"
              )}
            </>
          </FeatureItem>
        </div>
      </div>
    </section>
  );
};

export default LiquidityPools;
