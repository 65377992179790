import React from "react";
import Slider from "react-slick/";
import Image from "../../../Common/Image";

const SliderFeature: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="case_studies_slider">
      <Image
        className="img-fluid"
        src={"subscription/1.png"}
        alt="infographic"
      />
      <Image
        className="img-fluid"
        src={"subscription/2.png"}
        alt="infographic"
      />
      <Image
        className="img-fluid"
        src={"subscription/3.png"}
        alt="infographic"
      />
      <Image
        className="img-fluid"
        src={"subscription/4.png"}
        alt="infographic"
      />
      <Image
        className="img-fluid"
        src={"subscription/5.png"}
        alt="infographic"
      />
      <Image
        className="img-fluid"
        src={"subscription/6.png"}
        alt="infographic"
      />
    </Slider>
  );
};

export default SliderFeature;
