import React from "react";
import { useTranslation } from "react-i18next";
import SmartChainItem from "./SmartChainItem";
const Reveal = require("react-reveal/Reveal");
import "./smartchain.css";

export const SmartChainSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className="pumapay_bridge_smart_chain">
      <div className="container">
        <h1 className="title">{t("pumapayBridge.smartChain.title")}</h1>
        <div className="row smart_chain_row1">
          <Reveal effect="fadeInUp">
            <div className="col-lg-6 col-sm-6">
              <SmartChainItem
                customClass="item1"
                imgName="evm-compatible.png"
                altText="evm-compatible"
                title={t("pumapayBridge.smartChain.item1.title")}
                desc={t("pumapayBridge.smartChain.item1.description")}
              />
            </div>
          </Reveal>
          <Reveal effect="fadeInUp">
            <div className="col-lg-6 col-sm-6">
              <SmartChainItem
                customClass="item2"
                imgName="ethereum.png"
                altText="ethereum"
                title={t("pumapayBridge.smartChain.item2.title")}
                desc={t("pumapayBridge.smartChain.item2.description")}
              />
            </div>
          </Reveal>
        </div>
        <div className="row smart_chain_row2">
          <Reveal effect="fadeInUp">
            <div className="col-lg-6 col-sm-6">
              <SmartChainItem
                customClass="item3"
                imgName="clock.png"
                altText="clock"
                title={t("pumapayBridge.smartChain.item3.title")}
                desc={t("pumapayBridge.smartChain.item3.description")}
              />
            </div>
          </Reveal>
          <Reveal effect="fadeInUp">
            <div className="col-lg-6 col-sm-6">
              <SmartChainItem
                customClass="item4"
                imgName="block.png"
                altText="block"
                title={t("pumapayBridge.smartChain.item4.title")}
                desc={t("pumapayBridge.smartChain.item4.description")}
              />
            </div>
          </Reveal>
        </div>
      </div>
    </section>
  );
};
export default SmartChainSection;
