import React from "react";
import { HeaderMenu, PlainHeaderBanner } from "../../components/Header";
import { AcceptCryptoSteps } from "../../components/AcceptCrypto";
import { LanguageTranslater } from "../../components/Common";
import { FooterBanner } from "../../components/Footer";

const AcceptCrypto: React.FC = () => {
  return (
    <>
      <HeaderMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        cClass=""
        hbtnClass=""
      />
      <PlainHeaderBanner
        title={<LanguageTranslater langKey="acceptCrypto.headerBanner.title" />}
        text={
          <LanguageTranslater langKey="acceptCrypto.headerBanner.description" />
        }
        headerClass="crypto_title"
        subHeaderClass="crypto_subtitle"
      />
      <AcceptCryptoSteps />
      <FooterBanner
        imgClass="billing_content_bg_simple"
        title={<LanguageTranslater langKey="acceptCrypto.footerBanner.title" />}
        description={
          <LanguageTranslater langKey="acceptCrypto.footerBanner.description" />
        }
        btnText={<LanguageTranslater langKey="common.createBillingModel" />}
        fbTitleClass="accept_acrypto"
        fbDesClass="accept_acrypto_des"
        btn_url="https://console.pumapay.io/register"
      />
    </>
  );
};

export default AcceptCrypto;
