import React from "react";
import { CardFlip, LanguageTranslater } from "../../Common";
import StepItems from "./StepsItem";
import "./acceptCryptoSteps.css";

export const AcceptCryptoSteps: React.FC = () => {
  return (
    <section className="agency_featured_area bg_color accept_crypto_area_two">
      <div className="container">
        <h2
          className="f_size_30 f_600 t_color3 l_height40 text-center wow fadeInUp custom_subbanner_h m-0"
          data-wow-delay="0.3s"
        >
          <LanguageTranslater langKey="acceptCrypto.steps.title" />
        </h2>
        <div className="features_info_steps">
          <img
            className="dot_img"
            src={require("../../../assets/img/accept-crypto/dot.png").default}
            alt="dot"
          />
          <CardFlip
            FrontCard={
              <StepItems
                rowClass="row flex-row-reverse accept_crypto_custom crypto_odd_number"
                fimage="work1.png"
                iImg="icon01.png"
                ftitle={
                  <LanguageTranslater langKey="acceptCrypto.steps.step1.cardFront.title" />
                }
                buttonIcon="ti-arrow-right"
                firstDescription={
                  <LanguageTranslater langKey="acceptCrypto.steps.step1.cardFront.description" />
                }
                isLink="https://console.pumapay.io/register"
                isSetupBtn={true}
                iconClass="mt-2"
                mobileClass="steps_icon_for_mobile"
              />
            }
            BackCard={
              <StepItems
                rowClass="row flex-row-reverse accept_crypto_custom crypto_odd_number"
                fimage="metamask.png"
                iImg="icon01.png"
                ftitle={
                  <LanguageTranslater langKey="acceptCrypto.steps.step1.cardBack.title" />
                }
                buttonIcon="ti-arrow-right"
                firstDescription={
                  <LanguageTranslater langKey="acceptCrypto.steps.step1.cardBack.description" />
                }
                isLink="https://console.pumapay.io/register"
                isSetupBtn={true}
                iconClass="mt-2"
                mobileClass="steps_icon_for_mobile"
              />
            }
            isV3Card={true}
            isComingSoonCard={true}
          />
          <StepItems
            rowClass="row agency_featured_item_two accept_crypto_custom"
            aClass="pl_100"
            fimage="work2.png"
            iImg="icon02.png"
            buttonIcon="ti-arrow-left"
            ftitle={
              <LanguageTranslater langKey="acceptCrypto.steps.step2.title" />
            }
            firstDescription={
              <LanguageTranslater langKey="acceptCrypto.steps.step2.description1" />
            }
            secondDescription={
              <LanguageTranslater langKey="acceptCrypto.steps.step2.description2" />
            }
            isLink="https://metamask.io/download.html"
            iconClass="mt_30"
            mobileClass="steps_icon_for_mobile"
          />
          <StepItems
            rowClass="row flex-row-reverse accept_crypto_custom crypto_odd_number"
            aClass="pl_70"
            fimage="work3.png"
            buttonIcon="ti-arrow-right"
            iImg="icon3.png"
            firstDescription={
              <LanguageTranslater langKey="acceptCrypto.steps.step3.description1" />
            }
            secondDescription={
              <LanguageTranslater langKey="acceptCrypto.steps.step3.description2" />
            }
            isLink="https://console.pumapay.io/register"
            ftitle={
              <LanguageTranslater langKey="acceptCrypto.steps.step3.title" />
            }
            iconClass="mt_1"
            mobileClass="steps_icon_for_mobile"
          />
          <StepItems
            rowClass="row agency_featured_item_two accept_crypto_custom"
            aClass="pl_100"
            fimage="work4.png"
            iImg="icon_04.png"
            buttonIcon="ti-check"
            firstDescription={
              <LanguageTranslater langKey="acceptCrypto.steps.step4.description" />
            }
            ftitle={
              <LanguageTranslater langKey="acceptCrypto.steps.step4.title" />
            }
            iconClass="mt_30"
            mobileClass="steps_icon_for_mobile"
          />
        </div>
      </div>
    </section>
  );
};

export default AcceptCryptoSteps;
