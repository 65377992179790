import React from "react";
import Image from "../../../../components/Common/Image";
import "./carouselitem.css";

interface CarouselItemProps {
  fimage: string;
  iImg: string;
  ftitle: string | JSX.Element;
  buttonIcon: boolean;
  firstDescription: string | JSX.Element;
  secondDescription?: string | JSX.Element;
}

const CarouselItem: React.FunctionComponent<CarouselItemProps> = (
  props: CarouselItemProps
) => {
  const {
    fimage,
    iImg,
    ftitle,
    buttonIcon,
    firstDescription,
    secondDescription,
  } = props;

  return (
    <div className="agency_featured_item row custom_caraousel">
      <div className="col-lg-6">
        <div className="agency_featured_img text-right">
          <Image className="img-fluid" src={`forex/${fimage}`} alt={fimage} />
        </div>
      </div>
      <div className="col-lg-6 my-auto">
        <div
          className={"agency_featured_content wow fadeInLeft pl_100"}
          data-wow-delay="0.6s"
        >
          <img
            className="img-fluid"
            src={require(`../../../../assets/img/home4/${iImg}`).default}
            alt={iImg}
          />
          <h3 className="step-title forex_carousel_title">{ftitle}</h3>
          <p className="step-description forex_carousel_desc">
            {firstDescription}
          </p>
          <p className="step-description forex_carousel_desc">
            {secondDescription}
          </p>
          {buttonIcon && (
            <a
              className="btn_get btn_hover banner_button"
              href="https://console.pumapay.io/register"
              target="_blank"
              rel="noopener noreferrer"
            >
              Set up your account
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
export default CarouselItem;
