import React from "react";
import { SolutionSectitle } from "../../Common";
import ComplexBillingItem from "./ComplexBillingItem";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";
import "./complexBilling.css";

export const ComplexBilling: React.FC = () => {
  return (
    <section className="hosting_service_area sec_pad complex-billing-section">
      <div className="container">
        <SolutionSectitle
          sClass="adult-ent-header head_detail text-center "
          title={<LanguageTranslater langKey="adult.ComplexBilling.title" />}
          subTitle={
            <LanguageTranslater langKey="adult.ComplexBilling.subTitle" />
          }
        />
        <div className="row">
          <ComplexBillingItem
            icon="adult-subscription.png"
            serviceTitle={
              <LanguageTranslater langKey="adult.ComplexBilling.item1.title" />
            }
            servicedescription={
              <LanguageTranslater langKey="adult.ComplexBilling.item1.text" />
            }
          />
          <ComplexBillingItem
            icon="adult-dynamic.png"
            serviceTitle={
              <LanguageTranslater langKey="adult.ComplexBilling.item2.title" />
            }
            servicedescription={
              <LanguageTranslater langKey="adult.ComplexBilling.item2.text" />
            }
          />
          <ComplexBillingItem
            icon="adult-api.png"
            serviceTitle={
              <LanguageTranslater langKey="adult.ComplexBilling.item3.title" />
            }
            servicedescription={
              <LanguageTranslater langKey="adult.ComplexBilling.item3.text" />
            }
          />
        </div>
        <div className="row ">
          <div className="col-sm-12 text-center complex_billing_btn mt_70 btn_learn_more">
            <a href="/subscription" className="btn_get btn_hover">
              {<LanguageTranslater langKey="adult.ComplexBilling.btn_text" />}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ComplexBilling;
