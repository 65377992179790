import React from "react";
import { useTranslation } from "react-i18next";
const Reveal = require("react-reveal/Reveal");

export const CustomerInfoSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className="payment_clients_area home_cutomer_info">
      <img
        className="trusted_logo_bg"
        src={require("../../../assets/img/home/trusted_logo_bg.png").default}
        alt="trusted_logo_bg"
      />
      <div className="container">
        <div className="client_customrow row">
          <div className="client_rightarea col-sm-6 home_customer_detail d-flex align-items-center">
            <Reveal effect="fadeInLeft" duration={500}>
              <div className="">
                <div className="icon">
                  <img
                    className="img_shape"
                    src={
                      require("../../../assets/img/home/bussiness_leader_icon.png")
                        .default
                    }
                    alt="icon_shape"
                  />
                </div>
                <h1 className="f_p f_size_26 f_400 section1_h1 customer_title">
                  {t("home.customerInfoSection.header")}
                </h1>
                <p className="f_p f_size_18 section1_p trusted_description">
                  {t("home.customerInfoSection.text1")}
                </p>
              </div>
            </Reveal>
          </div>
          <div className="client_leftarea col-sm-6">
            <div className="payment_clients_inner">
              <Reveal effect="fadeInLeft" duration={500}>
                <img
                  className="home_trusted_logos"
                  src={
                    require("../../../assets/img/home/home_trusted_logos.png")
                      .default
                  }
                  alt="home_trusted_logos"
                />
              </Reveal>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CustomerInfoSection;
