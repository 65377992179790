import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../footer.css";
import FooterData from "../FooterData";
const Reveal = require("react-reveal/Reveal");

interface menuItem {
  id: number;
  url: string;
  text: string;
  key: string;
  isTargetBlank?: boolean;
  isBold?: boolean;
}

const PersonalWidget: React.FC = () => {
  const { t } = useTranslation();
  const footerData = JSON.parse(JSON.stringify(FooterData));

  return (
    <>
      {FooterData.footerTitle.map((ftitle: string, index: number) => {
        return (
          <div key={index}>
            <Reveal effect="fadeInUp" duration={1400}>
              <div className="footer_menu">
                <div
                  className="f_widget about-widget pr_15 wow fadeInLeft footer_menu_list"
                  data-wow-delay="0.4s"
                >
                  <h3 className="f-title f_600 t_color f_size_18 mb_40">
                    {t(`title.footerTitle.${ftitle}`)}
                  </h3>
                  <ul className="list-unstyled f_list">
                    {footerData[ftitle].map((item: menuItem) => {
                      return (
                        <li key={item.id}>
                          {!item.isTargetBlank ? (
                            <Link
                              to={`${item.url}`}
                              className={item.isBold ? "text-bold" : ""}
                            >
                              {t(`title.${ftitle}.${item.key}`)}
                            </Link>
                          ) : (
                            <a
                              href={`${item.url}`}
                              target="_blank"
                              className={item.isBold ? "text-bold" : ""}
                              rel="noreferrer"
                            >
                              {t(`title.${ftitle}.${item.key}`)}
                            </a>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </Reveal>
          </div>
        );
      })}
    </>
  );
};

export default PersonalWidget;
