import React from "react";
import { useHistory } from "react-router-dom";
import { ImageLoader } from "../../../components/Common";
import { blogDataProps } from "./../PropTypes";
import LanguageTranslater from "../../../components/Common/LanguageTranslater";

interface ContentfulProps {
  blogData: blogDataProps;
}

const ContentfulBlogs: React.FunctionComponent<ContentfulProps> = (
  props: ContentfulProps
) => {
  const history = useHistory();
  const { blogData } = props;

  const contentTitle = blogData.title || "";
  const heroImage = blogData.heroImage && blogData.heroImage.fields.file.url;
  const contentDescription = blogData.description || blogData.title;
  const dt = new Date(blogData.publishDate);
  const createdDate = dt.getDate();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const createdMonth = dt.getMonth();

  const routeChange = (slug: string) => {
    const path = `/${slug}`;
    history.push(path);
  };
  const textLimit = 70;

  return (
    <div className="col-sm-6 pb-5">
      <div className="blogs_item">
        {heroImage ? (
          <div role="button" onClick={() => routeChange(blogData.slug)}>
            <ImageLoader
              sClass="blogs_item_image form-group"
              imageSrc={heroImage}
              imageAlt={heroImage}
            />
          </div>
        ) : (
          <div className="blogs_item_image form-group">
            <img className="w-100" src="no-image.png" alt="no-image.png" />
          </div>
        )}
        <div className="blogs_timestamp">
          <div className="date">{createdDate}</div>
          <div className="month">{months[createdMonth]}</div>
        </div>
        <h4
          onClick={() => routeChange(blogData.slug)}
          className="blogs_subheader"
        >
          {contentTitle}
        </h4>
        <p>
          {contentDescription.length > textLimit
            ? contentDescription.substring(0, textLimit - 3) + "..."
            : contentDescription}
        </p>
      </div>
      <div
        onClick={() => routeChange(blogData.slug)}
        className="blogs_read_more"
      >
        {<LanguageTranslater langKey="blog.readMore" />}
        <img
          className="pl-3"
          src={require("../../../assets/img/blogs/right_arrow.png").default}
        />
      </div>
    </div>
  );
};
export default ContentfulBlogs;
